import { createSlice } from "@reduxjs/toolkit";
import {
  getOrders,
  getStatOrders,
  getDetails,
  acceptOrder,
  assignDriver,
  finishOrder,
  getDrivers,
  updateOrder,
  dispatchOrder,
  cancelOrder,
  resendOrder,
  sendPeya,
  // transferOrder,
  // getLoyalty,
  // updateLoyalty,
  // updateNotes,
} from "../../api/Monitor";
// import { changeTakeOut } from "../../api/Branches";

export const monitorSlice = createSlice({
  name: "monitor",
  initialState: {
    loaded: false,
    statistics: {},
    new_orders: [],
    day_orders: [],
  },
  reducers: {
   setNewOrders: (state, action) => {
      state.new_orders = action.payload;
    },
    setDayOrders: (state, action) => {
      state.day_orders = action.payload;
    },
    /* setLocations: (state, action) => {
      state.locations = action.payload;
    },*/

    setStatistics: (state, action) => {
      state.statistics = action.payload;
    }, 
    setLoaded: (state, action) => {
      state.loaded = action.payload;
    },
     setDelivery: (state, action) => {
      const { index, val } = action.payload;
      state.locations[index].delivery = val;
    },
    /*
    setLoyalty: (state, action) => {
      state.loyalty = action.payload;
    }, */
    setOrderStatus: (state, action) => {
      const { order, status } = action.payload;
      const index = state.day_orders.findIndex((el) => el.id === order);
      if (status === 12 || status === 15) {
        state.day_orders.splice(index, 1);
      } else {
        state.day_orders[index].status = status;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setNewOrders,
  setDayOrders,
  // setLocations,
  setStatistics,
  setLoaded,
  // setDelivery,
  // setLoyalty,
  setOrderStatus,
} = monitorSlice.actions;

export const getMonitorOrders = (branch, admin) => async (dispatch) => {
  try {
    const response = await getOrders(branch, admin);
    if (!response.error && response.status === 200) {
      dispatch(setNewOrders(response.data.new_orders));
      dispatch(setDayOrders(response.data.orders));
      // dispatch(setLocations(response.data.locations));
      // dispatch(setStatistics(response.data.statistics));
      dispatch(setLoaded(true));
      return {
        status: "success",
        orders: response.data.new_orders.concat(response.data.day_orders),
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const getOrderStats = (branch, admin) => async (dispatch) => {
  try {
    const response = await getStatOrders(branch, admin);
    if (!response.error && response.status === 200) {
      dispatch(setStatistics(response.data.statistics));
      dispatch(setLoaded(true));
      return {
        status: "success",
        statistics: response.data.statistics ,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const getOrderDetails = (order) => async (dispatch) => {
  try {
    const response = await getDetails(order);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
        order: response.data.order,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

/*
export const deliveryLocation = (id, index, val) => async (dispatch) => {
  try {
    dispatch(setDelivery({ index, val: !val }));
    const response = await changeTakeOut(id);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
      };
    }
    dispatch(setDelivery({ index, val }));
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    dispatch(setDelivery({ index, val }));
    return {
      status: "error",
      type: "unknown",
    };
  }
};
*/
export const acceptMonitorOrder = (order, branch) => async (dispatch) => {
  try {
    const response = await acceptOrder(order, branch);
    if (!response.error && response.status === 200) {
      dispatch(setOrderStatus({ order, status: 7 }));
      return {
        status: "success",
        order: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

/* export const updateNotesClient =
  (user, address, notes, order) => async (dispatch) => {
    try {
      const response = await updateNotes(user, address, notes, order);
      if (!response.error && response.status === 200) {
        return {
          status: "success",
          order: response.data,
        };
      }
      return {
        status: "error",
        type: "unkown",
      };
    } catch (e) {
      return {
        status: "error",
        type: "unknown",
      };
    }
  }; */

export const updateMonitorOrder = (order) => async (dispatch) => {
  try {
    const response = await updateOrder(order);
    if (!response.error && response.status === 200) {
      dispatch(setOrderStatus({ order, status: 11 }));
      return {
        status: "success",
        order: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } 
  catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const dispatchMonitorOrder = (order) => async (dispatch) => {
  try {
    const response = await dispatchOrder(order);
    if (!response.error && response.status === 200) {
      dispatch(setOrderStatus({ order, status: 10 }));
      return {
        status: "success",
        order: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } 
  catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const assignMonitorDriver = (order, driver) => async (dispatch) => {
  try {
    const response = await assignDriver(order, driver);
    if (!response.error && response.status === 200) {
      return response.data;
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
}; 

export const finishMonitorOrder = (order) => async (dispatch) => {
  try {
    const response = await finishOrder(order);
    if (!response.error && response.status === 200) {
      dispatch(setOrderStatus({ order, status: 12 }));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const cancelMonitorOrder = (order,reasonId) => async (dispatch) => {
  try {
    const response = await cancelOrder(order,reasonId);
    if (!response.error && response.status === 200) {
      dispatch(setOrderStatus({ order, status: 15 }));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const resendCirebaOrder = (order, branch) => async (dispatch) => {
  try {
    const response = await resendOrder(order, branch);
    if (!response.error && response.status === 200) {
      dispatch(setOrderStatus({ order, status: 7 }));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
      stack: response.error
    };
  } 
  catch (e) {
    return {
      status: "error",
      type: "unknown",
      stack: e
    };
  }
}

export const sendPeyaOrder = (order, branch) => async (dispatch) => {
  try {
    const response = await sendPeya(order, branch);
    if (!response.error && response.status === 200) {
      dispatch(setOrderStatus({ order, status: 8 }));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
      stack: response.error
    };
  } 
  catch (e) {
    return {
      status: "error",
      type: "unknown",
      stack: e
    };
  }
}

/* export const transferMonitorOrder =
  (order, branch, reason) => async (dispatch) => {
    try {
      const response = await transferOrder(order, branch, reason);
      if (!response.error && response.status === 200) {
        return {
          status: "success",
          order: response.data,
        };
      }
      return {
        status: "error",
        type: "unkown",
      };
    } catch (e) {
      return {
        status: "error",
        type: "unknown",
      };
    }
  }; */

export const getBranchDrivers = (location) => async (dispatch) => {
  try {
    const response = await getDrivers(location);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
        drivers: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

/* export const getLoyaltyInfo = () => async (dispatch) => {
  try {
    const response = await getLoyalty();
    if (!response.error && response.status === 200) {
      const { active, promovalue } = response.data;
      dispatch(setLoyalty({ active, value: promovalue }));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
}; */

/* export const handleLoyaltyInfo = (info) => async () => {
  try {
    const response = await updateLoyalty(info);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
}; */

export const selectNewOrders = (state) => state.monitor.new_orders;
export const selectDayOrders = (state) => state.monitor.day_orders;
// export const selectLocations = (state) => state.monitor.locations;
export const selectStatistics = (state) => state.monitor.statistics;
export const selectLoaded = (state) => state.monitor.loaded;
// export const selectLoyalty = (state) => state.monitor.loyalty;

export default monitorSlice.reducer;
