import X from '../assets/icons/x.png';
import styles from './styles/Close.module.css';

const Close = ({action}) => (
  <div onClick={action} className={styles.close}>
    <img src={X} alt="" className={styles.x} />
  </div>
);

export default Close;
