import { Table, Spin, Modal, Row, Col, Button, Switch, Divider } from "antd";
import cx from "classnames";
import moment from "moment";
import Header from "../../../components/HeaderList";
import { ReactComponent as Cash } from "../../../assets/icons/cash.svg";
import { ReactComponent as Card } from "../../../assets/icons/card.svg";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import { ReactComponent as Cancel } from "../../../assets/icons/cancel.svg";
import { ReactComponent as Eye } from "../../../assets/icons/eye.svg";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";
import { ReactComponent as Password } from "../../../assets/icons/password.svg";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import { getColumnSearchProps } from "../../../utils/tables";
import OrderDetail from "../../../components/OrderDetail";
import styles from "../styles/ClientsUI.module.css";
import Editor from "./Editor";
import PasswordEditor from "./PasswordEditor";
import AddressEditor from "./AddressEditor";

const ClientsUI = (props) => {
  const {
    loading,
    client,
    order,
    openOrder,
    closeOrder,
    exportData,
    handleActive,
    loadingOrder,
    visibleEditor,
    closeEditor,
    openEditor,
    visiblePasswordEditor,
    closePasswordEditor,
    openPasswordEditor,
    address,
    visibleAddressEditor,
    openAddressEditor,
    closeAddressEditor,
    confirmDeleteAddress
  } = props;

  const getIcon = (status) => {
    if (status === 4) {
      return <Cancel className={styles.driverIcon} />;
    }
    return <Check />;
  };

  const getMethod = (method) => {
    if (method === 1) {
      return <Cash />;
    }
    return <Card />;
  };

  const columns = [
    {
      title: "Origen",
      dataIndex: "platform",
      align: "center",
    },
    {
      title: "Ticket",
      dataIndex: "ticket",
      align: "center",
      ...getColumnSearchProps("ticket"),
    },
    {
      title: "Sucursal",
      dataIndex: "branch",
      align: "center",
      render: (val) => val.zone || "-",
    },
    {
      title: "Fecha",
      dataIndex: "created_time",
      align: "center",
      render: (val) => moment(val).format("DD/MM/YY - hh:mma"),
    },
    {
      title: "Monto",
      dataIndex: "total",
      render: (val) => `$${parseFloat(val | 0).toFixed(2)}`,
      align: "center",
    },
    {
      title: "Método",
      dataIndex: "payment_method",
      render: (val) => getMethod(val),
      align: "center",
      filters: [
        {
          value: 1,
          text: "Efectivo",
        },
        {
          value: 2,
          text: "Tarjeta",
        },
      ],
      onFilter: (value, record) => record.payment_method === value,
      filterMultiple: false,
    },
    {
      title: "Tipo de Orden",
      dataIndex: "orderType",
      align: "center",
      filters: [
        { value: 0, text: "PEDIDOS YA" },
        { value: 2, text: "PARA LLEVAR" },
        { value: 3, text: "DOMICILIO" },
      ],
      onFilter: (value, record) => record.orderType === value,
      render: (value) =>
        value === 0 ? "PEDIDOS YA" : value === 1 ? "DOMICILIO" : "PARA LLEVAR",
    },
    {
      title: "Estado",
      dataIndex: "status",
      render: (val) => getIcon(val),
      align: "center",
      filters: [
        {
          value: 12,
          text: "Completadas",
        },
        {
          value: 15,
          text: "Canceladas",
        },
      ],
      onFilter: (value, record) => record.status === value,
      filterMultiple: false,
    },
    {
      title: "Ver detalle",
      dataIndex: "id",
      align: "center",
      render: (val) => (
        <button
          disabled={loadingOrder}
          onClick={() => openOrder(val)}
          className={styles.eye}
        >
          {loadingOrder === val ? <Spin size="small" /> : <Eye />}
        </button>
      ),
    },
  ];
  const columnsAdresses = [
    { title: "Nombre", dataIndex: "name" },
    { title: "Dirección", dataIndex: "address" },
    { title: "# de Casa", dataIndex: "number" },
    { title: "Tipo", dataIndex: "type" },
    { title: "Zona", dataIndex: "zone" },
    { title: "Referencia", dataIndex: "reference_point" },
    {
      title: "Acciones",
      dataIndex: "id",
      render: (val,record) => (
        <div className={styles.actions}>
          <Edit onClick={()=> openAddressEditor(record)} /> 
          <Divider type="vertical" className={styles.divider} />{" "}
          <Trash onClick={()=> confirmDeleteAddress(val)} />
        </div>
      ),
    },
  ];
  console.log(client);
  return (
    <div className={styles.view}>
      <Header
        back="/ajustes/clientes"
        title={loading ? "Cliente" : `${client.name} ${client.lastname}`}
        actions={
          <div className={styles.actions}>
            <div className={styles.switchWrap}>
              <span>Activo</span>
              <Switch checked={client.active} onChange={handleActive} />
            </div>
            <Button size="large" type="primary" onClick={exportData}>
              Exportar
            </Button>
          </div>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div className={styles.infoWrap}>
            <Row>
              <Col span={11}>
                <div className={styles.infoCol}>
                  <span>{`Nombre: ${client.name} ${client.lastname}`}</span>
                  <span>{`Teléfono: ${client.mobile}`}</span>
                  <span>{`Correo: ${client.email}`}</span>
                  <span>{`DUI: ${client.dui}`}</span>
                  <span>{`Notas: ${client.notes || ''}`}</span>
                </div>
              </Col>
              <Col span={11}>
                <div className={cx(styles.infoCol, styles.borders)}></div>
                <div className={styles.infoCol}>
                  <span>{`Registro: ${moment(client.created_time).format(
                    "DD/MM/YYYY"
                  )}`}</span>
                  <span>{`Cumpleaños:  ${
                    !client.birthday
                      ? "-"
                      : moment(client.birthday).format("DD/MM/YYYY")
                  }`}</span>
                  <span>
                    {`Primera compra: ${
                      client.orders.length
                        ? moment(client.orders[0].created_time).format(
                            "DD/MM/YY"
                          )
                        : "Sin compras"
                    }`}
                  </span>
                  <span>
                    {`Última compra: ${
                      client.orders.length
                        ? moment(
                            client.orders[client.orders.length - 1].created_time
                          ).format("DD/MM/YY")
                        : "Sin compras"
                    }`}
                  </span>
                 
                </div>
              </Col>
              <Col span={2}>
                <div className={styles.actions}>
                  <span>
                    <Edit onClick={openEditor} />
                  </span>
                  <Divider type="vertical" className={styles.divider} />
                  <span>
                    <Password onClick={openPasswordEditor} />
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <p className={styles.titleTable}> Direcciones </p>
          <Table
            columns={columnsAdresses}
            dataSource={client.addresses}
            rowKey="id"
          />
          <br />
          <p className={styles.titleTable}> Ordenes </p>

          <Table columns={columns} dataSource={client.orders} rowKey="id" />
          <br />
          
          
        </div>
      )}
      <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        visible={visibleEditor}
        width={600}
        closable={false}
        onCancel={closeEditor}
      >
        <Editor client={client} closeEditor={closeEditor} />
      </Modal>
      <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        visible={visiblePasswordEditor}
        width={600}
        closable={false}
        onCancel={closePasswordEditor}
      >
        <PasswordEditor client={client} closeEditor={closePasswordEditor} />
      </Modal>
      <Modal 
      destroyOnClose
      wrapClassName={styles.modalOrder}
      footer={false}
      visible={visibleAddressEditor}
      width={600}
      closable={false}
      onCancel={closeAddressEditor}
      >
        <AddressEditor address={address} closeEditor={closeAddressEditor} />
      </Modal>
      <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        visible={order}
        width={1150}
        closable={false}
        onCancel={closeOrder}
      >
        <OrderDetail order={order} close={closeOrder} userView />
      </Modal>
    </div>
  );
};

export default ClientsUI;
