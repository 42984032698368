import React from "react";
import styles from "../styles/DriverSchedules.module.css";
import Close from "../../../components/Close";
import { Button, Col, DatePicker, Form, Row } from "antd";
const DriverSchedules = (props) => {
  const { closeDriverSchedules } = props;
  const format = "HH:mm";
  return (
    <div>
      <div className={styles.header}>
        <span className={styles.title}>HORARIOS DISPONIBLES</span>
        <Close action={closeDriverSchedules} />
      </div>
      <Form layout="vertical">
        <Row>
          <Col span={12}>
            <Form.Item
              name="monday"
              className={styles.formItem}
              label={<span className={styles.labelFormItem}>LUNES</span>}
            >
              <DatePicker.TimePicker format={format} className={styles.timePicker} />
            </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item
            name="tuesday"
            label={<span className={styles.labelFormItem}>MARTES</span>}
          >
            <DatePicker.TimePicker format={format} className={styles.timePicker} />
          </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item
            name="wednesday"
            label={<span className={styles.labelFormItem}>MIÉRCOLES</span>}
          >
            <DatePicker.TimePicker format={format} className={styles.timePicker} />
          </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item
            name="thursday"
            label={<span className={styles.labelFormItem}>JUEVES</span>}
          >
            <DatePicker.TimePicker format={format} className={styles.timePicker} />
          </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item
            name="friday"
            label={<span className={styles.labelFormItem}>VIERNES</span>}
          >
            <DatePicker.TimePicker format={format} className={styles.timePicker} />
          </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item
            name="saturday"
            label={<span className={styles.labelFormItem}>SÁBADO</span>}
          >
            <DatePicker.TimePicker format={format} className={styles.timePicker} />
          </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item
            name="sunday"
            label={<span className={styles.labelFormItem}>DOMINGO</span>}
          >
            <DatePicker.TimePicker format={format} className={styles.timePicker} />
          </Form.Item>
          </Col>
          <Button className={styles.submit} htmlType="submit" type="primary" size="large">
            GUARDAR HORARIOS
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default DriverSchedules;
