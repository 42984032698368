import instance from "./Request";

export const getPromosList = async() => {
    const request = await instance();
    let data = await request.post("/monitor/orders-promo-nino")
    .catch((error) => {
      return {
        error,
      };
    });
    return data;
}