import { Button, Form, Select } from "antd";
import styles from "../styles/Content.module.css";
import cx from "classnames";

const OrdersArea = () => {
  return (
    <div className={styles.cardContent}>
      <Form layout="inline">
        <Form.Item>
          <Select
            size="large"
            className={styles.select}
            placeholder="Todos los departamentos"
          />
        </Form.Item>
        <Form.Item>
          <Select
            size="large"
            className={styles.select}
            placeholder="Todos los productos"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="large">
            Filtrar
          </Button>
        </Form.Item>
      </Form>
      <br />
      <div className={styles.containerTableArea}>
        <span className={styles.headerItemTable}>Posición</span>
        <span className={styles.headerItemTable}>Departamento</span>
        <span
          className={cx(styles.headerItemTable, styles.lastHeaderItemTable)}
        >
          Producto
        </span>
        <span className={styles.bodyCellTable}>1</span>
        <span className={styles.bodyCellTable}>San Salvador</span>
        <span className={styles.bodyCellTable}>25</span>
      </div>
    </div>
  );
};

export default OrdersArea;
