import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import { ConfigProvider } from 'antd';
import {store} from './redux/store';
import './main.css';
import './index.less';
import App from './App';
import reportWebVitals from './reportWebVitals';
import locale from 'antd/lib/locale/es_ES'
import { register } from './utils/serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={locale}>
    <Provider store={store}>
      <App />
    </Provider>
    </ConfigProvider>
 
  </React.StrictMode>,
  document.getElementById('root')
);

register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
