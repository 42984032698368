import React from "react";
import styles from "../styles/Content.module.css";
import { Button, Card, Form, Select } from "antd";
import cx from "classnames";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

const data = [
  { name: "Group A", value: 22 },
  { name: "Group B", value: 17 },
  { name: "Group C", value: 5 },
];

const COLORS = ["#FF671D", "#585858", "#FFF"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill={index === 2 ? "#585858" : "#FFF"}
      textAnchor={x > cx ? "start" : "end"}
      fontWeight="bold"
      fontSize="25px"
      fontFamily="Arial"
      dominantBaseline="central"
    >
      {value}
    </text>
  );
};

const AverageTimes = () => {
  return (
    <div className={styles.cardContent}>
      <Form layout="inline">
        <Form.Item>
          <Select
            size="large"
            className={styles.select}
            options={[]}
            placeholder="Todas las sucursales"
          />
        </Form.Item>
        <Button type="primary" size="large">
          Filtrar
        </Button>
      </Form>
      <br />
      <Card hoverable>
      <div className={styles.chartContent}>
        <ResponsiveContainer width="70%" height={400}>
          <PieChart width={500} height={500}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={175}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
          <div className={styles.chartLegends}>
            <span className={styles.chartLegend}>
              <span className={styles.chartLegendBox} />
              <p>Aceptación</p>
            </span>
            <span className={styles.chartLegend}>
              <span
                className={cx(
                  styles.chartLegendBox,
                  styles.chartLegendBoxPreparation
                )}
              />
              <p>En preparación</p>
            </span>
            <span className={styles.chartLegend}>
              <span
                className={cx(
                  styles.chartLegendBox,
                  styles.chartLegendBoxOnTheWay
                )}
              />
              <p>En camino</p>
            </span>
          </div>
        </div>
        <p className={styles.legend}>*Tiempos promedios en minutos</p>
      </Card>
    </div>
  );
};

export default AverageTimes;
