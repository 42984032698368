import React from 'react'
import styles from '../styles/ClientsUI.module.css'
import Close from '../../../components/Close'
import { Button, Col, Form, Input, Row } from 'antd'
const AddressEditor = ({address,closeEditor}) => {
    console.log(address)
  return (
    <div className={styles.editor}>
    <div className={styles.header}>
        <span className={styles.title}>
            Editar
        </span>
        <Close action={closeEditor} />
    </div>
    <Form layout='vertical' initialValues={{...address}}>
          <Row>
            <Col span={24}>
            <Form.Item name={"address"} className={styles.itemColumn} label={<span className={styles.label}>DIRECCIÓN</span>} rules={[{required:true,message:"Ingresar dirección"}]}>
                <Input />
            </Form.Item>
            <Form.Item name={"number"} className={styles.itemColumn} label={<span className={styles.label}>NUMERO DE CASA</span>} rules={[{required:true,message:"Ingresar nombre"}]}>
                <Input  />
            </Form.Item>
            <Form.Item name={"reference_point"}className={styles.itemColumn}  label={<span className={styles.label}>REFERENCIA</span>} rules={[{required:true,message:"Ingresar Referencia"}]}>
                <Input.TextArea rows={4}  />
            </Form.Item>
            </Col>
            <Col span={8}>
            <Form.Item className={styles.itemSubmit}>
                <Button 
                htmlType="submit"
                type="primary"
                size="large"
                className={styles.submit}
                >
                    GUARDAR CAMBIOS
                </Button>
            </Form.Item>
            </Col>
          </Row>
    </Form>
    </div>
  )
}

export default AddressEditor