import { createSlice } from "@reduxjs/toolkit";
import { cancellationReasonList } from "../../api/CancellationReason";

export const cancellationReasonSlice = createSlice({
  name: "cancellationReason",
  initialState: {
    reasons: [],
  },
  reducers: {
    setReasons: (state, action) => {
      state.reasons = action.payload;
    },
  },
});

export const { setReasons } = cancellationReasonSlice.actions;

export const cancellationReasonListThunk = () => async (dispatch) => {
  try {
    const response = await cancellationReasonList();
    if (!response.error && response.status === 200) {
      dispatch(setReasons(response.data));
      return {
        success: true,
        data: response.data,
      };
    }
    return{
        status:"error",
        type:"unknown"
      }
  } catch (e) {
    return{
      status:"error",
      type:"unknown"
    }
  }
};


export const selectCancellationReasons = (state) => state.cancellationReason.reasons


export default cancellationReasonSlice.reducer