import { Table, Switch, Button, Spin, Tooltip } from "antd";
import { selectUser } from "../../../redux/slices/user";
import { getColumnSearchProps } from "../../../utils/tables";
import Header from "../../../components/HeaderList";
import styles from "../styles/ProductsUI.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SuggestsUI = (props) => {
  const { loading, products, handleProductSection, categories } = props;

  const user = useSelector(selectUser);


  const getCategoryName = (role) => {
    const index = categories.findIndex((el) => el.value === role);
    if (index > -1) {
      return categories[index].text;
    }
    return "-";
  };

  const irhacia = useNavigate();

  const columns = [
    /* {
      title: "ID",
      dataIndex: "id",
      align: "center",
    }, */
    {
      title: "Nombre",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Descripción",
      dataIndex: "description",
      width: 200,
      render: (val) => (
        <Tooltip placement="bottom" title={val}>
          <span className={styles.description}>{val}</span>
        </Tooltip>
      ),
    },
    {
      title: "Categoría",
      dataIndex: "id_category",
      align: "center",
      filters: categories,
      filteredValue: [88,16],
      onFilter: (val, record) => record.id_category === val,
      render: (val) => getCategoryName(val),
    },
    /*
    {
      title: "Recomendado",
      align: "center",
      dataIndex: "take_out",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.take_out === value,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={() => handleProductSection(record.id, val, "recomendado", user.branch)}
        />
      ),
    },
    */
    {
      title: "Sugerido",
      align: "center",
      dataIndex: "suggested",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.suggested === value,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={() => handleProductSection(record.id, val, "take_out", user.branch)}
        />
      ),
    },
  ];

  return (
    <div className={styles.view}>
      <Header back="/productos" title="Productos Sugeridos" />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table columns={columns} dataSource={products} rowKey="id" />
        </div>
      )}
    </div>
  );
};

export default SuggestsUI;
