import React from 'react'
import styles from '../styles/Editor.module.css'
import Close from '../../../components/Close'
import { Button, Col, Form, Input, Row } from 'antd'

export const PasswordEditor = ({close,vehicle}) => {
  return (
    <div className={styles.editor}>
        <div className={styles.header}>
            <span className={styles.title}>
                Editar Contraseña
            </span>
            <Close action={close}/>
        </div>
        <Form>
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <span className={styles.label}>
                  CONTRASEÑA ACTUAL
                </span>
              }
              name="old_password"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar contraseña'}]}
            >
              <Input.Password size="large" className={styles.input} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <span className={styles.label}>
                  CONTRASEÑA NUEVA
                </span>
              }
              name="new_password"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar contraseña nueva'}]}
            >
              <Input.Password size="large" className={styles.input} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item className={styles.itemSubmit}>
              <Button
                className={styles.submit}
                size="large"
                type="primary"
                htmlType="submit">
                GUARDAR NUEVA CONTRASEÑA
              </Button>
            </Form.Item>
          </Col>
        </Row>
        </Form>
    </div>
  )
}
