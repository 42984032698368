import { createSlice } from "@reduxjs/toolkit";
import { getLogs } from "../../api/Logs";

export const logsSlice = createSlice({
  name: "logs",
  initialState: {
    logs: [],
  },
  reducers: {
    setLogs: (state, action) => {
      state.logs = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLogs } = logsSlice.actions;



export const getLogsThunk = (userId) => async (dispatch) => {
  try {
    const response = await getLogs(userId);
    if (!response.error && response.status === 200) {
      dispatch(setLogs(response.data.logs));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const selectLogs = (state) => state.logs.logs;

export default logsSlice.reducer;
