import validator from 'validator';

export function validateFloatKeyPress(value, prev) {
  if (
    validator.isFloat(value) === true ||
    value === '.' ||
    validator.isEmpty(value)
  ) {
    if (value.charAt(0) === '.') {
      return prev;
    }
    if (value.indexOf('.') > -1) {
      const number = value.split('.');
      if (number[1] > 99) {
        return prev;
      }
      return value;
    }
    return value;
  }
  return prev;
}


export const handlePhone = (e) => {
  const maxLength = 8;
  let value = e.target.value.replace(/\D/g, "");
  value = value.slice(0, maxLength);
  if (value > 4) {
    value = value.replace(/^(\d{4})/, "$1-");
  }
  e.target.value = value;
};

export const handleDUI = (e) => {
  const maxLength = 9;
  let value = e.target.value.replace(/\D/g, "");
  value = value.slice(0, maxLength);
  if (value > 8) {
    value = value.replace(/^(\d{8})/, "$1-");
  }
  e.target.value = value;
};

export const handlePlate = (e) => {
  const maxLength = 6;
  let value = e.target.value.replace(/\D/g, "");
  value = value.slice(0, maxLength);

  if (value > 1) {
    value = value.replace(/^(\d{0})/, "$1M");
  }

  e.target.value = value;
};