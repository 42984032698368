import Header from "../../../components/HeaderList";
// import Store from "../../../assets/sections/store.png";
// import Blog from "../../../assets/sections/blog.png";
// import Web from "../../../assets/sections/web.png";
import Motos from "../../../assets/sections/drivers.png";
import CardSection from "../../../components/CardSection";
import styles from "../styles/ContentUI.module.css";

const ContentsUI = () => (
  <div>
    <Header title="Reportes" />
    <div className={styles.sections}>
      {/* <CardSection image={Store} label="TIENDAS" route="/contenidos/tiendas" />
      <CardSection image={Blog} label="BLOG" route="/contenidos/blogs" />
      <CardSection image={Web} label="PÁGINAS" route="/contenidos/paginas" /> */}
      <CardSection image={Motos} label="TIEMPOS" route="/reportes/tiempos" />
      <CardSection image={Motos} label="LOGS" route="/reportes/logs" />

    </div>
  </div>
);

export default ContentsUI;
