import { createSlice } from "@reduxjs/toolkit";
import { getRatings, newQuestion, editQuestion } from "../../api/Ratings";

export const ratingsSlice = createSlice({
  name: "ratings",
  initialState: {
    ratings: {},
    questions:[],
    average: 0,
  },
  reducers: {
    setRatings: (state, action) => {
      state.ratings = action.payload;
    },
    setAverage: (state, action) => {
      state.average = action.payload;
    },
    addQuestion: (state, action) => {
      state.questions.push(action.payload);
    },
    setQuestion: (state, action) => {
      const index = state.questions.findIndex(el => el.id === action.payload.id);
      if (index > -1) {
        state.questions[index] = action.payload;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRatings, setAverage, addQuestion, setQuestion } = ratingsSlice.actions;

export const getPlatformRatings = (config) => async (dispatch) => {
  try {
    const response = await getRatings(config);
    if (!response.error && response.status === 200) {
      const { average, ratings } = response.data;
      dispatch(setRatings(ratings));
      dispatch(setAverage(average));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};
export const selectRatings = (state) => state.ratings.ratings;
export const selectAverage = (state) => state.ratings.average;

export const createQuestion = data => async dispatch => {
  try {
    const response = await newQuestion(data);
    if (!response.error && response.status === 200) {
      dispatch(addQuestion(response.data));
      return {
        status: 'success',
        question: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const updateQuestion = data => async dispatch => {
  try {
    const response = await editQuestion(data);
    if (!response.error && response.status === 200) {
      dispatch(setQuestion(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export default ratingsSlice.reducer;
