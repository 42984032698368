import { configureStore } from "@reduxjs/toolkit";
import blogs from "./slices/blogs";
import branches from "./slices/branches";
import ratings from "./slices/ratings";
import clients from "./slices/clients";
import frontpage from "./slices/frontpage";
import history from "./slices/history";
import monitor from "./slices/monitor";
import pages from "./slices/pages";
import products from "./slices/products";
import promos from "./slices/promos";
import user from "./slices/user";
import users from "./slices/users";
import vehicles from "./slices/vehicles";
import zones from "./slices/zones";
import scanner from "./slices/scanner";
import promosList from './slices/promosList'
import logs from "./slices/logs";
import notifications from "./slices/notifications";
import cancellationReason from "./slices/cancellationReason"
export const store = configureStore({
  reducer: {
    blogs,
    branches,
    ratings,
    clients,
    frontpage,
    history,
    monitor,
    pages,
    products,
    promos,
    user,
    users,
    vehicles,
    zones,
    scanner,
    promosList,
    logs:logs,
    notifications:notifications,
    cancellationReason,
  },
});
