import {useState, useEffect} from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  message,
} from 'antd';
import {useDispatch} from 'react-redux';
import {createVehicle, updateVehicle} from '../../../redux/slices/vehicles';
import Close from '../../../components/Close';
import styles from '../styles/Editor.module.css';

const {Option} = Select;

const Editor = ({close, vehicle, branches, userList, handleVehicle}) => {
  const [sending, handleSending] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      if (vehicle) {
        const newValues = {
          user: vehicle.user,
          plate: vehicle.plate,
          phone: vehicle.phone||"",
          location: vehicle.branch,
          status: vehicle.status,
        };
        form.setFieldsValue(newValues);
      }
    };
    initialFetch();
  }, [vehicle]);

  const save = async () => {
    try {
      handleSending(true);
      const values = await form.validateFields();
      const info = {
        userId: values.user,
        phone: values.phone,
        name: values.plate,
        plate: values.plate,
        locationId: values.location,
        status: values.status,
      };
      if (vehicle) {
        info.id = vehicle.id;
      }
      if (vehicle) {
        const response = await dispatch(updateVehicle(info));
        if (response.status === 'success') {
          handleSending(false);
          message.success('Cambios realizados con éxito');
        } else {
          handleSending(false);
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }
      } else {
        const response = await dispatch(createVehicle(info));
        if (response.status === 'success') {
          message.success('Cambios realizados con éxito');
          handleSending(false);
          handleVehicle(response.vehicle);
        } else {
          handleSending(false);
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }
      }
    } catch (e) {
    }
  };

  return (
    <div className={styles.editor}>
      <div className={styles.header}>
        <span className={styles.title}>
          {vehicle ? 'Editar Driver' : 'Nuevo Driver'}
        </span>
        <Close action={close} />
      </div>
      <Form
        name="driver-editor"
        autoComplete="off"
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={save}
      > 
        <Row>
        <Col span={24}>
            <Form.Item
              label={<span className={styles.label}>USUARIO</span>}
              name="user"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar el usuario'}]}
            >
              <Select size="large" className={styles.input}>
                {userList.filter(x => x.admin == 0).map(user => (
                  <Option key={user.id} value={user.id}>{user.username}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <span className={styles.label}>
                  TELÉFONO
                </span>
              }
              name="phone"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar telefono'}]}
            >
              <Input size="large" className={styles.input} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <span className={styles.label}>
                  PLACA
                </span>
              }
              name="plate"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar placa'}]}
            >
              <Input size="large" className={styles.input} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <span className={styles.label}>
                  TIENDA
                </span>
              }
              name="location"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar tienda'}]}
            >
              <Select size="large" className={styles.input}>
                {branches.map(branch => (
                  <Option key={branch.id} value={branch.id}>{branch.zone}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <span className={styles.label}>
                  ESTADO
                </span>
              }
              name="status"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar estado'}]}
            >
              <Select size="large" className={styles.input}>
                <Option value="active">Activo</Option>
                <Option value="inactive">Inactivo</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item className={styles.itemSubmit}>
              <Button
                loading={sending}
                className={styles.submit}
                size="large"
                type="primary"
                htmlType="submit">
                GUARDAR DRIVER
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Editor;
