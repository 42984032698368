import React from "react";
import styles from "../styles/Result.module.css";
import Close from "../../../components/Close";
import moment from "moment";
import { Col, Row, Select } from "antd";
import { ReactComponent as Start } from "../../../assets/icons/star.svg";
const EvaluationResult = ({
  close,
  question,
  branches,
  selectedBranchId,
  handleSelectedBranchId,
}) => {
  const filter = (item) => {
    if (!selectedBranchId) {
      if (item.rating === 5) return item;
      if (item.rating === 4) return item;
      if (item.rating === 3) return item;
      if (item.rating === 2) return item;
      if (item.rating === 1) return item;
    } else {
      if (item.rating === 5 && item.id_branch === selectedBranchId) return item;
      if (item.rating === 4 && item.id_branch === selectedBranchId) return item;
      if (item.rating === 3 && item.id_branch === selectedBranchId) return item;
      if (item.rating === 2 && item.id_branch === selectedBranchId) return item;
      if (item.rating === 1 && item.id_branch === selectedBranchId) return item;
    }
  };
  return (
    <div style={styles.editor}>
      <div className={styles.header}>
        <span className={styles.title}>RESULTADOS DE EVALUACIÓN</span>
        <Close action={close} />
      </div>
      <span>
        <p className={styles.label}>TÍTULO DE LA EVALUACIÓN</p>
        <p className={styles.result}>{question.question}</p>
      </span>
      <span className={styles.item}>
        <p className={styles.label}>ESTATUS:</p>
        <p className={question.active === 1 ? styles.active : styles.finished}>
          {" "}
          {question.active === 1 ? "ACTIVA" : "FINALIZADA"}{" "}
        </p>
      </span>
      <span className={styles.item}>
        <p className={styles.label}>FECHA DE INICIO</p>
        <p className={styles.result}>
          {moment(question.dateBegin).format("DD/MM/YYYY")}
        </p>
      </span>
      <span className={styles.item}>
        <p className={styles.label}>FECHA DE FINALIZADA</p>
        <p className={styles.result}>
          {moment(question.dateEnd).format("DD/MM/YYYY")}
        </p>
      </span>
      <div className={styles.divider} />
      <br />
      <Select
        className={styles.input}
        options={branches.map((item) => {
          return { value: item.id, label: item.zone };
        })}
        allowClear
        onChange={(value) => handleSelectedBranchId(value)}
      />
      <br />
      <br />
      <Row>
        <Col span={18}>
          <Row>
            <Col span={8}>
              <div className={styles.starts}>
                {[1, 2, 3, 4, 5].map(() => (
                  <Start />
                ))}
              </div>
            </Col>
            <Col span={6}>
              {`${question.ratings.filter(filter).length} usuarios`}
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <div className={styles.starts}>
                {[1, 2, 3, 4].map(() => (
                  <Start />
                ))}
              </div>
            </Col>
            <Col span={6}>
              {`${question.ratings.filter(filter).length} usuarios`}
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <div className={styles.starts}>
                {[1, 2, 3].map(() => (
                  <Start />
                ))}
              </div>
            </Col>
            <Col span={6}>
              {`${question.ratings.filter(filter).length} usuarios`}
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <div className={styles.starts}>
                {[1, 2].map(() => (
                  <Start />
                ))}
              </div>
            </Col>
            <Col span={6}>
              {`${question.ratings.filter(filter).length} usuarios`}
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <div className={styles.starts}>
                {[1].map(() => (
                  <Start />
                ))}
              </div>
            </Col>
            <Col span={6}>
              {`${question.ratings.filter(filter).length} usuarios`}
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <div className={styles.containerElipse}>
            <div className={styles.elipse}>
              <p>{question.ratings.length}</p>
            </div>
            <p className={styles.label}>TOTAL DE RESPUESTAS</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EvaluationResult;
