import {createSlice} from '@reduxjs/toolkit';
import {getPages, editPages} from '../../api/Pages';

export const pagesSlice = createSlice({
  name: 'pages',
  initialState: {
    pages: [],
  },
  reducers: {
    setPages: (state, action) => {
      state.pages = action.payload;
    },
    setPage: (state, action) => {
      const index = state.pages.findIndex(el => el.id === action.payload.id);
      if (index > -1) {
        state.pages[index] = action.payload;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {setPages, setPage} = pagesSlice.actions;

export const getAllPages = () => async dispatch => {
  try {
    const response = await getPages();
    if (!response.error && response.status === 200) {
      dispatch(setPages(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const updatePage = data => async dispatch => {
  try {
    const response = await editPages(data);
    if (!response.error && response.status === 200) {
      dispatch(setPage(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const selectPages = state => state.pages.pages;

export default pagesSlice.reducer;
