import instance from './Request';

export const getZones = async () => {
  const request = await instance();
  let data = await request
    .get('/zone')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const changeZoneActive = async zone => {
  const request = await instance();
  let data = await request
    .post('/zone/activate', {zone})
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getLevels = async () => {
  const request = await instance();
  let data = await request
    .get('/main/levels')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const sendCobertura = async zone => {
  const request = await instance();
  let data = await request
    .post('/set-coverage', zone)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const newZone = async zone => {
  const request = await instance();
  let data = await request
    .post('/zone', zone)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const editZone = async zone => {
  const request = await instance();
  let data = await request
    .put(`/zone/${zone.id}`, zone)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const deleteZone = async id => {
  const request = await instance();
  let data = await request
    .delete(`/zone/${id}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
