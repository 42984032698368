import { Button, Modal, Spin, Table, Tag } from "antd";
import HeaderList from "../../../components/HeaderList";
import styles from "../styles/NotificationsUI.module.css";
import moment from 'moment'
import NotificationEditor from "./NotificationEditor";
const NotificationsUI = (props) => {
  const { showNewNotification,openNewNotification,notifications,loading,closeNewNotification,userId } = props;
  const columns = [
    {title:'Titulo',dataIndex:'title'},
    {title:'Fecha Envió',dataIndex:'dateBegin',render:(date)=> moment(date).format('DD/MM/YYYY HH:mm a')},
    {title:'Fecha Vencimiento',dataIndex:'dateEnd',render:(date)=> moment(date).format('DD/MM/YYYY HH:mm a')},
    {title:'Estado',dataIndex:'active',render:(active)=> active ? <Tag color="green" >Activo</Tag> : <Tag color="red" >Inactivo</Tag>},
    {title:'Descripción',dataIndex:'description'}
  ]
  return (
    <div>
      <HeaderList
        title="Notificaciones"
        actions={
          <Button size="large" type="primary" onClick={openNewNotification}>
            Nueva Notificación
          </Button>
        }
      />
      {
        loading ? <div className={styles.loader}> <Spin size="large" /> </div> :  <Table dataSource={notifications} columns={columns} /> 
      }
      <Modal
      footer={false}
      visible={showNewNotification}
      onCancel={closeNewNotification}
      destroyOnClose
      closable={false}
      >
       <NotificationEditor closeNewNotification={closeNewNotification}  userId={userId} />
      </Modal>
    </div>
  );
};

export default NotificationsUI;
