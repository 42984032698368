import {Link} from 'react-router-dom';
import styles from './styles/CardSection.module.css';

const CardSection = ({image, label, route}) => (
  <Link to={route} className={styles.card}>
    <img src={image} alt="" className={styles.image} />
    <span className={styles.label}>
      {label}
    </span>
  </Link>
);

export default CardSection;
