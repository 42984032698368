import HeaderList from "../../../components/HeaderList";
import { Modal, Spin, Table } from "antd";
import { getColumnSearchProps } from "../../../utils/tables";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListScannedThunk } from "../../../redux/slices/scanner";
import { ReactComponent as Eye } from "../../../assets/icons/eye.svg";
import styles from "../../monitor/styles/MonitorUI.module.css";
import OrderDetail from "../../../components/OrderDetail";

const ScannedListUI = ({
  loadingOrder,
  openDetails,
  modalOrder,
  closeDetails,
  orderDetail,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const listScanned = useSelector((state) => state.scanner.listScanned);


  const columnsTakeOut = [
    {
      title: "Ticket",
      dataIndex: "ticket",
      ...getColumnSearchProps("ticket"),
      render: (val, record) => <span>{val}</span>,
    },
    {
      title: "Cliente",
      dataIndex: "id_user",
      render: (val) => `${val.name} ${val.lastname}`,
    },
    {
      title: "Sucursal",
      dataIndex: "branch",
      align: "center",
      render: (val) => val.zone || "-",
    },
    {
      title: "Hora de redención",
      dataIndex: "redeem_time",
      align: "center",
      render: (val) => moment(val).format("DD/MM/YYYY hh:mm a"),
    },
    {
      title: "Teléfono",
      dataIndex: "id_user",
      align: "center",
      render: (val) => val.mobile,
    },
    {
      title: "Monto actual",
      dataIndex: "total",
      align: "center",
      render: (val) => `$${val}`,
    },
    {
      title: "Ver detalle",
      dataIndex: "id",
      align: "center",
      render: (val) => (
        <button
          disabled={loadingOrder}
          onClick={() => openDetails(val)}
          className={styles.eye}
        >
          {loadingOrder === val ? <Spin size="small" /> : <Eye />}
        </button>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      getListScannedThunk({
        id_user: user.info.id,
        idBranch: user.info.branch,
      })
    );
  }, []);

  return (
    <main>
      <HeaderList back="/home-scanner" title="LISTA REDIMIDOS" />
      <Table
        columns={columnsTakeOut}
        dataSource={listScanned}
        scroll={{ x: "100%" }}
        rowKey={(record) => record.id}
      />

      <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        visible={modalOrder}
        width={1150}
        closable={false}
        onCancel={closeDetails}
      >
        <OrderDetail order={orderDetail} close={closeDetails} />
      </Modal>
    </main>
  );
};

export default ScannedListUI;
