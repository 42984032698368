import instance from "./Request";

export const getClients = async (page = 1) => {
  const request = await instance();
  let data = await request.get(`/users/${page}`).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const changeActive = async (client) => {
  const request = await instance();
  let data = await request
    .post("/clients/block", { customer: client })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getClient = async (user) => {
  const request = await instance();
  let data = await request.get(`/users/profile/${user}`).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const getClientOrders = async (user) => {
  const request = await instance();
  let data = await request
    .post("v3/get-orders-history", {
      id_user: user,
      page: 1,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
