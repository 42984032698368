import {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import {message} from 'antd';
import { getAllZones, selectZones,  getAllLevels, removeMonitorZone, activeZone, } from '../../redux/slices/zones';
import { selectBranches, getBranche, getAllBranches } from '../../redux/slices/branches';
import {getAllClients, selectClients, setPage, selectPage, selectLoaded, setLoaded} from '../../redux/slices/clients';
import ZoneUI from './components/ZoneUI';


const Zone = () => {
  const loaded = useSelector(selectLoaded);
  const [loading, handleLoading] = useState(!loaded);
  const [zone, handleZone] = useState(null);
  const [levels, handleLevels] = useState([]);
  const branches = useSelector(selectBranches);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    const initialFetch = async () => {
      if (!loaded) {
        const [response, res_levels] = await Promise.all([
          dispatch(getAllBranches()),
          dispatch(getAllLevels()),
        ]);
        if (response.status !== 'success') {
          message.error('¡Hubo un problema!');
        }
        if(id !== null) {
          const [res_branch] = await Promise.all([
            dispatch(getBranche(id))
          ]);
          if (res_branch.status !== 'success') {
            message.error('¡Hubo un problema!');
          }
          handleZone(res_branch.branch);
        }
        handleLevels(res_levels.levels);
        handleLoading(false);
        dispatch(setLoaded(true));
      }
    };
    if(id !== null) {
        initialFetch();
    }
  }, [dispatch, id]);

  return (
    <ZoneUI
      loading={loading}
      zoneId={id}
      zone={zone}
      levels={levels}
      branches={branches}
      handleZone={handleZone}
    />
  );
}

export default Zone;
