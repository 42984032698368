import { createSlice } from '@reduxjs/toolkit';
import { getClientScannedList, getLastClientScaned } from '../../api/Scanner';

export const scannerSlice = createSlice({
    name: 'scanner',
    initialState: {
        lastScanned: [],
        listScanned: [],
        userScanned: null
    },
    reducers: {
        setLastScanned: (state, {payload}) => {
           state.lastScanned = payload.new_orders
           return state
        },
        setUserScanned: (state, {payload}) => {
            state.userScanned = payload
            return state
        },
        setListScanned: (state, {payload}) => {
            state.listScanned = payload.orders
            return state
        }
    }
})

export const {setLastScanned, setUserScanned, setListScanned} = scannerSlice.actions;

export const getLastScannedThunk = (data) => async(dispatch) => {
try {
    const response = await getLastClientScaned(data)
    if (!response.error && response.status === 200) {
        dispatch(setLastScanned(response.data))
        return {
          status: "success",
        };
      }
} catch (error) {
    return {
        status: "error",
        type: "unknown",
      };
}
}

export const getListScannedThunk = (data) => async(dispatch) => {
try {
    const response = await getClientScannedList(data)
    if (!response.error && response.status === 200) {
        dispatch(setListScanned(response.data))
        return {
          status: "success",
        };
      }
} catch (error) {
    return {
        status: "error",
        type: "unknown",
      };
}
}



export default scannerSlice.reducer;
