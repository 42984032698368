import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import { login } from "../../api/User";

const initialUser = localStorage.getItem("buffalo_admin")
  ? jwt_decode(localStorage.getItem("buffalo_admin"))
  : null;

export const userSlice = createSlice({
  name: "user",
  initialState: { info: initialUser },
  reducers: {
    setInfo: (state, action) => {
      const user = jwt_decode(action.payload);
      state.info = user;
    },
    logoutSuccess: (state) => {
      state.info = null;
      localStorage.removeItem("buffalo_admin");
    },
  },
});

// Action creators are generated for each case reducer function
export const { setInfo, logoutSuccess } = userSlice.actions;

export const signIn = (email, password) => async (dispatch) => {
  try {
    const response = await login(email, password);
    if (!response.error && response.status === 200) {
      const { token } = response.data;
      await localStorage.setItem("buffalo_admin", token);
      dispatch(setInfo(token));
      return {
        status: "success",
      };
    }
    if (response.error.response.status === 401) {
      return {
        status: "error",
        type: "wrong-user",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const selectUser = (state) => state.user.info;

export default userSlice.reducer;
