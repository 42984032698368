import {
  Tabs,
  Modal,
  Spin,
  Table,
  Space,
  Tooltip,
  Card,
  Col,
  Row,
  Statistic,
} from "antd";
import { Link } from "react-router-dom";
import { getColumnSearchProps } from "../../../utils/tables";
import ProductCard from "./ProductCard";
import TableOrders from "../../../components/TableOrders";
import cx from "classnames";
import moment from "moment";
import {
  FieldTimeOutlined,
  ShoppingOutlined,
  FundViewOutlined,
} from "@ant-design/icons";
import { ReactComponent as History } from "../../../assets/icons/history.svg";
import { ReactComponent as NewOrder } from "../../../assets/icons/new-order.svg";
import { ReactComponent as InPreparation } from "../../../assets/icons/inpreparation.svg";
import { ReactComponent as Eye } from "../../../assets/icons/eye.svg";
import { ReactComponent as Handbag } from "../../../assets/icons/handbag.svg";
import { ReactComponent as Warning } from "../../../assets/icons/warning.svg";
import { ReactComponent as Driver } from "../../../assets/icons/driver.svg";

import { ReactComponent as Child } from "../../../assets/icons/child.svg";
import { ReactComponent as ClockRefresh } from "../../../assets/icons/clock-refresh.svg";
import { ReactComponent as Empty } from "../../../assets/icons/empty.svg";
import Header from "../../../components/HeaderList";
// import Commentaries from "./Commentaries";
import OrderDetail from "../../../components/OrderDetail";
import styles from "../styles/MonitorUI.module.css";
import { useSelector } from "react-redux";

const { TabPane } = Tabs;

const MonitorUI = (props) => {
  const {
    modalOrder,
    loadingOrder,
    closeDetails,
    openDetails,
    loaded,
    isDriver,
    isAdmin,
    orderDetail,
    newOrders,
    dayOrders,
    // branches,
    acceptOrder,
    updateOrder,
    assignOrder,
    finishOrder,
    cancelOrder,
    resendOrder,
    sendPeya,
    getDriversBranch,
    openDriverConfirm,
    statistics,
    // closeModalCommetaries,
    // openModalCommetaries,
    // modalCommentaries,
    // transferOrder,
    // updateNotes,
  } = props;

  const getIcon = (status) => {
    if (status === 7) {
      return (
        <Tooltip title="Orden en preparación" placement="top">
          <InPreparation />
        </Tooltip>
      );
    } else if (status === 8) {
      <Tooltip title="Asignada a driver" placement="top">
        <Driver />
      </Tooltip>;
    } else if (status === 9) {
      <Tooltip title="Driver en domicilio" placement="top">
        Driver en domicilio
      </Tooltip>;
    } else if (status === 10) {
      <Tooltip title="Lista para domicilio" placement="top">
        Lista para domicilio
      </Tooltip>;
    } else if (status === 11) {
      return (
        <Tooltip title="Orden lista para recoger" placement="top">
          <Handbag />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Orden nueva" placement="top">
        <NewOrder />
      </Tooltip>
    );
  };

  const promosList = useSelector((state) => state.promosList);

  /* const columnsEatIn = [
    {
      title: "Ticket",
      dataIndex: "ticket",
      ...getColumnSearchProps("ticket"),
    },
    {
      title: "Número de mesa",
      dataIndex: "table_number",
      ...getColumnSearchProps("table_number"),
    },
    {
      title: "Tiempo en mesa",
      dataIndex: "created_at",
      align: "center",
    },
    {
      title: "Monto actual",
      dataIndex: "amount",
      align: "center",
    },
    {
      title: "Estado",
      dataIndex: "status",
      align: "center",
    },
    {
      title: "Ver detalle",
      dataIndex: "id",
      align: "center",
    },
  ]; */

  const columnsTakeOut = [
    {
      title: "Ticket",
      dataIndex: "ticket",
      ...getColumnSearchProps("ticket"),
      render: (val, record) => (
        <span>
          {(!record.cireba_remote_id || record.cireba_remote_id === "0") && (
            <Tooltip placement="top" title="Orden no ingresada a CIREBA">
              <Warning className={styles.warning} />
            </Tooltip>
          )}
          {val}
        </span>
      ),
    },
    {
      title: "Cliente",
      dataIndex: "id_user",
      render: (val) => `${val.name} ${val.lastname}`,
      ...getColumnSearchProps("id_user__name"),
    },
    {
      title: "Sucursal",
      dataIndex: "branch",
      align: "center",
      render: (val) => val.zone || "-",
      ...getColumnSearchProps("branch_name"),
    },
    {
      title: "Hora de ingreso",
      dataIndex: "created_time",
      align: "center",
      render: (val) => moment(val).format("DD/MM/YYYY hh:mm a"),
    },
    {
      title: "Teléfono",
      dataIndex: "id_user",
      align: "center",
      render: (val) => val.mobile,
      ...getColumnSearchProps("id_user__mobile"),
    },
    {
      title: "Monto actual",
      dataIndex: "total",
      align: "center",
      render: (val) => `$${val}`,
    },
    {
      title: "Estado",
      dataIndex: "status",
      align: "center",
      filters: [
        { value: 6, text: "Orden nueva" },
        { value: 7, text: "En preparación" },
        { value: 11, text: "Lista para recoger" },
      ],
      onFilter: (value, record) => record.status === value,
      render: (val) => getIcon(val),
    },
    {
      title: "Ver detalle",
      dataIndex: "id",
      align: "center",
      render: (val) => (
        <button
          disabled={loadingOrder}
          onClick={() => openDetails(val)}
          className={styles.eye}
        >
          {loadingOrder === val ? <Spin size="small" /> : <Eye />}
        </button>
      ),
    },
  ];

  const columnsDelivery = [
    {
      title: "Ticket",
      dataIndex: "ticket",
      ...getColumnSearchProps("ticket"),
      render: (val, record) => (
        <span>
          {(!record.cireba_remote_id || record.cireba_remote_id === "0") && (
            <Tooltip placement="top" title="Orden no ingresada a CIREBA">
              <Warning className={styles.warning} />
            </Tooltip>
          )}
          {val}
        </span>
      ),
    },
    {
      title: "Cliente",
      dataIndex: "id_user",
      render: (val) => `${val.name} ${val.lastname}`,
      ...getColumnSearchProps("id_user__name"),
    },
    {
      title: "Sucursal",
      dataIndex: "branch",
      align: "center",
      render: (val) => val.zone || "-",
      ...getColumnSearchProps("branch_name"),
    },
    {
      title: "Hora de ingreso",
      dataIndex: "created_time",
      align: "center",
      render: (val) => moment(val).format("DD/MM/YYYY hh:mm a"),
    },
    {
      title: "Teléfono",
      dataIndex: "id_user",
      align: "center",
      render: (val) => val.mobile,
      ...getColumnSearchProps("id_user__mobile"),
    },
    {
      title: "Monto actual",
      dataIndex: "total",
      align: "center",
      render: (val) => `$${val}`,
    },
    {
      title: "Estado",
      dataIndex: "status",
      align: "center",
      filters: [
        { value: 6, text: "Orden nuevas" },
        { value: 7, text: "En preparación" },
        { value: 8, text: "Asignada a driver" },
        { value: 9, text: "Driver en domicilio" },
        { value: 10, text: "Lista para domicilio" },
        { value: 11, text: "Despachar" },
      ],
      onFilter: (value, record) => record.status === value,
      render: (val) => getIcon(val),
    },
    {
      title: "Ver detalle",
      dataIndex: "id",
      align: "center",
      render: (val) => (
        <button
          disabled={loadingOrder}
          onClick={() => openDetails(val)}
          className={styles.eye}
        >
          {loadingOrder === val ? <Spin size="small" /> : <Eye />}
        </button>
      ),
    },
  ];

  if (!loaded) {
    return (
      <div className={cx(styles.view, styles.loader)}>
        <Spin size="large" />
      </div>
    );
  }
 console.log(dayOrders)
  return (
    <div className={styles.view}>
      {!isDriver ? (
        <Row>
          <Col span={24}>
            <Header
              title="Ordenes Activas"
              actions={
                <div className={styles.ordersButtons}>
                  {/* 
                  <Link to="/promocion" className={styles.history}>
                    <Child/>
                    <span>PROMOCIÓN DÍA DEL NIÑO ({promosList.orders_total})</span>
                  </Link>
                  */}
                  <Link to="/drivers" className={styles.history}>
                    <span> 📡 DRIVERS EN LINEA</span>
                  </Link>
                  <Link to="/historial" className={styles.history}>
                    <History />
                    <span>VER HISTORIAL DE ORDENES</span>
                  </Link>
                </div>
              }
            />
            <Tabs defaultActiveKey="2">
              {/* <TabPane tab={`EN MESA (${0})`} key="1">
        <Table columns={columnsEatIn} dataSource={[]} rowKey="id" />
      </TabPane> */}
              <TabPane
                tab={`PARA LLEVAR (${
                  dayOrders.filter((x) => x.orderType == 1 || x.orderType == 0)
                    .length
                })`}
                key="2"
              >
                <Table
                  columns={columnsTakeOut}
                  dataSource={dayOrders.filter(
                    (x) => x.orderType == 1 || x.orderType == 0
                  )}
                  rowKey="id"
                />
              </TabPane>
              <TabPane
                tab={`DOMICILIO (${
                  dayOrders.filter((x) => x.orderType == 2).length
                })`}
                key="3"
              >
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ display: "flex" }}
                >
                  <Row gutter={40} justify="center">
                    <Col span={4} style={{ textAlign: "center" }}>
                      <Card>
                        <Statistic
                          title="Tiempo Despacho"
                          value={
                            statistics.tiempoDespacho
                              ? moment(statistics.tiempoDespacho, "LTS").format(
                                  "mm[m] ss[s]"
                                )
                              : "0m"
                          }
                          prefix={<FieldTimeOutlined />}
                        />
                      </Card>
                    </Col>
                    <Col span={4} style={{ textAlign: "center" }}>
                      <Card>
                        <Statistic
                          title="Tiempo Entrega"
                          value={
                            statistics.tiempoFinaliza
                              ? moment(statistics.tiempoFinaliza, "LTS").format(
                                  "mm[m] ss[s]"
                                )
                              : "0m"
                          }
                          prefix={<FieldTimeOutlined />}
                        />
                      </Card>
                    </Col>
                    <Col span={4} style={{ textAlign: "center" }}>
                      <Card>
                        <Statistic
                          title="TQT PROM."
                          value={statistics.tqt}
                          prefix={<FundViewOutlined />}
                        />
                      </Card>
                    </Col>
                    <Col span={4} style={{ textAlign: "center" }}>
                      <Card>
                        <Statistic
                          title="No. Ordenes"
                          value={statistics.total_ordenes}
                          prefix={<ShoppingOutlined />}
                        />
                      </Card>
                    </Col>
                  </Row>
                  <Table
                    columns={columnsDelivery}
                    dataSource={dayOrders.filter((x) => x.orderType == 2)}
                    rowKey="id"
                  />
                </Space>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <div className={styles.tabsSummaryWrap}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="ORDENES ACTIVAS" key="1">
                  {newOrders.length ? (
                    newOrders.map((order) => (
                      <ProductCard
                        key={order.id}
                        order={order}
                        openDetails={openDetails}
                      />
                    ))
                  ) : (
                    <div className={styles.empty}>
                      <span>SIN ORDENES NUEVAS</span>
                    </div>
                  )}
                </TabPane>
                <TabPane tab="HISTORIAL" key="2">
                  {dayOrders.length ? (
                    <TableOrders
                      monitor
                      orders={dayOrders}
                      openDetails={openDetails}
                    />
                  ) : (
                    <div className={styles.empty}>
                      <span>NO SE HAN ENCONTRADO ORDENES</span>
                    </div>
                  )}
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      )}
      {/* <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        visible={modalCommentaries}
        width={400}
        closable={false}
        onCancel={closeModalCommetaries}
      >
        <Commentaries
          close={closeModalCommetaries}
          finishOrder={finishOrder}
          cancelOrder={cancelOrder}
          type={modalCommentaries}
        />
      </Modal> */}
      <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        visible={modalOrder}
        width={1150}
        closable={false}
        onCancel={closeDetails}
      >
        <OrderDetail
          order={orderDetail}
          monitor
          close={closeDetails}
          // branches={branches}
          acceptOrder={acceptOrder}
          updateOrder={updateOrder}
          assignOrder={assignOrder}
          finishOrder={finishOrder}
          cancelOrder={cancelOrder}
          resendOrder={resendOrder}
          sendPeya={sendPeya}
          getDriversBranch={getDriversBranch}
          openDriverConfirm={openDriverConfirm}
          // openModalCommetaries={openModalCommetaries}
          // transferOrder={transferOrder}
          // updateNotes={updateNotes}
        />
      </Modal>
    </div>
  );
};

export default MonitorUI;
