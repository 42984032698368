import { Spin, Select, DatePicker, Button, Table, Modal, Tag } from "antd";
import moment from "moment";
import cx from "classnames";
import { getColumnSearchProps } from "../../../utils/tables";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";
import { ReactComponent as Chart } from "../../../assets/icons/pie-chart.svg";
import Header from "../../../components/HeaderList";
import styles from "../styles/RatingsUI.module.css";
import Editor from "./Editor";
import EvaluationResult from "./EvaluationResult"

const { Option } = Select;

const RatingsUI = (props) => {
  const {
    loading,
    ratings,
    average,
    platform,
    setPlatform,
    confirmDelete,
    handleQuestion,
    question,
    openEditor,
    editorVisible,
    closeEditor,
    closeResult,
    openResult,
    resultVisible,
    branches,
    selectedBranchId,
    handleSelectedBranchId
  } = props;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
    },
    {
      title: "Nombre de Encuesta",
      dataIndex: "question",
      ...getColumnSearchProps("question"),
    },
    {
      title: "Fecha de Creación",
      dataIndex: "dateBegin",
      render: (val) => moment(val).format("DD/MM/YYYY"),
    },
    {
      title: "Estatus",
      dataIndex: "active",
      render: (val) =>
        val === 1 ? (
          <Tag color="var(--orange)">Activa</Tag>
        ) : (
          <Tag color="var(--darkGray)">Finalizada</Tag>
        ),
    },
    {
      title: "",
      align: "center",
      dataIndex: "id",
      render: (id, record) => (
        <div className={styles.actions}>
          <Chart onClick={() => openResult(record)} />
          <div className={styles.divider} />
          {record.active === 1 ? (
            <div className={styles.edit} onClick={() => openEditor(record)}>
              <Edit />
            </div>
          ) : (
            <div className={styles.delete} onClick={() => confirmDelete(id)}>
              <Trash />
            </div>
          )}
        </div>
      ),
    },
  ];
  return (
    <div className={styles.view}>
      <Header
        title="Evaluación"
        back="/ajustes"
        actions={
          <div>
            <Select
              className={cx(styles.input, styles.select)}
              loading={loading}
              disabled={loading}
              size="large"
              value={platform}
              onChange={setPlatform}
            >
              <Option value={2}>Delivery</Option>
              <Option value={1}>Take Out</Option>
              <Option value={0}>Pedidos Ya</Option>
            </Select>
            <Button
              className={styles.newQuestion}
              size="large"
              type="primary"
              onClick={openEditor}
            >
              NUEVA EVALUACIÓN
            </Button>
          </div>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          {/*
          <div className={styles.header}>
            <div className={styles.titleWrap}>
              <span className={styles.label}>PREGUNTA</span>
              <span className={styles.title}>{ratings.question}</span>
            </div>
            <Button
              className={styles.search}
              size="large"
              type="primary"
              onClick={() => {}}
            >
              Exportar
            </Button>
          </div>
      */}
          <div className={styles.bodyRatings}>
            <div className={styles.scores}>
              <div className={styles.scoreWrap}>
                <div className={styles.score}>{average}</div>
                <span className={styles.scoreLabel}>
                  PROMEDIO DE CALIFICACIÓN
                </span>
              </div>
              {/*
              <div className={styles.scoreWrap}>
                <div className={styles.score}>{ratings[0].ratings.length}</div>
                <span className={styles.scoreLabel}>TOTAL DE RESPUESTAS</span>
              </div>
              */}
            </div>
          </div>
          <Table columns={columns} dataSource={ratings} rowKey="id" />
        </div>
      )}
      <Modal
        destroyOnClose
        closable={false}
        visible={editorVisible}
        footer={false}
        onCancel={closeEditor}
        width={600}
      >
        <Editor close={closeEditor} handleQuestion={handleQuestion} question={question} />
      </Modal>
      <Modal
      destroyOnClose
      closable={false}
      visible={resultVisible}
      footer={false}
      onCancel={closeResult}
      width={600}
      >
        <EvaluationResult close={closeResult} question={question} branches={branches} selectedBranchId={selectedBranchId}
    handleSelectedBranchId={handleSelectedBranchId} />
      </Modal>
    </div>
  );
};

export default RatingsUI;
