import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Modal } from "antd";
import {
  getAllUsers,
  selectUsers,
  removeMonitorUser,
  handlePassword,
} from "../../redux/slices/users";
import { getAllBranches, selectBranches } from "../../redux/slices/branches";
import UsersUI from "./components/UsersUI";

const Users = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [user, handleUser] = useState(null);
  const [modalPassword, handleModalPassword] = useState(false);
  const users = useSelector(selectUsers);
  const branches = useSelector(selectBranches);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      const [response] = await Promise.all([
        dispatch(getAllUsers()),
        dispatch(getAllBranches()),
      ]);
      if (response.status !== "success") {
        message.error("¡Hubo un problema!");
      }
      handleLoading(false);
    };
    initialFetch();
  }, [dispatch]);

  const openEditor = (userToEdit) => {
    handleUser(userToEdit);
    handleEditorVisible(true);
  };

  const newUser = () => {
    handleUser(null);
    handleEditorVisible(true);
  };

  const closeEditor = () => {
    handleUser(null);
    handleEditorVisible(false);
  };

  const openModalPassword = () => {
    handleModalPassword(true);
  };

  const closeModalPassword = () => {
    handleModalPassword(false);
  };

  const changePassword = async (password) => {
    const response = await dispatch(handlePassword(user.id, `${password}`));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      message.success("Contraseña actualizada");
    }
  };

  const confirmDelete = (idUser) => {
    Modal.confirm({
      title: "¿Estás segúro de eliminar este usuario?",
      content: "Esta información no se podrá recuperar.",
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        await removeUser(idUser);
      },
      onCancel() {},
    });
  };

  const removeUser = async (idUser) => {
    const response = await dispatch(removeMonitorUser(idUser));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      message.success("Usuario eliminado correctamente");
    }
  };

  return (
    <UsersUI
      loading={loading}
      branches={branches}
      user={user}
      users={users}
      openEditor={openEditor}
      editorVisible={editorVisible}
      closeEditor={closeEditor}
      newUser={newUser}
      confirmDelete={confirmDelete}
      handleUser={handleUser}
      modalPassword={modalPassword}
      openModalPassword={openModalPassword}
      closeModalPassword={closeModalPassword}
      changePassword={changePassword}
    />
  );
};

export default Users;
