import React, { useState } from "react";
import styles from "../styles/KpisUI.module.css";
import HeaderList from "../../../components/HeaderList";
import DateFilters from "./DateFilters";
import NavItems from "./NavItems";
import TopProducts from "./TopProducts";
import OrdersArea from "./OrdersArea";
import CustomerTracking from "./CustomerTracking";
import Summary from "./Summary";
import AverageTimes from "./AverageTimes";
import TotalOverview from "./TotalOverview";
import OrdersSales from "./OrdersSales";
import OrdersPerHour from "./OrdersPerHour";

const KpisUI = () => {
  const [activeCard, handleActiveCard] = useState({ key: 1, label: "RESUMEN" });
  const [activeDate, handleActiveDate] = useState("1");

  const content = {
    1: <Summary />,
    2: <TopProducts />,
    3: <AverageTimes />,
    4: <OrdersArea />,
    5: <TotalOverview />,
    6: <OrdersSales />,
    7: <OrdersPerHour />,
    8: <CustomerTracking />,
  };
/* 

tiempos promedios

hacer sumatoria total por sucursal cuando filtre por sucursal

pedidos por zona pendiente

filtrar en el frontend por sucursales

reporte de recompras

en boton listado mandar a pantalla de listado similar a listado de usuario
mandar parametro a la api que trae todos los usuarios */

  return (
    <div className={styles.view}>
      <HeaderList title={activeCard.key === 1 ? "KPI's" : activeCard.label} />
      <div className={styles.layout}>
        <div>
          {![5, 7, 8].includes(activeCard.key) && (
            <DateFilters
              active={activeDate}
              handleActive={handleActiveDate}
              activeCard={activeCard}
            />
          )}
          <div className={styles.content}>{content[activeCard.key]}</div>
        </div>
        <NavItems activeCard={activeCard} handleActiveCard={handleActiveCard} />
      </div>
    </div>
  );
};

export default KpisUI;
