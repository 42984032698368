import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {selectUser} from './redux/slices/user';
import Dashboard from './pages/dashboard/Main';
import Login from './pages/login/Main';
import { setupNotifications } from './utils/firebaseConfig';
import { toastNotification, sendNativeNotification } from './utils/notificationHelpers';
import useVisibilityChange from './utils/useVisibilityChange';

const App = () => {
  const user = useSelector(selectUser);
  const [country, setCountry] = useState('sv');
  const isForeground = useVisibilityChange();

  useEffect( () => {
    
    setupNotifications((message) => {
      if (isForeground) {
        // App is in the foreground, show toast notification
        toastNotification({
          title: message.title,
          description: message.body,
          status: "info",
        });
      } else {
        // App is in the background, show native notification
        sendNativeNotification({
          title: message.title,
          body: message.body,
        });
      }
    });

  }, []); 

  return (
    <Router>
      <Routes>
        <Route path="/*" element={user ? <Dashboard /> : <Login />} />
      </Routes>
    </Router>
  );
}

export default App;
