import React from 'react'
import { Card } from "antd";
import cx from "classnames";
import styles from "../styles/KpisUI.module.css";

const NavItems = ({activeCard,handleActiveCard}) => {
    
    const cards = [
        { key: 1, label: "RESUMEN" },
        { key: 2, label: "TOP PRODUCTOS" },
        { key: 3, label: "TIEMPOS PROMEDIOS" },
        { key: 4, label: "PEDIDOS POR ZONA" },
        { key: 5, label: "RESUMEN DE TOTALES" },
        { key: 6, label: "PEDIDOS Y VENTAS" },
        { key: 7, label: "ORDENES POR HORA" },
        { key: 8, label: "REPORTE DE RECOMPRAS" },
      ];
  return (
    <div className={styles.cardsContainer}>
    {cards.map((card) => (
      <Card hoverable className={cx(styles.card,activeCard.key === card.key && styles.activeCard)} size="small" bordered onClick={()=>handleActiveCard(card)}>
        {card.label}
      </Card>
    ))}
  </div>
  )
}

export default NavItems