import { Html5Qrcode } from 'html5-qrcode'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import closeIcon from '../../../assets/icons/close-icon.svg'
import HeaderList from '../../../components/HeaderList'
import instance from "../../../api/Request";
import { useDispatch } from 'react-redux'
import { setUserScanned } from '../../../redux/slices/scanner'
import { message } from 'antd'

const CamScannerUI = () => {
  const [html5QrCode, setHtml5QrCode] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const config = { fps: 10, qrbox: 250 } // configuracion del escaner

  const qrCodeSuccessCallback = (decodedText) => { // callback si todo sale bien
    console.log(decodedText)
    checkQrCodeApi(decodedText)

    html5QrCode.stop().then(() => {
      navigate('/home-scanner')
    })
  }

  useEffect(() => {
    const QR = new Html5Qrcode('scanner') // Creamos la instancia y pasamos el id del elemento
    setHtml5QrCode(QR)

    return () => {
      if (html5QrCode) {
        html5QrCode.stop()
      }
    }
  }, [])

  useEffect(() => {
    if (html5QrCode) { // Si existe la instancia iniciamos la camara
      html5QrCode.start({ facingMode: 'environment' }, config, qrCodeSuccessCallback) // configuracion para que la camara ocupe la trasera en mobil
    }
  }, [html5QrCode])

  const checkQrCodeApi = async(code) => {
    const request = await instance();
    let data = await request.post('/api/verificar-qr', { code })
    .then((res) => {
      if(res.data.status === 'success'){
        message.success('¡Código escaneado exitosamente! Abriendo detalles...')
        dispatch(setUserScanned(res.data.order))
      }else{
        message.error(res.data.message)
      }
  
    })
    .catch((error) => {
      console.log(error);
      return {
        error,
      };
    });
    return data;
  }

  return (
    <>
      <HeaderList
        back="/home-scanner"
        title="CAM SCANNER"
        clickAction={() => html5QrCode.stop()}
      />
      <main className='min-h-[400.25px] max-w-[450px] mx-auto relative'>
      <div id='scanner' />
      <img
       alt=''
        className='absolute right-[.5rem] top-[.5rem] bg-[#ff4d4f] rounded-full'
        src={closeIcon}
        onClick={() => {
          navigate('/home-scanner')
          html5QrCode.stop()
        }}
      />
    </main>
    </>
  
  )
}

export default CamScannerUI
