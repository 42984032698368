import {createSlice} from '@reduxjs/toolkit';
import {getZones, getLevels, newZone, editZone, deleteZone, changeZoneActive, sendCobertura} from '../../api/Zones';

export const zonesSlice = createSlice({
  name: 'zones',
  initialState: {
    zones: [],
    coverages: [],
  },
  reducers: {
    setZones: (state, action) => {
      state.zones = action.payload;
    },
    setActive: (state, action) => {
      const {zone, val} = action.payload;
      const index = state.zones.findIndex(el => el.id === zone)
      state.zones[index].active = val;
    },
    setZone: (state, action) => {
      const index = state.zones.findIndex(el => el.id === action.payload.id);
      if (index > -1) {
        state.zones[index] = action.payload;
      }
    },
    setCoverage: (state, action) => {
      const index = state.coverages.findIndex(el => el.id === action.payload.id);
      if (index > -1) {
        state.coverages[index] = action.payload;
      }
    },
    addZone: (state, action) => {
      state.zones.push(action.payload);
    },
    addCoverage: (state, action) => {
      state.coverages.push(action.payload);
    },
    removeZone: (state, action) => {
      const index = state.zones.findIndex(el => el.id === action.payload);
      if (index > -1) {
        state.zones.splice(index, 1);
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {setZones, setZone, addZone, removeZone, setActive, addCoverage, setCoverage} = zonesSlice.actions;

export const getAllZones = () => async dispatch => {
  try {
    const response = await getZones();
    if (!response.error && response.status === 200) {
      dispatch(setZones(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const activeZone = (id, val) => async dispatch => {
  try {
    dispatch(setActive({zone: id, val: !val}));
    const response = await changeZoneActive(id);
    if (!response.error && response.status === 200) {
      return {
        status: 'success',
      };
    }
    dispatch(setActive({zone: id, val}));
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    dispatch(setActive({zone: id, val}));
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};


export const getAllLevels = () => async () => {
  try {
    const response = await getLevels();
    if (!response.error && response.status === 200) {
      return {
        status: 'success',
        levels: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const setCobertura = data => async dispatch => {
  try {
    const response = await sendCobertura(data);
    if (!response.error && response.status === 200) {
      dispatch(addCoverage(response.data));
      return {
        status: 'success',
        coverage: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const createZone = data => async dispatch => {
  try {
    const response = await newZone(data);
    if (!response.error && response.status === 200) {
      dispatch(addZone(response.data));
      return {
        status: 'success',
        zone: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const updateZone = data => async dispatch => {
  try {
    const response = await editZone(data);
    if (!response.error && response.status === 200) {
      dispatch(setZone(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const removeMonitorZone = id => async dispatch => {
  try {
    const response = await deleteZone(id);
    if (!response.error && response.status === 200) {
      dispatch(removeZone(id));
      return {
        status: 'success',
        // departments: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const selectZones = state => state.zones.zones;

export default zonesSlice.reducer;
