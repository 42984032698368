import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import {
  getAllProducts,
  handleActive,
  selectProducts,
  selectCategories,
} from "../../redux/slices/products";
import { selectUser } from "../../redux/slices/user";
import ProductsUI from "./components/ProductsUI";

const Products = () => {
  const [loading, handleLoading] = useState(true);
  const products = useSelector(selectProducts);
  const categories = useSelector(selectCategories);
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const initialFetch = useCallback(async () => {
    handleLoading(true);
    const response = await dispatch(getAllProducts(user.branch));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
  }, [dispatch, user]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  const handleProductActive = async (idProduct, val, type, branch) => {
    const response = await dispatch(handleActive(idProduct, val, type, branch));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  };

  return (
    <ProductsUI
      loading={loading}
      products={products}
      initialFetch={initialFetch}
      handleProductActive={handleProductActive}
      categories={categories}
    />
  );
};

export default Products;
