import instance from "./Request";

export const getProducts = async (branch) => {
  const request = await instance();
  let data = await request.post("/partners/menu/any/44", { branch_id: branch }).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const changeActive = async (product, active, platform, branch) => {
  const request = await instance();
  let data = await request
    .post("/products/disable", { skus: [{ sku: product, platform, active, branch }] })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getExtras = async (branch) => {
  const request = await instance();
  let data = await request.post("/partners/extras/any/44", { branch_id: branch }).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const changeExtraActive = async (extra, active, platform, branch, cat) => {
  const request = await instance();
  let data = await request
    .post("/extras/disable", { skus: [{ sku: extra, platform,  active, branch, cat }] })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const changeSuggested = async (extra, active, platform, branch, cat) => {
  const request = await instance();
  let data = await request
    .post("/suggested/disable", { skus: [{ sku: extra, platform,  active, branch, cat }] })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};