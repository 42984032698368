import { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, Select, message, Checkbox } from "antd";
import { useDispatch } from "react-redux";
import cx from "classnames";
import { createUser, updateUser } from "../../../redux/slices/users";
import Close from "../../../components/Close";
import styles from "../styles/Editor.module.css";

const { Option } = Select;

const Editor = ({ close, user, branches, handleUser, openModalPassword }) => {
  const [sending, handleSending] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const rols = [
    { label: "Ordenes", value: "dashboard" },
    { label: "Portada", value: "cover" },
    { label: "Productos", value: "products" },
    { label: "Sucursales", value: "branches" },
    { label: "Ajustes", value: "settings" },
  ];

  useEffect(() => {
    const initialFetch = async () => {
      if (user) {
        const newValues = {
          username: user.username,
          email: user.email,
          branch: user.branch.id,
          rols: JSON.parse(user.rols),
        };
        form.setFieldsValue(newValues);
      }
    };
    initialFetch();
  }, [user]);

  const save = async () => {
    try {
      handleSending(true);
      const values = await form.validateFields();
      const info = {
        username: values.username,
        email: values.email,
        telefono: values.phone,
        rols: values.rols,
        branches: [values.branch],
        // admin: values.branch === 0 ? 1 : 0,
      };
      if (user) {
        info.id_user = user.id;
      } else {
        info.password = values.password;
      }
      const indexBranch = branches.findIndex((el) => el.id === values.branch);
      const selectedBranch = branches[indexBranch];
      if (user) {
        const response = await dispatch(updateUser(info, selectedBranch));
        if (response.status === "success") {
          handleSending(false);
          message.success("Cambios realizados con éxito");
        } else if (response.type === "user-found") {
          handleSending(false);
          message.warning("Este usuario no esta disponible");
        } else {
          handleSending(false);
          message.error("¡Hubo un problema! Inténtalo de nuevo");
        }
      } else {
        const response = await dispatch(createUser(info, selectedBranch));
        if (response.status === "success") {
          message.success("Cambios realizados con éxito");
          handleSending(false);
          handleUser(response.user);
        } else if (response.type === "user-found") {
          handleSending(false);
          message.warning("Este usuario no esta disponible");
        } else {
          handleSending(false);
          message.error("¡Hubo un problema! Inténtalo de nuevo");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.editor}>
      <div className={styles.header}>
        <span className={styles.title}>
          {user ? "Editar usuario" : "Nuevo usuario"}
        </span>
        <Close action={close} />
      </div>
      <Form
        name="branch-editor"
        autoComplete="off"
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={save}
      >
        <Row gutter={20}>
          <Col span={24}>
            <Row gutter={20}>
              <Col span={24}>
                <Form.Item
                  label={<span className={styles.label}>NOMBRE</span>}
                  name="username"
                  className={styles.itemColumn}
                  rules={[{ required: true, message: "Ingresar nombre" }]}
                >
                  <Input size="large" className={styles.input} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={24}>
                <Form.Item
                  label={<span className={styles.label}>SUCURSAL</span>}
                  name="branch"
                  className={styles.itemColumn}
                  rules={[{ required: true, message: "Ingresar sucursal" }]}
                >
                  <Select
                    showSearch
                    size="large"
                    className={styles.input}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {/* <Option value={0}>Administrador</Option> */}
                    {branches.map((branch) => (
                      <Option key={branch.id} value={branch.id}>
                        {branch.zone}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={user ? 24 : 12}>
                <Form.Item
                  label={<span className={styles.label}>CORREO</span>}
                  name="email"
                  className={styles.itemColumn}
                  rules={[{ required: true, message: "Ingresar correo" }]}
                >
                  <Input size="large" type="email" className={styles.input} />
                </Form.Item>
              </Col>
              {!user && (
                <Col span={12}>
                  <Form.Item
                    label={<span className={styles.label}>CONTRASEÑA</span>}
                    name="password"
                    className={styles.itemColumn}
                    rules={[{ required: true, message: "Ingresar contraseña" }]}
                  >
                    <Input size="large" className={styles.input} />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={<span className={styles.label}>PERMISOS</span>}
                  name="rols"
                  className={styles.itemColumn}
                  rules={[{ required: true, message: "Ingresar permisos" }]}
                >
                  <Checkbox.Group options={rols} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item className={styles.itemSubmit}>
              {user && (
                <Button
                  className={cx(styles.submit, styles.password)}
                  onClick={openModalPassword}
                  size="large"
                  type="primary"
                >
                  CAMBIAR CONTRASEÑA
                </Button>
              )}
              <Button
                loading={sending}
                className={styles.submit}
                size="large"
                type="primary"
                htmlType="submit"
              >
                GUARDAR USUARIO
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Editor;
