import { Table, Switch, Button, Spin, Tooltip, Space } from "antd";
import { selectUser } from "../../../redux/slices/user";
import { getColumnSearchProps } from "../../../utils/tables";
import Header from "../../../components/HeaderList";
import styles from "../styles/ProductsUI.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProductsUI = (props) => {
  const { loading, products, handleProductActive, categories } = props;

  const user = useSelector(selectUser);


  const getCategoryName = (role) => {
    const index = categories.findIndex((el) => el.value === role);
    if (index > -1) {
      return categories[index].text;
    }
    return "-";
  };

  const irhacia = useNavigate();
  const goToCondiments = () => {
    irhacia("/productos/salsas");
  };
  const goToSuggets = () => {
    irhacia("/productos/recomendados");
  }

  const columns = [
    /* {
      title: "ID",
      dataIndex: "id",
      align: "center",
    }, */
    {
      title: "Nombre",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Descripción",
      dataIndex: "description",
      width: 200,
      render: (val) => (
        <Tooltip placement="bottom" title={val}>
          <span className={styles.description}>{val}</span>
        </Tooltip>
      ),
    },
    {
      title: "Categoría",
      dataIndex: "id_category",
      align: "center",
      filters: categories,
      onFilter: (val, record) => record.id_category === val,
      render: (val) => getCategoryName(val),
    },
    {
      title: "Precio",
      dataIndex: "price",
      align: "center",
      render: (val) => `$${val.toFixed(2)}`,
    },
    {
      title: "SKU",
      dataIndex: "sku",
      ...getColumnSearchProps("sku"),
    },
    {
      title: "Take Out",
      align: "center",
      dataIndex: "take_out",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.take_out === value,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={() => handleProductActive(record.id, val, "take_out", user.branch)}
        />
      ),
    },
   /*  {
      title: "Eat In",
      align: "center",
      dataIndex: "eat_in",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.eat_in === value,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={() => handleProductActive(record.id, val, "eat_in", user.branch)}
        />
      ),
    }, */
  ];
  return (
    <div className={styles.view}>
      <Header title="Productos" />
      <Space style={{paddingBottom: "1em"}}>
          <Button
            onClick={goToCondiments}
            type="primary"
            size="large"
            className="main-button"
          >
            CONDIMENTS
          </Button>
          <Button
            onClick={goToSuggets}
            type="primary"
            size="large"
            className="main-button"
          >
            RECOMENDADOS/SUGERIDOS
          </Button>
      </Space>
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table columns={columns} dataSource={products} rowKey="id" />
        </div>
      )}
    </div>
  );
};

export default ProductsUI;
