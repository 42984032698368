import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal, message } from "antd";
import {
  getClientDetails,
  selectClient,
  activeClient,
} from "../../redux/slices/clients";
import { getOrderDetails } from "../../redux/slices/monitor";
import ClientsUI from "./components/ClientsUI";
import exportOrders from "../../utils/exportOrders";

const Clients = () => {
  const [loading, handleLoading] = useState(true);
  const [loadingOrder, handleLoadingOrder] = useState(false);
  const [order, handleOrder] = useState(null);
  const [visibleEditor,handleVisibleEditor] = useState(false)
  const [visiblePasswordEditor,handleVisiblePasswordEditor]= useState(false)
  const [address,handleAddress] = useState({})
  const [visibleAddressEditor,handleVisibleAddressEditor] = useState(false)
  const client = useSelector(selectClient);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    const initialFetch = async () => {
      const [response] = await Promise.all([dispatch(getClientDetails(id))]);
      if (response.status !== "success") {
        message.error("¡Hubo un problema!");
      }
      handleLoading(false);
    };
    initialFetch();
  }, [dispatch, id]);

  const closeOrder = () => {
    handleOrder(null);
  };

  const exportData = () => {
    exportOrders(client.orders);
  };

  const handleActive = async () => {
    const response = await dispatch(activeClient(client.id, client.active));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  };

  const openDetails = async (order) => {
    handleLoadingOrder(order);
    const response = await dispatch(getOrderDetails(order));
    handleLoadingOrder(false);
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      handleOrder(response.order);
      // handleModalOrder(true);
    }
  };
  
  const closeEditor = () => {
    handleVisibleEditor(false)
  }
  const openEditor = () => {
    handleVisibleEditor(true)
  }

  const openPasswordEditor = () => {
    handleVisiblePasswordEditor(true)
  }

  const closePasswordEditor = () => {
    handleVisiblePasswordEditor(false)
  }

  const openAddressEditor = (address) => {
    handleAddress(address)
    handleVisibleAddressEditor(true)
  }

  const closeAddressEditor = () => {
    handleVisibleAddressEditor(false)
  }
  const confirmDeleteAddress = (id) => {
   Modal.confirm({
    title:'Eliminar Dirección',
    content:'¿Estas Seguro de Eliminar esta Dirección?',
    onCancel(){},
    async onOk(){}
   })
  }
  return (
    <ClientsUI
      loading={loading}
      client={client}
      order={order}
      openOrder={openDetails}
      closeOrder={closeOrder}
      exportData={exportData}
      handleActive={handleActive}
      loadingOrder={loadingOrder}
      visibleEditor={visibleEditor}
      openEditor={openEditor}
      closeEditor={closeEditor}
      visiblePasswordEditor={visiblePasswordEditor}
      openPasswordEditor={openPasswordEditor}
      closePasswordEditor={closePasswordEditor}
      openAddressEditor={openAddressEditor}
      closeAddressEditor={closeAddressEditor}
      visibleAddressEditor={visibleAddressEditor}
      address={address}
      confirmDeleteAddress={confirmDeleteAddress}
    />
  );
};

export default Clients;
