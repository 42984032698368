import { createSlice } from "@reduxjs/toolkit";
import { getPromosList } from "../../api/PromosList";

export const promosList = createSlice({
  name: "promosList",
  initialState: {},
  reducers: {
    setPromosList: (state, { payload }) => {
      return payload;
    },
  },
});

export const { setPromosList } = promosList.actions;

export const getPromosListThunk = () => async (dispatch) => {
  try {
    const response = await getPromosList();
    if (!response.error && response.status === 200) {

      dispatch(setPromosList(response.data));
      return {
        status: "success",
      };
    }
  } catch (error) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export default promosList.reducer;
