import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyDCIwcId5OVY5EJohtmkcg61FmBG6TX6Xg",
    authDomain: "bw-apps-feadc.firebaseapp.com",
    projectId: "bw-apps-feadc",
    storageBucket: "bw-apps-feadc.appspot.com",
    messagingSenderId: "868236838572",
    appId: "1:868236838572:web:b19b6e2fc8bcd629288109",
    measurementId: "G-KT0NGV8ECL"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const setupNotifications = async () => {
    try {
        // Request permission for notifications
        const permission = await Notification.requestPermission();
        
        if (permission === 'granted') {
            console.log('Notification permission granted.');
        // Get the FCM token
            const token = await getToken(messaging);
            console.log('FCM Token:', token);

        } else {
            console.log('Notification permission denied.');
        }
        // Handle foreground notifications
        onMessage(messaging, (payload) => {
            console.log('Foreground Message:', payload);
            // Handle the notification or update your UI
        });
    } catch (error) {
        console.error('Error setting up notifications:', error);
    }
};

export { messaging, setupNotifications };