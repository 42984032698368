import React from 'react'

const LayoutRounded = ({ children, bg, minH }) => {
  return (
    <section className='  pt-3 xl:pt-0 rounded-t-[20px] xl:rounded-t-none'>
      <div className={`${bg || 'bg-white'} overflow-hidden py-[2rem] xl:pb-[5rem] xl:rounded-t-none xl:mx-auto text-center  rounded-t-[20px] ${minH || 'min-h-[calc(100vh-258px)]'} xl:min-h-[calc(100vh-266px)]`}>
        {children}
      </div>
    </section>
  )
}

export default LayoutRounded
