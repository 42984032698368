import {
  Button,
  Modal,
  Spin,
  Table,
} from "antd";
import styles from "../styles/EmergencyBranchUI.module.css";
import { ReactComponent as Branch } from "../../../assets/icons/branch.svg";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import HeaderList from "../../../components/HeaderList";
import Editor from "./Editor";

const EmergencyBranchUI = ({
  branches,
  branchesBackup,
  loading,
  confirmDelete,
  sending,
  onFinish,
  editorVisible,
  openEditor,
  closeEditor,
  editBackup,
  selectedBackup
}) => {
  const columns = [
    { title: "Sucursal", dataIndex: "branch_origin", align: "center" },
    { title: "Sucursal backup", dataIndex: "branch_backup", align: "center" },
    { title: "Tipo de backup", dataIndex: "type_backup", align: "center" },
    { title: "Razón del cambio", dataIndex: "backup_reason", align: "center" },
    { title: "Fecha de Cambio", dataIndex: "backup_time", align: "center" ,render:(date)=> date ?? "-"},
    {
      title: "Opciones",
      dataIndex: "id",
      align: "center",
      render: (id,record) => (
        <div className={styles.actions}>
          <Trash onClick={() => confirmDelete(id)} />
          <Edit onClick={()=> editBackup(record)}/>
        </div>
      ),
    },
  ];
  return (
    <div className={styles.view}>
      <HeaderList
        title={"Sucursal Emergencia"}
        back={"/sucursales"}
        actions={
          <Button type="primary" size="large" onClick={openEditor}>
            NUEVA SUCURSAL BACKUP
          </Button>
        }
      />
      <Modal
        destroyOnClose
        closable={false}
        visible={editorVisible}
        onCancel={closeEditor}
        footer={false}
        width={600}
      >
        <Editor
          closeEditor={closeEditor}
          branches={branches}
          sending={sending}
          onFinish={onFinish}
          selectedBackup={selectedBackup}
        />
      </Modal>
      <span className={styles.tableLabel}>
        <Branch />
        <p>Domicilios sin servicio</p>
      </span>
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <Table columns={columns} dataSource={branchesBackup} />
      )}
    </div>
  );
};

export default EmergencyBranchUI;
