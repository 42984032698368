import React, { useCallback, useEffect, useState } from "react";
import EmergencyBranchUI from "./components/EmergencyBranchUI";
import { useDispatch, useSelector } from "react-redux";
import {
  createBranchBackupThunk,
  deleteBranchBackupThunk,
  editBranchBackupThunk,
  getAllBranches,
  getBranchesBackupThunk,
  selectBranches,
  selectBranchesBackup,
} from "../../redux/slices/branches";
import { selectUser } from "../../redux/slices/user";
import { Modal, message } from "antd";

const Main = () => {
  const branches = useSelector(selectBranches);
  const branchesBackup = useSelector(selectBranchesBackup);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [loading, handleLoading] = useState(true);
  const [sending, handleSending] = useState(false);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [selectedBackup, handleSelectedBackup] = useState(null);
  const initialFetch = useCallback(async () => {
    const [response] = await Promise.all([
      dispatch(getBranchesBackupThunk(user.id)),
      dispatch(getAllBranches(user.id)),
    ]);
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
  }, [dispatch, user]);

  const onFinish = async (values) => {
    try {
      values.userId = user.id;
      handleSending(true);
      let response = {
        status: ''
      }
      if(values.branchId){
       response =  await dispatch(editBranchBackupThunk(values))
      }else{
        response = await dispatch(createBranchBackupThunk(values));
      }
    
      if (response.status === "success") {
        message.success(`Sucursal de emergencia ${values.branchId ? 'editada' : 'guardada' } exitosamente!`);
        closeEditor();
        dispatch(getBranchesBackupThunk(user.id));
        return {
          status: "success",
        };
      } else {
        message.error("¡Hubo un problema! Inténtalo de nuevo");
        return {
          status: "error",
          type: "unknown",
        };
      }
    } catch (e) {
      return {
        status: "error",
        type: "unknown",
      };
    } finally {
      handleSending(false);
    }
  };
  useEffect(() => {
    initialFetch();
  }, [initialFetch]);
  const confirmDelete = (id) => {
    Modal.confirm({
      title: "¿Esta seguro de restablecer esta sucursal?",
      content: "",
      okText: "Restablecer",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        await removeBranchBackup(id);
      },
      onCancel() {},
    });
  };
  const removeBranchBackup = async (id) => {
    const response = await dispatch(deleteBranchBackupThunk(user.id, id));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      message.success("Sucursal restablecida correctamente");
    }
  };

  const openEditor = () => handleEditorVisible(true);
  const closeEditor = () => {
    if (selectedBackup !== null) {
      handleSelectedBackup(null);
    }
    handleEditorVisible(false);
  };
  const mapBranches = branches.map((item) => {
    return { value: item.id, label: item.zone };
  });

  const editBackup = (backup) => {
    handleSelectedBackup(backup);
    openEditor();
  };
  return (
    <EmergencyBranchUI
      branches={mapBranches}
      branchesBackup={branchesBackup}
      loading={loading}
      onFinish={onFinish}
      confirmDelete={confirmDelete}
      sending={sending}
      editorVisible={editorVisible}
      openEditor={openEditor}
      closeEditor={closeEditor}
      editBackup={editBackup}
      selectedBackup={selectedBackup}
    />
  );
};

export default Main;
