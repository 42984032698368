import { Button, Form, Select } from "antd";
import styles from "../styles/Content.module.css";
import cx from "classnames"
const TopProducts = () => {
  const data = [
    { sucursal:'Las Cascadas', score: 1, product: "Combo 10 Alas o Chunks", amount: 25 },
    { sucursal:'Las Cascadas', score: 1, product: "Combo 10 Alas ", amount: 25 },
    { sucursal:'Las Cascadas', score: 1, product: "Combo 10 Chunks", amount: 25 },
  ];
  return (
    <div className={styles.cardContent}>
      <Form layout="inline">
        <Form.Item>
          <Select className={styles.select} size="large" placeholder="Todas las sucursales" />
        </Form.Item>
        <Button type="primary" size="large">
          Filtrar
        </Button>
      </Form>
      <br />
      <div className={styles.containerTable}>
        <span className={styles.headerItemTable}>Sucursal</span>
        <span className={styles.headerItemTable}>Posición</span>
        <span className={styles.headerItemTable}>Producto</span>
        <span className={cx(styles.headerItemTable,styles.lastHeaderItemTable)}>Cantidad</span>
        <span className={styles.bodyCellTable}>
          {data.map((item) => (
            <p>{item.sucursal}</p>
          ))}
        </span>
        <span className={styles.bodyCellTable}>
          {data.map((item) => (
            <p>{item.score}</p>
          ))}
        </span>
        <span className={styles.bodyCellTable}>
          {data.map((item) => (
            <p>{item.product}</p>
          ))}
        </span>
        <span className={styles.bodyCellTable}>
          {data.map((item) => (
            <p>{item.amount}</p>
          ))}
        </span>
      </div>
    </div>
  );
};

export default TopProducts;
