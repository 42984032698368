import { Button, Form, Input, Modal } from "antd";
import styles from "../styles/DriversPauseUI.module.css";
import Close from "../../../components/Close";
const DeniedPause = (props) => {
  const { openModal, handleCloseModal,onFinishDeniedPause } = props;
  return (
    <Modal
      visible={openModal}
      footer={null}
      onCancel={handleCloseModal}
      closable={false}
      destroyOnClose
    >
      <div className={styles.headerModal}>
        <span className={styles.titleModal}>MOTIVO DEL RECHAZO</span>
        <Close action={handleCloseModal} />
      </div>
      <Form layout="vertical" onFinish={onFinishDeniedPause}>
        <Form.Item
          name="comment"
          label={
            <span className={styles.labelFormItem}>MOTIVO DEL RECHAZO</span>
          }
          rules={[{ required: true, message: "Ingresar comentario" }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 6 }}>
          <Button
            type="primary"
            size="middle"
            htmlType="submit"
            className={styles.submit}
          >
            ENVIAR
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DeniedPause;
