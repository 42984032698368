import React, { useCallback, useEffect, useState } from "react";
import DriversPauseHistoryUI from "./components/DriversPauseHistoryUI";
import { useDispatch, useSelector } from "react-redux";
import {
  getPausesHistoryThunk,
  selectPausesHistory,
} from "../../redux/slices/vehicles";
import { selectUser } from "../../redux/slices/user";
import { message } from "antd";
import moment from "moment";
import XLSX from 'xlsx'
import { saveAs } from "file-saver";

const Main = () => {
  const pausesHistory = useSelector(selectPausesHistory);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [loading, handleLoading] = useState(true);
  const [dates, handleDates] = useState([
    moment().startOf("date"),
    moment().endOf("date"),
  ]);
  const initialFetch = useCallback(async () => {
    handleLoading(true);
    const data = {
      userId: user.id,
      start_date: dates[0].format("YYYY-MM-DD 00:00:00"),
      end_date: dates[1].format("YYYY-MM-DD 23:59:59"),
    };
    const response = await dispatch(getPausesHistoryThunk(data));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
  }, [dates, dispatch, user.id]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);
  const onChangeDates = (values) => {
    handleDates(values)
  }
  const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };
  const exportToCSV = () => {
    const wb = XLSX.utils.book_new()
    const title = 'Historial de Pausas'

    wb.Props = {
      Title:title,
      Author:'Buffalo Wings',
      CreatedDate:new Date(),
    }
    wb.SheetNames.push('Pausas')
    const ws_data = [
      [
        "Fecha y Hora","Nombre del Driver","Motivo","Motivo del rechazo","Estado"
      ]
    ]
    pausesHistory.forEach((item)=>{
      const row = [
        moment(item.requestedAt).format("DD/MM/YYYY HH:mm A"),item.driverName,item.reasonName,item.comment_response,item.stateName
      ]
      ws_data.push(row)
    })
    const ws = XLSX.utils.aoa_to_sheet(ws_data)
    wb.Sheets["Pausas"] = ws
    const wbout = XLSX.write(wb,{bookType:'xlsx',type:'binary'})
    saveAs(new Blob([s2ab(wbout)],{type:'application/octet-stream'}),`${title}.xlsx`)

  }
  return (
    <DriversPauseHistoryUI
      loading={loading}
      pausesHistory={pausesHistory}
      dates={dates}
      onChangeDates={onChangeDates}
      exportToCSV={exportToCSV}
    />
  );
};

export default Main;
