import React from 'react'
import HeaderList from '../../components/HeaderList'
import ParamsUi from './components/ParamsUi'

const Main = () => {
  return (
    <div>
        <HeaderList title={'Parámetros'} back={'/ajustes'} />
        <ParamsUi />
    </div>
  )
}

export default Main