import React, { useEffect, useState } from "react";
import ZoneUi from "./components/ZoneUi";
import { Modal, message } from "antd";
import { activeZone, getAllLevels, getAllZones, removeMonitorZone, selectZones } from "../../redux/slices/zones";
import { useDispatch, useSelector } from "react-redux";
import { getAllBranches, getAllDepartments, selectBranches } from "../../redux/slices/branches";
import { setLoaded } from "../../redux/slices/clients";

const Main = () => {
  const [editorVisible, handleEditorVisible] = useState(false);
  const [zone, handleZone] = useState(null);
  const [departments, handleDepartments] = useState([]);
  const [levels, handleLevels] = useState([]);
  const zones = useSelector(selectZones);
  const branches = useSelector(selectBranches);

  const [loading, handleLoading] = useState(true);

  const dispatch = useDispatch();
  const removeZone = async (idZone) => {
    const response = await dispatch(removeMonitorZone(idZone));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      message.success("Zona eliminada correctamente");
    }
  };
  const confirmDelete = (idZone) => {
    Modal.confirm({
      title: "¿Estás segúro de eliminar esta cobertura?",
      content: "Esta información no se podrá recuperar.",
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        await removeZone(idZone);
      },
      onCancel() {},
    });
  };
  const handleActive = async (idZone, val) => {
    const response = await dispatch(activeZone(idZone, val));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  };

  const openEditor = (zoneToEdit) => {
    handleZone(zoneToEdit);
    handleEditorVisible(true);
  };
  const newZone = () => {
    handleZone(null);
    handleEditorVisible(true);
  };
  const closeEditor = () => {
    handleZone(null);
    handleEditorVisible(false);
  };

  useEffect(() => {
    const initialFetch = async () => {
      const [response, res_departments, res_levels] = await Promise.all([
        dispatch(getAllZones()),
        dispatch(getAllDepartments()),
        dispatch(getAllLevels()),
        dispatch(getAllBranches())

      ]);
      if (response.status !== 'success') {
        message.error('¡Hubo un problema!');
      } else {
        handleDepartments(res_departments.departments);
        handleLevels(res_levels.levels);
      }
      handleLoading(false);
      dispatch(setLoaded(false));
    };
    initialFetch();
  }, [dispatch]);
  return (
    <div>
      <ZoneUi
        newZone={newZone}
        loading={loading}
        editorVisible={editorVisible}
        zones={zones}
        openEditor={openEditor}
        departments={departments}
        levels={levels}
        zone={zone}
        confirmDelete={confirmDelete}
        handleActive={handleActive}
        handleZone={handleZone}
        closeEditor={closeEditor}
        branches={branches}
      />
    </div>
  );
};

export default Main;
