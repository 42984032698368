import { ReactComponent as User } from "../../../assets/icons/user.svg";
import { ReactComponent as RecentOrder } from "../../../assets/icons/recent-order.svg";
import { ReactComponent as Branch } from "../../../assets/icons/branch.svg";
import { ReactComponent as Telephone } from "../../../assets/icons/telephone.svg";
import { ReactComponent as Clock } from "../../../assets/icons/clock.svg";
import { ReactComponent as Driver } from "../../../assets/icons/driver-outline.svg";
import { ReactComponent as MoreDetail } from "../../../assets/icons/more-detail.svg";
import { ReactComponent as Platform } from "../../../assets/icons/platform.svg";

import styles from "../styles/MonitorUI.module.css";
import moment from "moment";
import { Spin } from "antd";

const OrderCard = ({item,openDetails,loadingOrder,getIcon}) => {
    const getBackgroundColor = (type) => {
        if (type === 7) {
          return "#74C5D7";
        }
        if (type === 8) {
          return "#FF671D";
        }
        if (type === 9) {
          return "#FF671D";
        }
        if (type === 10) {
          return "#4CAF50";
        }
        if (type === 11) {
          return "#FF671D";
        }
        return "#F5F5F5";
      };
      const formatTimeInitOrder = (time) => {
        let value = moment.duration(moment().diff(moment(time)));
        const minutes = value.minutes()
        const seconds = value.seconds()
        const hours = value.hours()
        let formattedHours = hours < 10 ? "0" + hours : hours;
        let formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
        let formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      };
    
      const formatTimeStateOrder = (time) => {
        let value = moment.duration(moment().diff(moment(time)));
        const minutes = value.minutes()
        const seconds = value.seconds()
        let formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
        let formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
    
        return `${formattedMinutes}:${formattedSeconds}`;
      };
      const getCurrentStateTime = (item) => {
        const statusTimes = [];
        for (const key in item) {
          if (key.includes("time_status")) {
            statusTimes.push(item[key]);
          }
        }
        let currentTime = "";
        statusTimes.forEach((time) => {
          if (time !== null) {
            currentTime = time;
          }
        });
        if (currentTime) {
          return formatTimeStateOrder(currentTime);
        }
        return "";
      };
   // console.log(item)
  return (
    <div className={styles.order}>
                      <div className={styles.header}>
                        {item.status === 6 && <RecentOrder />}

                        <p className={styles.typeOrder}>
                          {item.orderType === 2 ? "DELIVERY" : "TAKE OUT"}
                        </p>
                        <span className={styles.ticketCreated}>
                          <p className={styles.ticket}>TICKET: {item.ticket}</p>
                          <p className={styles.created}>
                            {formatTimeInitOrder(item.created_time)}
                          </p>
                        </span>
                      </div>
                      <span className={styles.orderDetail}>
                        <User />
                        <span className={styles.orderItem}>
                          <p className={styles.orderItemTitle}>CLIENTE: </p>
                          <p className={styles.orderItemValue}>
                            {item.id_user.fullname}
                          </p>
                        </span>
                      </span>

                      <span className={styles.orderDetail}>
                        <Branch />
                        <span className={styles.orderItem}>
                          <p className={styles.orderItemTitle}>SUCURSAL: </p>
                          <p className={styles.orderItemValue}>
                            {`${item?.branch?.zone ?? 'No Asignada'}`}
                          </p>
                        </span>
                      </span>
                      <div className={styles.telephoneClock}>
                        <span className={styles.orderDetail}>
                          <Telephone />
                          <span className={styles.orderItem}>
                            <p className={styles.orderItemTitle}>TELÉFONO: </p>
                            <p className={styles.orderItemValue}>
                              {item.id_user.mobile}
                            </p>
                          </span>
                        </span>
                        <span className={styles.orderDetail}>
                          <Clock />
                          <span className={styles.orderItem}>
                            <p
                              className={styles.orderItemTitle}
                              style={{ fontWeight: 600 }}
                            >
                              INGRESO:{" "}
                            </p>
                            <p className={styles.orderItemValue}>
                              {moment(item.created_time).format("hh:mm a")}
                            </p>
                          </span>
                        </span>
                        
                      </div>
                      <span className={styles.orderDetail}>
                          <Platform />
                          <span className={styles.orderItem}>
                            <p
                              className={styles.orderItemTitle}
                              style={{ fontWeight: 600 }}
                            >
                              ORIGEN:{" "}
                            </p>
                            <p className={styles.orderItemValue}>
                              {item.platform}
                            </p>
                          </span>
                        </span>
                      {item.orderType === 2 && item.status > 7 && (
                        <span className={styles.orderDetail}>
                          <Driver />
                          <span className={styles.orderItem}>
                            <p
                              className={styles.orderItemTitle}
                              style={{ fontWeight: 600 }}
                            >
                              DRIVER:{" "}
                            </p>
                            {item.driver ? (
                              <p className={styles.orderItemValue}>
                                  { `${item.driver?.name} ${item.driver?.lastname}`  }
                              </p>
                            ) :
                            (
                              <p className={styles.orderItemValue}>
                                  { item.peyaOrder ? ' Pedidos Ya Courier' : 'No Asignado'  }
                              </p>
                            )
                            }
                          </span>
                        </span>
                      )}

                      <div className={styles.moreDetail}>
                        <span className={styles.labelItemBox}>
                          <p className={styles.label}> MONTO: </p>
                          <span
                            className={[styles.itemBox, styles.total].join(" ")}
                          >
                            $ {item.total}
                          </span>
                        </span>
                        <span className={styles.labelItemBox}>
                          <p className={styles.label}> ESTADO: </p>
                          <span
                            className={styles.itemBox}
                            style={{
                              backgroundColor: getBackgroundColor(item.status),
                              flexDirection: "column",
                            }}
                          >
                            {getIcon(item.status,item.orderType, "card")}
                            {getCurrentStateTime(item)}
                          </span>
                        </span>
                        <span className={styles.labelItemBox}>
                          <p className={styles.label}> DETALLE: </p>
                          <span
                            className={[styles.itemBox, styles.eye].join(" ")}
                          >
                            {loadingOrder === item.id ? (
                              <Spin size="small" />
                            ) : (
                              <MoreDetail
                                onClick={() => openDetails(item.id)}
                              />
                            )}
                          </span>
                        </span>
                      </div>

                      {item.orderType === 2 && (
                        <span className={styles.orderDetail}>
                          <span className={styles.orderItem}>
                            <p
                              className={styles.orderItemTitle}
                              style={{ fontWeight: 600 }}
                            >
                              DIRECCIÓN DE ENTREGA:{" "}
                            </p>
                            <p className={styles.orderItemValue}>
                              {item.address_info?.address}
                            </p>
                            <p>{
                                item.address_info?.latitude != null &&
                                <a href={`https://maps.google.com/?z=19&q=${item.address_info?.latitude},${item.address_info?.longitude}`} target="_blank" rel="noreferrer">ver mapa</a>
                            }</p>
                          </span>
                        </span>
                      )}
                    </div>
  )
}

export default OrderCard