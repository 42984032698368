import getInstance from "./Request";

export const getLogs = async (userId) => {
    const request = await getInstance();
    let data = await request
      .post("/api/lista-logs", {id:userId} )
      .catch((error) => {
        return {
          error,
        };
      });
    return data;
  };