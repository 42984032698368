import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Modal } from "antd";
import {
  getAllSlides,
  selectSlides,
  deleteSlide,
  activeBanner,
  getAllModals,
  selectModals,
} from "../../redux/slices/frontpage";
import FrontpageUI from "./components/FrontpageUI";
import { getAllProducts, selectCategories, selectProducts } from "../../redux/slices/products";
import { selectUser } from "../../redux/slices/user";

const Frontpage = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [banner, handleBanner] = useState(null);
  const [isBannerOrPopUp,setIsBannerOrPopUp] = useState('banner')
  const slides = useSelector(selectSlides);
  const modals = useSelector(selectModals);
  const dispatch = useDispatch();
  const user = useSelector(selectUser)
  const products = useSelector(selectProducts)
  const categories = useSelector(selectCategories)
 /*  console.log(products)
  console.log(categories) */
  console.log(modals)
  const initialFetch = useCallback(async () => {
    const response = await dispatch(getAllSlides());
    const responseModals = await dispatch(getAllModals())
    const responseAllProducts = await dispatch(getAllProducts(user.branch))

    
    if(responseAllProducts.status !== 'success'){
      message.error('¡Hubo un problema!')
    }
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
  }, []);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  const editBanner = (newTopping) => {
    handleBanner(newTopping);
    setIsBannerOrPopUp(isBannerOrPopUp)
    handleEditorVisible(true);
  };

  const closeEditor = () => {
    setIsBannerOrPopUp(isBannerOrPopUp)
    handleEditorVisible(false);
    handleBanner(null);
  };

  const newBanner = (type) => {
    setIsBannerOrPopUp(type)
    handleBanner(null);
    handleEditorVisible(true);
  };

  const handleActive = async (idBanner, index, val) => {
    const response = await dispatch(activeBanner(idBanner, index, val));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  };
  const changeTable = (type) => {
    setIsBannerOrPopUp(type)
  }
  const confirmDelete = (idSlide) => {
    Modal.confirm({
      title: "¿Estás segúro de eliminar este banner?",
      content: "Esta información no se podrá recuperar.",
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        await removeBanner(idSlide);
      },
      onCancel() {},
    });
  };

  const removeBanner = async (idSlide) => {
    const response = await dispatch(deleteSlide(idSlide));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      message.success("Banner eliminado correctamente");
    }
  };
  console.log(slides)
  return (
    <FrontpageUI
      loading={loading}
      editorVisible={editorVisible}
      editBanner={editBanner}
      closeEditor={closeEditor}
      newBanner={newBanner}
      banner={banner}
      banners={slides}
      handleBanner={handleBanner}
      confirmDelete={confirmDelete}
      handleActive={handleActive}
      isBannerOrPopUp={isBannerOrPopUp}
      products={products}
      categories={categories}
      changeTable={changeTable}
      modals={modals}
    />
  );
};

export default Frontpage;
