import React, { useCallback, useEffect, useState } from "react";
import DriverUI from "./components/DriverUI";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/user";
import { getAllBranches, selectBranches } from "../../redux/slices/branches";
import { Form, Modal, message } from "antd";
import { useParams } from "react-router-dom";
import {
  availableDriverThunk,
  createDriverThunk,
  getDriverInfoThunk,
  selectDriver,
  updateDriverThunk,
} from "../../redux/slices/vehicles";

const Main = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const branches = useSelector(selectBranches);
  const driverInfo = useSelector(selectDriver)
  const [image, handleImage] = useState(null);
  const [loading, handleLoading] = useState(true);
  const [enabledLoading, handleEnableLoading] = useState(false);
  const [disabledLoading, handleDisableLoading] = useState(false);
  const [sending, handleSending] = useState(false);
  const [showMap, handleShowMap] = useState(false);
  const [showDriverSchedules,handleShowDriverSchedules] = useState(false)
  const { id } = useParams();
  const [form] = Form.useForm();
  const initialFetch = useCallback(async () => {
    const promises = [dispatch(getAllBranches(user.id))]
    if(id){
      promises.push(dispatch(getDriverInfoThunk(id)))
    }
    const response = await Promise.all(promises)
   if (response[0].status !== 'success') {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
  }, [dispatch, user]);
  useEffect(() => {
    initialFetch();
  }, [initialFetch]);
  useEffect(() => {
    if(id && driverInfo?.id){
      form.setFieldsValue({...driverInfo,driverId:driverInfo.branch_id,nationalId:driverInfo.national_id})
      handleImage(driverInfo?.photo)
    }
  }, [driverInfo])
  
  const onFinish = async (values) => {
    console.log(values);
    try {
      handleSending(true);
      values.photo = image;
      if (!id) {
        const response = await dispatch(createDriverThunk(values));
        if (response.status === "success") {
          message.success("Driver Guardado con éxito");
        } else {
          message.error("¡Hubo un problema! Inténtalo de nuevo");
        }
      } else {
        values.driverId = id;
        const response = await dispatch(updateDriverThunk(values));
        if (response.status === "success") {
          message.success("Driver actualizado con éxito");
        } else {
          message.error("¡Hubo un problema! Inténtalo de nuevo");
        }
      }
    } catch (error) {
      message.error("¡Hubo un problema! Inténtalo de nuevo");
    } finally {
      handleSending(false);
    }
  };

  

  const confirmEnabledDriver = async (driverId) => {
    Modal.confirm({
      title: "¿Deseas habilitar este driver?",
      content: "",
      okText: "Habilitar",
      cancelText: "Cancelar",
      async onOk() {
        await enabledDriver(driverId);
      },
      onCancel() {},
    });
  };
  const enabledDriver = async (driverId) => {
    try {
      handleEnableLoading(true);
      const response = await dispatch(
        availableDriverThunk({ driverId: driverId, available: true })
      );
      if (response.status === "success") {
        Modal.success({
          title: "Driver habilitado",
          content: "Se ha activado el driver con éxito",
        });
      } else {
        Modal.error({
          title: "Hubo un error",
          content: "Inténtalo de nuevo",
        });
      }
    } catch (error) {
      Modal.error({
        title: "Hubo un error",
        content: "Inténtalo de nuevo",
      });
    } finally {
      handleEnableLoading(false);
    }
  };

  const confirmDisabledDriver = (driverId) => {
    Modal.confirm({
      title: "¿Deseas deshabilitar este driver?",
      okText: "Deshabilitar",
      cancelText: "Cancelar",
      async onOk() {
        disabledDriver(driverId);
      },
      onCancel() {},
    });
  };
  const disabledDriver = async (driverId) => {
    try {
      handleDisableLoading(true);
      const response = await dispatch(
        availableDriverThunk({ driverId: driverId, available: false })
      );
      if(response.status === 'success'){
        Modal.success({
          title: "Driver deshabilitado",
          content: "Se ha deshabilitado el driver con éxito",
        });
      }else{
        Modal.error({
          title: "Hubo un error",
          content: "Intentado de nuevo",
        });
      }
     
    } catch (error) {
      Modal.error({
        title: "Hubo un error",
        content: "Intentado de nuevo",
      });
    } finally {
      handleDisableLoading(false);
    }
  };

  const openDriverSchedules = () => {
    handleShowDriverSchedules(true)
  }
  const closeDriverSchedules = () => {
    handleShowDriverSchedules(false)
  }
  return (
    <div>
      <DriverUI
        handleImage={handleImage}
        image={image}
        branches={branches}
        loading={loading}
        handleLoading={handleLoading}
        sending={sending}
        handleShowMap={handleShowMap}
        showMap={showMap}
        onFinish={onFinish}
        form={form}
        confirmEnabledDriver={confirmEnabledDriver}
        confirmDisabledDriver={confirmDisabledDriver}
        enabledLoading={enabledLoading}
        disabledLoading={disabledLoading}
        driverId={id}
        driver={driverInfo}
        openDriverSchedules={openDriverSchedules}
        closeDriverSchedules={closeDriverSchedules}
        showDriverSchedules={showDriverSchedules}
      />
    </div>
  );
};

export default Main;
