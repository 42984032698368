import { Button, Table, Spin, Modal, Tag } from "antd";
import Header from "../../../components/HeaderList";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";
import { ReactComponent as Password } from "../../../assets/icons/password.svg";

import { getColumnSearchProps } from "../../../utils/tables";
import Editor from "./Editor";
import styles from "../styles/VehiclesUI.module.css";
import { PasswordEditor } from "./PasswordEditor";
import { Link } from "react-router-dom";

const VehiclesUI = (props) => {
  const {
    loading,
    vehicles,
    vehicle,
    branches,
    userList,
    openEditor,
    openEditorPassword,
    editorVisible,
    editorPasswordVisible,
    closeEditor,
    closeEditorPassword,
    newVehicle,
    confirmDelete,
    handleVehicle,
  } = props;
  const getStatus = (status) => {
    if (status === 1) {
      return "Activo";
    }
    return "Inactivo";
  };

  const getLocationName = (location) => {
    let name = "-";
    const index = branches.findIndex((el) => el.id === location);
    if (index > -1) {
      name = branches[index].zone;
    }
    return name;
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
    },
    {
      title: "Usuario",
      dataIndex: "name",
      align: "center",
      ...getColumnSearchProps("user"),
    },
    {
      title: "Placa",
      dataIndex: "plate",
      align: "center",
      ...getColumnSearchProps("plate"),
    },
    {
      title: "Estado",
      dataIndex: "available",
      align: "center",
      render: (val) =>
        val ? (
          <Tag color="success"> {getStatus(val)} </Tag>
        ) : (
          <Tag color="error">{getStatus(val)}</Tag>
        ),
    },
    {
      title: "Tienda",
      dataIndex: "branch_id",
      align: "center",
      render: (val) => getLocationName(val),
    },
    {
      title: "Acciones",
      align: "center",
      dataIndex: "id",
      render: (id, record) => (
        <div className={styles.actions}>
          <Link to={`driver/edit/${id}`}>
            <div className={styles.edit}>
              <Edit />
            </div>
          </Link>
          <div className={styles.divider} />
          <div
            className={styles.password}
            onClick={() => openEditorPassword(record)}
          >
            <Password />
          </div>
          <div className={styles.divider} />

          <div className={styles.delete} onClick={() => confirmDelete(id)}>
            <Trash />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.view}>
      <Header
        back="/ajustes"
        title="Listado de drivers"
        actions={
          <div className={styles.buttons}>
            <Link to={"pausas"}>
              <Button type="primary" size="middle" >
                DRIVERS EN PAUSA
              </Button>
            </Link>
            <Link to={`driver/new`}>
              <Button type="primary" size="middle" >
                AGREGAR DRIVER
              </Button>
            </Link>
          </div>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table columns={columns} dataSource={vehicles} rowKey="id" />
        </div>
      )}
      <Modal
        destroyOnClose
        closable={false}
        visible={editorPasswordVisible}
        footer={false}
        onCancel={closeEditorPassword}
        width={400}
      >
        <PasswordEditor close={closeEditorPassword} vehicle={vehicle} />
      </Modal>
      <Modal
        destroyOnClose
        closable={false}
        visible={editorVisible}
        footer={false}
        onCancel={closeEditor}
        width={600}
      >
        <Editor
          close={closeEditor}
          vehicle={vehicle}
          branches={branches}
          userList={userList}
          handleVehicle={handleVehicle}
        />
      </Modal>
    </div>
  );
};

export default VehiclesUI;
