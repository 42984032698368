import React, { useEffect, useState } from "react";
import styles from "../styles/Editor.module.css";
import Close from "../../../components/Close";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Select,
  Switch,
} from "antd";
import { ReactComponent as SaveIcon } from "../../../assets/icons/save.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/trash.svg";
import cx from "classnames";
import moment from "moment";
const initialWeek = [
  {
    id: 7,
    letter: "DO",
    nameValue: "Domingo",
    nameLabel: "Domingo",
    active: false,
  },
  {
    id: 1,
    letter: "LU",
    nameValue: "Lunes",
    nameLabel: "Lunes",
    active: false,
  },
  {
    id: 2,
    letter: "MA",
    nameValue: "Martes",
    nameLabel: "Martes",
    active: false,
  },
  {
    id: 3,
    letter: "MI",
    nameValue: "Miercoles",
    nameLabel: "Miércoles",
    active: false,
  },
  {
    id: 4,
    letter: "JU",
    nameValue: "Jueves",
    nameLabel: "Jueves",
    active: false,
  },
  {
    id: 5,
    letter: "VI",
    nameValue: "Viernes",
    nameLabel: "Viernes",
    active: false,
  },
  {
    id: 6,
    letter: "SA",
    nameValue: "Sabado",
    nameLabel: "Sábado",
    active: false,
    selected: false,
  },
];
const Editor = ({
  closeEditor,
  branches,
  sending,
  onFinish,
  selectedBackup,
}) => {
  const [week, handleWeek] = useState(initialWeek);
  const [showScheduledTime, setShowScheduledTime] = useState(false);
  const [scheduledEnd, setScheduledEnd] = useState(true);
  const [savedSchedules, handleSavedSchedules] = useState({});
  const [repeatAllDays, handleRepeatAllDays] = useState(false);
  const getCurrentDay = () => {
    const current = moment().format("dd").toLocaleUpperCase();
    const day = week.filter((item) => current.includes(item.letter))[0];
    return { ...day, letter: "X", selected: true };
  };
  const [activeDay, handleActiveDay] = useState(getCurrentDay());
  const [formBackup] = Form.useForm();
  const [formScheduled] = Form.useForm();
  const handleSelectDay = (day) => {
    formScheduled.resetFields();
    if (savedSchedules[day.nameValue]) {
      formScheduled.setFieldsValue({
        from: moment(savedSchedules[day.nameValue].from),
        to: moment(savedSchedules[day.nameValue].to),
      });
    }
    handleActiveDay({ ...day, selected: true });
  };
  const setRepeatAllDays = (e) => {
    const value = e.target.checked;
    handleRepeatAllDays(value);
    formScheduled.resetFields();
    const newWeek = week.map((item) => {
      return { ...item, active: false };
    });
    handleWeek(newWeek);

    handleSavedSchedules({});
    handleActiveDay({ ...getCurrentDay() });
  };
  const getDay = (name) => {
    return week.filter((item) => item.nameValue === name)[0];
  };
  const saveScheduled = (values) => {
    const newWeek = week.map((item) => {
      if (item.id === activeDay.id) {
        return { ...item, active: true };
      }
      return item;
    });
    handleWeek(newWeek);
    if (repeatAllDays) {
      const sunday = getDay("Domingo");
      const monday = getDay("Lunes");
      const tuesday = getDay("Martes");
      const wednesday = getDay("Miercoles");
      const thursday = getDay("Jueves");
      const friday = getDay("Viernes");
      const saturday = getDay("Sabado");
      const dates = {
        to: values.to.format("YYYY/MM/DD HH:mm:ss"),
        from: values.from.format("YYYY/MM/DD HH:mm:ss"),
        active: true,
      };
      const newWeek = week.map((item) => {
        return { ...item, active: true };
      });
      handleWeek(newWeek);
      handleSavedSchedules({
        [sunday.nameValue]: {
          ...sunday,
          ...dates,
        },
        [monday.nameValue]: {
          ...monday,
          ...dates,
        },
        [tuesday.nameValue]: {
          ...tuesday,
          ...dates,
        },
        [wednesday.nameValue]: {
          ...wednesday,
          ...dates,
        },
        [thursday.nameValue]: {
          ...thursday,
          ...dates,
        },
        [friday.nameValue]: {
          ...friday,
          ...dates,
        },
        [saturday.nameValue]: {
          ...saturday,
          ...dates,
        },
      });
      message.success("Horarios guardados exitosamente!");
    } else {
      handleSavedSchedules({
        ...savedSchedules,
        [activeDay.nameValue]: {
          ...activeDay,
          active: true,
          to: values.to.format("YYYY/MM/DD HH:mm:ss"),
          from: values.from.format("YYYY/MM/DD HH:mm:ss"),
        },
      });
      message.success("Horario guardado exitosamente!");
    }
  };

  const submitScheduled = () => {
    formScheduled.submit();
  };

  const deleteSchedule = () => {
    if (savedSchedules[activeDay.nameValue]) {
      const newWeek = week.map((item) => {
        if (item.id === activeDay.id) {
          return { ...item, active: false };
        }
        return item;
      });
      if (repeatAllDays) {
        handleRepeatAllDays(false);
      }
      formScheduled.resetFields();
      handleWeek(newWeek);
      delete savedSchedules[activeDay.nameValue];
      message.success("Horario eliminado exitosamente!");
    }
  };
  
  const saveBranchBackup = async (values) => {
    if (showScheduledTime && Object.values(savedSchedules).length === 0) {
      message.error("Guarda almenos un horario!");
      return;
    }
    const data = {
      schedules: Object.values(savedSchedules),
      ...values,
      dateEnd: !scheduledEnd
        ? moment(values.dateEnd).startOf("day").format("YYYY/MM/DD HH:mm:ss")
        : null
    };

    if(selectedBackup?.id){
       data.branchId = selectedBackup.id 
    }
   
   
    const res = await onFinish(data);
    if (res.status === "success") {
      formBackup.resetFields();
    }

  };
  const onChangeScheduledEnd = (e) => {
    const value = e.target.value;
    if (value) {
      formBackup.setFieldsValue({ dateEnd: moment().endOf("year") });
    } else {
      formBackup.setFieldsValue({ dateEnd: moment().add(1, "day") });
    }
    setScheduledEnd(value);
  };
  const setValuesToEdit = () => {
    formBackup.setFieldsValue({
      dateEnd:
        selectedBackup.backup_time !== null
          ? moment(selectedBackup.backup_time, "DD-MM-YYYY hh:mm A")
          : moment().endOf("year"),
    });
    if (selectedBackup?.backup_time) {
      setScheduledEnd(false);
    }
    if (selectedBackup?.backup_schedule?.length) {
      if (selectedBackup?.backup_schedule?.length === 7) {
        handleRepeatAllDays(true);
      }

      let mergeDays = week.map((local) => {
        const savedDay = selectedBackup?.backup_schedule.filter(
          (saved) => saved.nameValue === local.nameValue
        );
        if (savedDay.length) {
          handleActiveDay(savedDay[0])
          formScheduled.setFieldsValue({
            from: moment(savedDay[0].from),
            to: moment(savedDay[0].to),
          });
          return { ...savedDay[0], selected: false,letter: savedDay[0].letter === 'X' ? savedDay[0].nameLabel.substring(0,2).toLocaleUpperCase() : savedDay[0].letter };
        }
        return local;
      });
      handleWeek(mergeDays);
      const schedules = selectedBackup?.backup_schedule.reduce((acc,item)=> {
        return {
          ...acc,
          [item.nameValue]: {
            ...item,
          },
        };
      },{})
      handleSavedSchedules(schedules)
      setShowScheduledTime(true);
    }
  }
  useEffect(() => {
    if (selectedBackup?.id) {
      formBackup.setFieldsValue({
        branchAffected: selectedBackup.id,
        branchBackup: selectedBackup.backup,
        dateEnd:
          selectedBackup.backup_time !== null
            ? moment(selectedBackup.backup_time, "DD-MM-YYYY hh:mm A")
            : moment().endOf("year"),
        backupReason: selectedBackup.backup_reason,
      });
      setValuesToEdit()
     
    }
  }, []);
 
  const disableDate = (current) => {
    return current && current < moment().endOf("day");
  };
  const onChangeScheduledTime = (value) => {
    setShowScheduledTime(value)
    if(value && selectedBackup?.id){
      setValuesToEdit()
    }
    if(!value){
      formBackup.setFieldsValue({dateEnd: moment().endOf("year") })
      handleActiveDay(getCurrentDay())
      formScheduled.resetFields()
      handleWeek(initialWeek)
      setScheduledEnd(true)
      handleSavedSchedules({})
    }
  }
  return (
    <div className={styles.editor}>
      <div className={styles.header}>
        <span className={styles.title}>
          {`${selectedBackup ? "EDITAR" : "NUEVA"} SUCURSAL BACKUP`}
        </span>
        <Close action={closeEditor} />
      </div>
      <Divider />
      <Form
        layout="vertical"
        requiredMark={false}
        initialValues={{ dateEnd: moment().endOf("year") }}
        onFinish={saveBranchBackup}
        form={formBackup}
      >
        <Form.Item
          name="branchAffected"
          label={
            <span className={styles["labelFormItem"]}>SUCURSAL ORIGEN</span>
          }
          rules={[
            { required: true, message: "Ingresar Domicilio Afectado" },
            (form) => ({
              validator(_, branchAffected) {
                const branchBackup = form.getFieldValue("branchBackup");
                if (branchBackup === branchAffected) {
                  return Promise.reject(
                    new Error(
                      "La sucursal de origin no puede ser igual a la sucursal de backup"
                    )
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select
            options={branches}
            placeholder="Seleccionar"
            className={styles["input"]}
            size="large"
            allowClear
            disabled={selectedBackup?.id}
          />
        </Form.Item>
        <Form.Item
          name="branchBackup"
          label={
            <span className={styles["labelFormItemBackup"]}>
              SUCURSAL BACKUP
            </span>
          }
          rules={[
            {
              required: true,
              message: "Ingresar Domicilio De Ayuda",
            },
            (form) => ({
              validator(_, branchBackup) {
                const branchAffected = form.getFieldValue("branchAffected");
                if (branchBackup === branchAffected) {
                  return Promise.reject(
                    new Error(
                      "La sucursal de backup no puede ser igual a la sucursal de origin"
                    )
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select
            options={branches}
            placeholder="Seleccionar"
            className={styles["input"]}
            size="large"
            allowClear
            disabled={selectedBackup?.id}
          />
        </Form.Item>

        <div className={styles["scheduledActive"]}>
          <span className={styles["labelFormItem"]}>HORARIO PROGRAMADO</span>
          <Switch
            onChange={onChangeScheduledTime}
            checked={showScheduledTime}
          />
        </div>
        {showScheduledTime && (
          <>
            <div className={styles["scheduleTime"]}>
              <div className={styles["headerScheduleTime"]}>
                <p className={styles["labelHeaderScheduleTime"]}>
                  REPETIR CADA
                </p>
                <span className={styles["actionHeaderScheduleTime"]}>
                  <Checkbox
                    onClick={setRepeatAllDays}
                    checked={repeatAllDays}
                  />{" "}
                  <p> Todos los días</p>
                </span>
              </div>
              <div className={styles["week"]}>
                {week.map((item) => (
                  <span
                    className={cx(
                      styles["day"],
                      item.id === activeDay.id &&
                        !item.active &&
                        styles["currentDay"],
                      item.active && styles["activeDay"]
                    )}
                    onClick={() => handleSelectDay(item)}
                  >
                    {item.id === getCurrentDay().id
                      ? getCurrentDay().letter
                      : item.letter}
                  </span>
                ))}
              </div>
              <div className={styles["selectedDayContainer"]}>
                <span className={styles["selectedDay"]}>
                  <p className={styles["labelHeaderScheduleTime"]}>
                    {repeatAllDays ? "Todos los días" : activeDay.nameLabel}
                  </p>
                  <span className={styles["divider"]} />
                </span>
              </div>
              <Form
                layout="inline"
                form={formScheduled}
                onFinish={saveScheduled}
              >
                <Form.Item
                  name="from"
                  label="Desde"
                  rules={[{ required: true, message: "Ingrese un horario" }]}
                >
                  <DatePicker.TimePicker
                    className={styles["input"]}
                    size="large"
                    format="HH:mm"
                  />
                </Form.Item>
                <Form.Item
                  name="to"
                  label="Hasta"
                  rules={[{ required: true, message: "Ingrese un horario" }]}
                >
                  <DatePicker.TimePicker
                    className={styles["input"]}
                    size="large"
                    format="HH:mm"
                  />
                </Form.Item>
                <Form.Item label=" ">
                  <Button
                    className={styles["saveDateTimeButton"]}
                    htmlType="button"
                    size="large"
                    onClick={submitScheduled}
                  >
                    <SaveIcon /> Guardar horario
                  </Button>
                </Form.Item>
                <Form.Item label=" ">
                  <DeleteIcon
                    onClick={deleteSchedule}
                    className={styles["deleteIcon"]}
                  />
                </Form.Item>
              </Form>
            </div>
            <div className={styles["containerDateEnd"]}>
              <Form.Item
                label={
                  <p className={styles["labelHeaderScheduleTime"]}>
                    FECHA QUE FINALIZA
                  </p>
                }
                rules={[{ required: true }]}
              >
                <Radio.Group
                  options={[
                    { value: true, label: "Nunca" },
                    { value: false, label: "El día" },
                  ]}
                  value={scheduledEnd}
                  onChange={onChangeScheduledEnd}
                  className={styles["selectedDate"]}
                />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Ingresa una fecha" }]}
                name="dateEnd"
                label=" "
              >
                <DatePicker
                  className={cx(styles["datePicker"], styles["input"])}
                  size="large"
                  format="DD-MM-YYYY"
                  disabled={scheduledEnd}
                  rules={[{ required: true }]}
                  disabledDate={disableDate}
                />
              </Form.Item>
            </div>
          </>
        )}
        <Form.Item
          label={
            <span className={styles["labelFormItem"]}>RAZÓN DEL CAMBIO</span>
          }
          name="backupReason"
          rules={[{ required: true, message: "Ingresar Razón del Cambio" }]}
        >
          <Input.TextArea rows={3} className={styles.input} />
        </Form.Item>
        <div className={styles["containerSubmitButton"]}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className={styles["submitButton"]}
            loading={sending}
          >
            GUARDAR
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Editor;
