import {  Modal, DatePicker, Table } from 'antd'
import React from 'react'
import styles from '../styles/ReportUI.module.css'
import Header from '../../../components/HeaderList'
import { ReactComponent as Calendar } from '../../../assets/icons/calendar.svg'
import { getColumnSearchProps } from '../../../utils/tables'
import { ReactComponent as Detail} from '../../../assets/icons/eye-box.svg'
import moment from 'moment'
const ReportUI = ({onChangeDates,dates,loading,logs}) => 
  {

    const openDetails = async (id, data) => {
      let jsonobj = JSON.parse(data);
      Modal.info({
        title: 'Detalle del Registro - ' + id,
        content: (
          <div>
            <pre dangerouslySetInnerHTML={{__html: syntaxHighlight(jsonobj)}} />
          </div>
        ),
        onOk() {},
      });
    };

    const syntaxHighlight = (json) => {
      if (typeof json != 'string') {
           json = JSON.stringify(json, undefined, 2);
      }
      json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
      return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
          var cls = 'number';
          if (/^"/.test(match)) {
              if (/:$/.test(match)) {
                  cls = 'key';
              } else {
                  cls = 'string';
              }
          } else if (/true|false/.test(match)) {
              cls = 'boolean';
          } else if (/null/.test(match)) {
              cls = 'null';
          }
          return '<span class="' + cls + '">' + match + '</span>';
      });
    };

  const columns = [
    {title:'#',dataIndex:'id',align:'center',render:(id,record,index)=> index+1},
    {title:'Canal',dataIndex:'channel',align:'center',...getColumnSearchProps('canal')},
    {title:'Acción',dataIndex:'action',align:'center',...getColumnSearchProps('accion')},
    {title:'Usuario',dataIndex:'user_full_name',align:'center',...getColumnSearchProps('usuario')},
    {title:'Fecha',dataIndex:'register_date',align:'center',render:(date)=> moment(date).format('DD/MM/YYYY HH:mm A')},
    {title:'Ver detalle',dataIndex:'id',render:(_, record, index) => (
      <button
        onClick={() => openDetails(record.id, record.details)}
        className={styles.eye}
      >
        <Detail />
      </button>
    ) }
  ]
  console.log(logs)
  return (
    <div>
        <Header title={'LOGS'} back={'/reportes'} />
        <div className={styles.filters}>
        <DatePicker.RangePicker size='large' suffixIcon={<Calendar />} allowClear format={'DD/MM/YYYY'} placeholder={['Fecha Inicio','Fecha Final']} value={dates} onChange={onChangeDates} />
        </div>
        <Table columns={columns} dataSource={logs} loading={loading} pagination={{pageSize:50,showSizeChanger:false}}/>
    </div>
  )
}

export default ReportUI