import { useRef, useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Upload,
  Button,
  TimePicker,
  message,
} from "antd";
import {
  GoogleMap,
  useJsApiLoader,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import cx from "classnames";
import moment from "moment";
import {
  editLocation,
  newLocation,
  newImage,
} from "../../../redux/slices/branches";
import { normFile, beforeUpload, dummyRequest } from "../../../utils/images";
import { ReactComponent as Gps } from "../../../assets/icons/gps.svg";
import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";
import { ReactComponent as Minus } from "../../../assets/icons/minus.svg";

import Close from "../../../components/Close";
import styles from "../styles/Editor.module.css";
import { GOOGLE_MAPS_API_KEY, GOOGLE_MAPS_LIBRARIES } from "../../../utils/constants";

const { TextArea } = Input;

const format = "HH:mm";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 13.701319108430834,
  lng: -89.22441469943618,
};

const Editor = ({ close, branch, handleBranch }) => {
  const searchbox = useRef(null);
  const [sending, handleSending] = useState(false);
  const [mapLoaded, handleMapLoaded] = useState(false);
  const [preview, handlePreview] = useState(null);
  const [days, setDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const [form] = Form.useForm();
  const map = useRef(null);
  const dispatch = useDispatch();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey:GOOGLE_MAPS_API_KEY,
    libraries:GOOGLE_MAPS_LIBRARIES
  });
  useEffect(() => {
    const initialFetch = async () => {
      try {
        if (branch && mapLoaded) {
          const newValues = {
            zone: branch.zone,
            address: branch.address,
            phone: branch.phone,
            venue: branch.venue,
          };
          if (branch.schedules ) {
            const monday = getHours("monday");
            newValues.monday = monday.day;
            newValues.monday2 = monday.day2;
            const tuesday = getHours("tuesday");
            newValues.tuesday = tuesday.day;
            newValues.tuesday2 = tuesday.day2;
            const wednesday = getHours("wednesday");
            newValues.wednesday = wednesday.day;
            newValues.wednesday2 = wednesday.day2;
            const thursday = getHours("thursday");
            newValues.thursday = thursday.day;
            newValues.thursday2 = thursday.day2;
            const friday = getHours("friday");
            newValues.friday = friday.day;
            newValues.friday2 = friday.day2;
            const saturday = getHours("saturday");
            newValues.saturday = saturday.day;
            newValues.saturday2 = saturday.day2;
            const sunday = getHours("sunday");
            newValues.sunday = sunday.day;
            newValues.sunday2 = sunday.day2;
            setDays({
              ...days,
              monday: monday.day2 ? true : false,
              tuesday: tuesday.day2 ? true : false,
              wednesday: wednesday.day2 ? true : false,
              thursday: thursday.day2 ? true : false,
              friday:friday.day2 ? true : false,
              saturday:saturday.day2 ? true : false,
              sunday:sunday.day2 ? true : false
            });
          }
          if (branch.image) {
            handlePreview(branch.image);
          }
          form.setFieldsValue(newValues);
          map.current.setCenter({
            lat: parseFloat(branch.latitude),
            lng: parseFloat(branch.longitude),
          });
        }
      } catch (e) {
        console.log(e);
      }
    };
    initialFetch();
  }, [branch, mapLoaded]);

  const getHours = (day) => {
    const newHours = JSON.parse(branch.schedules);
    const index = newHours.findIndex((el) => el.day === day);
    
    if (index > -1) {
      const schedule = newHours[index];
      if(schedule?.hours?.length){
        return {
          day: [
            moment(schedule.hours[0].open, "hh:mmA"),
            moment(schedule.hours[0].close, "hh:mmA"),
          ],
          day2:
            schedule.hours[1] === null
              ? undefined
              : [
                  moment(schedule.hours[1].open, "hh:mmA"),
                  moment(schedule.hours[1].close, "hh:mmA"),
                ],
        };
      }else{
        return {
          day:[moment(schedule.open, "hh:mmA"),moment(schedule.close, "hh:mmA"),]
            
           

        };
      }
     
    } else {
      return [moment(), moment()];
    }
  };

  const onPlacesChanged = () => {
    const location = searchbox.current.getPlaces()[0].geometry.location;
    map.current.setCenter({
      lat: location.lat(),
      lng: location.lng(),
    });
  };

  const renderMap = () => {
    const onLoadSearchbox = (ref) => {
      searchbox.current = ref;
      handleMapLoaded(true);
    };

    const onLoadMap = (ref) => {
      map.current = ref;
    };

    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        onLoad={onLoadMap}
        zoom={16}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
          fullscreenControl: false,
        }}
      >
        <StandaloneSearchBox
          onLoad={onLoadSearchbox}
          onPlacesChanged={onPlacesChanged}
        >
          <input
            type="text"
            placeholder="Buscar..."
            className={styles.searchbox}
          />
        </StandaloneSearchBox>
      </GoogleMap>
    );
  };

  const getHourFormat = (label, values) => {
    return {
      // day: label,
      open: values[0].format("HH:mm"),
      close: values[1].format("HH:mm"),
    };
  };

  const uploadImage = async (valueImage, id) => {
    if (preview !== branch?.image || !branch) {
      const infoImage = new FormData();
      infoImage.append("id", id);
      infoImage.append(
        "image",
        valueImage?.length ? valueImage[0]?.originFileObj : preview
      );
      const response = await dispatch(newImage(infoImage));
      console.log(response);
      if (response.status === "success") {
        message.success("Cambios realizados con éxito");
        return response;
      } else {
        message.error("¡Hubo un problema! Inténtalo de nuevo");
        return false;
      }
    } else {
      message.success("Cambios realizados con éxito");
      return false;
    }
  };

  const save = async () => {
    try {
      handleSending(true);
      const values = await form.validateFields();
      // if (preview && preview.length) {
      const newCenter = map.current.getCenter().toJSON();
      const newHours = [
        {
          day: "monday",
          hours: [
            getHourFormat("monday", values.monday),
            values.monday2 ? getHourFormat("monday2", values.monday2) : null,
          ],
        },
        {
          day: "tuesday",
          hours: [
            getHourFormat("tuesday", values.tuesday),
            values.tuesday2 ? getHourFormat("tuesday2", values.tuesday2) : null,
          ],
        },
        {
          day: "wednesday",
          hours: [
            getHourFormat("wednesday", values.wednesday),
            values.wednesday2
              ? getHourFormat("wednesday2", values.wednesday2)
              : null,
          ],
        },
        {
          day: "thursday",
          hours: [
            getHourFormat("thursday", values.thursday),
            values.thursday2
              ? getHourFormat("thursday2", values.thursday2)
              : null,
          ],
        },
        {
          day: "friday",
          hours: [
            getHourFormat("friday", values.friday),
            values.friday2 ? getHourFormat("friday2", values.friday2) : null,
          ],
        },
        {
          day: "saturday",
          hours: [
            getHourFormat("saturday", values.saturday),
            values.fridsaturday2ay2
              ? getHourFormat("saturday2", values.saturday)
              : null,
          ],
        },
        {
          day: "sunday",
          hours: [
            getHourFormat("sunday", values.sunday),
            values.sunday2 ? getHourFormat("sunday2", values.sunday2) : null,
          ],
        },
      ];

      const info = {
        zone: values.zone,
        address: values.address,
        phone: values.phone,
        latitude: newCenter.lat,
        longitude: newCenter.lng,
        schedules: newHours,
        status: 1,
        venue: values.venue,
      };
      if (branch) {
        info.id = branch.id;
      }
      if (branch) {
        const response = await dispatch(editLocation(info));
        if (response.status === "success") {
          message.success("Sucursal editada exitosamente!");

          //await uploadImage(values.image, branch.id);
        } else {
          message.error("¡Hubo un problema! Inténtalo de nuevo");
        }
      } else {
        const response = await dispatch(newLocation(info));

        if (response.status === "success") {
          /*   const response_image = await uploadImage(
              values.image,
              response.branch.id
            );
            handleBranch(response_image.branch);
             */
          message.success("Sucursal guardada exitosamente!");
        } else {
          message.error("¡Hubo un problema! Inténtalo de nuevo");
        }
      }
      /*   } else {
        message.error("Seleciona una imagen válida");
      } */

      handleSending(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    handleSending(false);
  }, []);

  return (
    <div className={styles.editor}>
      <div className={styles.header}>
        <span className={styles.title}>
          {branch ? "Editar sucursal" : "Nueva sucursal"}
        </span>
        <Close action={close} />
      </div>
      <Form
        name="branch-editor"
        autoComplete="off"
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={save}
      >
        <Row gutter={[40, 40]}>
          <Col span={15}>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={<span className={styles.label}>NOMBRE</span>}
                  name="zone"
                  className={styles.itemColumn}
                  rules={[{ required: true, message: "Ingresar nombre" }]}
                >
                  <Input size="large" className={styles.input} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={<span className={styles.label}>TELÉFONO</span>}
                  name="phone"
                  className={styles.itemColumn}
                  rules={[{ required: true, message: "Ingresar teléfono" }]}
                >
                  <Input size="large" className={styles.input} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={<span className={styles.label}>VENUE</span>}
                  name="venue"
                  className={styles.itemColumn}
                  rules={[{ required: true, message: "Ingresar venue" }]}
                >
                  <Input size="large" className={styles.input} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={<span className={styles.label}>DIRECCIÓN</span>}
                  name="address"
                  className={styles.itemColumn}
                  rules={[{ required: true, message: "Ingresar dirección" }]}
                >
                  <TextArea rows={3} className={styles.input} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <div className={styles.mapWrapper}>
                  {isLoaded && renderMap()}
                  {isLoaded && <Gps className={styles.gps} />}
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={9}>
            {/*  <Form.Item
              name="image"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              label={<span className={styles.label}>IMAGEN BASE</span>}
              className={styles.itemColumn}
              extra={
                <span className={styles.center}>
                  (Formatos jpg o png de 1000px ancho)
                </span>
              }
            >
              <Upload
                maxCount={1}
                showUploadList={false}
                customRequest={(e) => dummyRequest(e, handlePreview)}
                beforeUpload={beforeUpload}
                listType="picture"
              >
                <Button size="large" className={styles.uploadImage}>
                  Cambiar imagen
                </Button>
              </Upload>
            </Form.Item> 
            <div
              className={styles.preview}
              style={{ backgroundImage: `url(${preview})` }}
            />
            */}
            <div className={styles.hours}>
              <span className={cx(styles.label, styles.labelSection)}>
                HORARIOS
              </span>
              <div className={styles.hoursList}>
                <span className={styles.formItem}>
                  <Form.Item
                    label={
                      <span className={cx(styles.label, styles.labelHour)}>
                        LUNES
                      </span>
                    }
                    name="monday"
                    rules={[{ required: true, message: "Ingresar horario" }]}
                  >
                    <TimePicker.RangePicker
                      format={format}
                      className={styles.input}
                      placeholder={["Apertura", "Cierre"]}
                    />
                  </Form.Item>
                  {!days.monday ? (
                    <Plus
                      className={styles.plusMinusIcon}
                      onClick={() => setDays({ ...days, monday: true })}
                    />
                  ) : (
                    <Minus
                      className={styles.plusMinusIcon}
                      onClick={() => setDays({ ...days, monday: false })}
                    />
                  )}
                </span>
                {days.monday && (
                  <Form.Item
                    label={
                      <span className={cx(styles.label, styles.labelHour)}>
                       
                      </span>
                    }
                    name="monday2"
                    rules={[{ required: true, message: "Ingresar horario" }]}
                  >
                    <TimePicker.RangePicker
                      format={format}
                      className={cx(styles.input,styles.innerHour)}
                      placeholder={["Apertura", "Cierre"]}
                    />
                  </Form.Item>
                )}
                <span className={styles.formItem}>
                  <Form.Item
                    label={
                      <span className={cx(styles.label, styles.labelHour)}>
                        MARTES
                      </span>
                    }
                    name="tuesday"
                    rules={[{ required: true, message: "Ingresar horario" }]}
                  >
                    <TimePicker.RangePicker
                      format={format}
                      className={styles.input}
                      placeholder={["Apertura", "Cierre"]}
                    />
                  </Form.Item>
                  {!days.tuesday ? (
                    <Plus
                      className={styles.plusMinusIcon}
                      onClick={() => setDays({ ...days, tuesday: true })}
                    />
                  ) : (
                    <Minus
                      className={styles.plusMinusIcon}
                      onClick={() => setDays({ ...days, tuesday: false })}
                    />
                  )}
                </span>
                {days.tuesday && (
                  <Form.Item
                    label={
                      <span className={cx(styles.label, styles.labelHour)}>
                        
                      </span>
                    }
                    name="tuesday2"
                    rules={[{ required: true, message: "Ingresar horario" }]}
                  >
                    <TimePicker.RangePicker
                      format={format}
                      className={cx(styles.input,styles.innerHour)}
                      placeholder={["Apertura", "Cierre"]}
                    />
                  </Form.Item>
                )}
                <span className={styles.formItem}>
                  <Form.Item
                    label={
                      <span className={cx(styles.label, styles.labelHour)}>
                        MIÉRCOLES
                      </span>
                    }
                    name="wednesday"
                    rules={[{ required: true, message: "Ingresar horario" }]}
                  >
                    <TimePicker.RangePicker
                      format={format}
                      className={styles.input}
                      placeholder={["Apertura", "Cierre"]}
                    />
                  </Form.Item>
                  {!days.wednesday ? (
                    <Plus
                      className={styles.plusMinusIcon}
                      onClick={() => setDays({ ...days, wednesday: true })}
                    />
                  ) : (
                    <Minus
                      className={styles.plusMinusIcon}
                      onClick={() => setDays({ ...days, wednesday: false })}
                    />
                  )}
                </span>
                {days.wednesday && (
                  <Form.Item
                    label={
                      <span className={cx(styles.label, styles.labelHour)}>
                       
                      </span>
                    }
                    name="wednesday2"
                    rules={[{ required: true, message: "Ingresar horario" }]}
                  >
                    <TimePicker.RangePicker
                      format={format}
                      className={cx(styles.input,styles.innerHour)}
                      placeholder={["Apertura", "Cierre"]}
                    />
                  </Form.Item>
                )}
                <span className={styles.formItem}>
                  <Form.Item
                    label={
                      <span className={cx(styles.label, styles.labelHour)}>
                        JUEVES
                      </span>
                    }
                    name="thursday"
                    rules={[{ required: true, message: "Ingresar horario" }]}
                  >
                    <TimePicker.RangePicker
                      format={format}
                      className={styles.input}
                      placeholder={["Apertura", "Cierre"]}
                    />
                  </Form.Item>
                  {!days.thursday ? (
                    <Plus
                      className={styles.plusMinusIcon}
                      onClick={() => setDays({ ...days, thursday: true })}
                    />
                  ) : (
                    <Minus
                      className={styles.plusMinusIcon}
                      onClick={() => setDays({ ...days, thursday: false })}
                    />
                  )}
                </span>
                {days.thursday && (
                  <Form.Item
                    label={
                      <span className={cx(styles.label, styles.labelHour)}>
                        
                      </span>
                    }
                    name="thursday2"
                    rules={[{ required: true, message: "Ingresar horario" }]}
                  >
                    <TimePicker.RangePicker
                      format={format}
                      className={cx(styles.input,styles.innerHour)}
                      placeholder={["Apertura", "Cierre"]}
                    />
                  </Form.Item>
                )}
                <span className={styles.formItem}>
                  <Form.Item
                    label={
                      <span className={cx(styles.label, styles.labelHour)}>
                        VIERNES
                      </span>
                    }
                    name="friday"
                    rules={[{ required: true, message: "Ingresar horario" }]}
                  >
                    <TimePicker.RangePicker
                      format={format}
                      className={styles.input}
                      placeholder={["Apertura", "Cierre"]}
                    />
                  </Form.Item>
                  {!days.friday ? (
                    <Plus
                      className={styles.plusMinusIcon}
                      onClick={() => setDays({ ...days, friday: true })}
                    />
                  ) : (
                    <Minus
                      className={styles.plusMinusIcon}
                      onClick={() => setDays({ ...days, friday: false })}
                    />
                  )}
                </span>
                {days.friday && (
                  <Form.Item
                    label={
                      <span className={cx(styles.label, styles.labelHour)}>
                        
                      </span>
                    }
                    name="friday2"
                    rules={[{ required: true, message: "Ingresar horario" }]}
                  >
                    <TimePicker.RangePicker
                      format={format}
                      className={cx(styles.input,styles.innerHour)}
                      placeholder={["Apertura", "Cierre"]}
                    />
                  </Form.Item>
                )}
                <span className={styles.formItem}>
                  <Form.Item
                    label={
                      <span className={cx(styles.label, styles.labelHour)}>
                        SÁBADO
                      </span>
                    }
                    name="saturday"
                    rules={[{ required: true, message: "Ingresar horario" }]}
                  >
                    <TimePicker.RangePicker
                      format={format}
                      className={styles.input}
                      placeholder={["Apertura", "Cierre"]}
                    />
                  </Form.Item>
                  {!days.saturday ? (
                    <Plus
                      className={styles.plusMinusIcon}
                      onClick={() => setDays({ ...days, saturday: true })}
                    />
                  ) : (
                    <Minus
                      className={styles.plusMinusIcon}
                      onClick={() => setDays({ ...days, saturday: false })}
                    />
                  )}
                </span>
                {days.saturday && (
                  <Form.Item
                    label={
                      <span className={cx(styles.label, styles.labelHour)}>
                       
                      </span>
                    }
                    name="saturday2"
                    rules={[{ required: true, message: "Ingresar horario" }]}
                  >
                    <TimePicker.RangePicker
                      format={format}
                      className={cx(styles.input,styles.innerHour)}
                      placeholder={["Apertura", "Cierre"]}
                    />
                  </Form.Item>
                )}
                <span className={styles.formItem}>
                  <Form.Item
                    label={
                      <span className={cx(styles.label, styles.labelHour)}>
                        DOMINGO
                      </span>
                    }
                    name="sunday"
                    rules={[{ required: true, message: "Ingresar horario" }]}
                  >
                    <TimePicker.RangePicker
                      format={format}
                      className={styles.input}
                      placeholder={["Apertura", "Cierre"]}
                    />
                  </Form.Item>
                  {!days.sunday ? (
                    <Plus
                      className={styles.plusMinusIcon}
                      onClick={() => setDays({ ...days, sunday: true })}
                    />
                  ) : (
                    <Minus
                      className={styles.plusMinusIcon}
                      onClick={() => setDays({ ...days, sunday: false })}
                    />
                  )}
                </span>
                {days.sunday && (
                  <Form.Item
                    label={
                      <span className={cx(styles.label, styles.labelHour)}>
                        
                      </span>
                    }
                    name="sunday2"
                    rules={[{ required: true, message: "Ingresar horario" }]}
                  >
                    <TimePicker.RangePicker
                      format={format}
                      className={cx(styles.input,styles.innerHour)}
                      placeholder={["Apertura", "Cierre"]}
                    />
                  </Form.Item>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.itemSubmit}>
              <Button
                loading={sending}
                className={styles.submit}
                size="large"
                type="primary"
                htmlType="submit"
              >
                GUARDAR SUCURSAL
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Editor;
