import { createSlice } from "@reduxjs/toolkit";
import { getNotifications, saveNotification } from "../../api/Notifications";

const initialState = {
  notifications: [],
};
export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
  },
});

export const { setNotifications } = notificationsSlice.actions;

export const getNotificationsThunk = (userId) => async (dispatch) => {
  try {
    const response = await getNotifications(userId);
    if (!response.error && response.status === 200) {
        dispatch(setNotifications(response.data.notifications))
      return {
        status: "success",
        data: response.data,
      };
    }

    return {
      status: "error",
      type: "unknown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const saveNotificationThunk = (notification) => async (dispatch) => {
    try {
        const response = await saveNotification(notification)
        if(!response.error && response.status === 200){
            dispatch(setNotifications(response.data.notifications))
            return {
                status:'success',
                data: response.data
            }
        }
        return {
            status:'error',
            type:'unknown'
        }
    } catch (e) {
        return {
            status:'error',
            type:'unknown'
        }
    }
}

export const selectNotifications = (state) => state.notifications.notifications
export default notificationsSlice.reducer
