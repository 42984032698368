import instance from './Request';

export const getVehicles = async () => {
  const request = await instance();
  let data = await request
    .get('/vehicle')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};


export const newVehicle = async vehicle => {
  const request = await instance();
  let data = await request
    .post('/vehicle', vehicle)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const editVehicle = async vehicle => {
  const request = await instance();
  let data = await request
    .put(`/vehicle/${vehicle.id}`, vehicle)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const deleteVehicle = async id => {
  const request = await instance();
  let data = await request
    .delete(`/vehicle/${id}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const createDriver = async (data) => {
  const request = await instance()
  const response = await request.post('/admin/driver-create',data).catch((error)=>{
    return {
      error
    }
  })
  return response
}

export const updateDriver = async (data) => {
  const request = await instance()
  const response = await request.post('/admin/driver-update',data).catch((error)=> {
    return {
      error
    }
  })
  return response
}

export const availableDriver = async (data) => {
  const request = await instance()
  const response = await request.post('/admin/driver-available',data).catch((error)=>{
    return {
      error
    }
  })
  return response
}

export const getDriverInfo = async (driverId) => {
  const request = await instance()
  const response = await request.get(`/drivers/${driverId}`).catch((error)=>{
    return {
      error
    }
  })
  return response
}

export const listPausaDrivers = async (data) => {
  const request = await instance()
  const response = await request.post('/admin/drivers-pauses', data).catch((error)=>{
    return {
      error
    }
  })
  return response
}

export const approvePauseDriver = async (data) => {
  const request = await instance()
  const response = await request.post('/admin/accept-driver-pause', data).catch((error)=>{
    return {
      error
    }
  })
  return response
}

export const deniedPauseDriver = async (data) => {
  const request = await instance()
  const response = await request.post('/admin/denied-driver-pause', data).catch((error)=>{
    return {
      error
    }
  })
  return response
}

export const getPausesHistory = async (data) => {
  const request = await instance()
  const response = await request.post('drivers/history-pauses',data).catch((error)=>{
    return {
      error
    }
  })
  return response
}