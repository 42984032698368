import React, { useEffect, useRef } from "react";
import HeaderList from "../../../components/HeaderList";
import { Button, DatePicker, Image, Spin, Table } from "antd";
import moment from "moment";
import { getColumnSearchProps } from "../../../utils/tables";
import styles from "../styles/DriversPauseHistoryUI.module.css";
const DriversPauseHistoryUI = (props) => {
  const { loading, pausesHistory, dates, onChangeDates, exportToCSV } = props;
  const viewRef = useRef();

  const columns = [
    {
      title: "Fecha y Hora",
      dataIndex: "requestedAt",
      align: "center",
      render: (date) => moment(date).format("DD/MM/YYYY HH:mm A"),
      sorter:((a,b)=> moment(a.requestedAt).unix() - moment(b.requestedAt).unix())
    },
    {
      title: "Nombre del Driver",
      dataIndex: "driverName",
      ...getColumnSearchProps("driverName"),
    },
    {
      title: "Motivo",
      align: "center",
      dataIndex: "reasonName",
      filters: [
        {
          text: "Desperfecto Mecánico",
          value: "1",
        },
        {
          text: "Otros",
          value: "2",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.reason === value,
    },
    {
      title: "Foto",
      align: "center",
      dataIndex: "photo",
      render: (photo) => (
        <Image
          src={`data:image/jpeg;base64,${photo}`}
          width="100px"
          height="100px"
        />
      ),
    },
    {
      title: "Motivo del rechazo",
      dataIndex: "comment_response",
      align: "center",
    },
    {
      title: "Estado",
      dataIndex: "state",
      render: (state, record) => (
        <p className={state === 3 ? styles.denied : state === 2 ? styles.approve : styles.pending}>
          {record.stateName}
        </p>
      ),
    },
  ];
  useEffect(() => {
    const style = document.createElement("style");
    const currentRef = viewRef.current;
    style.innerHTML = `
          .ant-image-preview-img-wrapper {
            display: flex;
            justify-content: center;
            align-items:center;
          }
        `;
    if (currentRef) {
      currentRef.appendChild(style);
    }

    return () => {
      if (currentRef) {
        currentRef.removeChild(style);
      }
    };
  }, []);
  console.log(pausesHistory)

  return (
    <div ref={viewRef}>
      <HeaderList
        title="HISTORIAL DE SOLICITUDES"
        back={-1}
        actions={
          <div className={styles.actions}>
           
            <DatePicker.RangePicker
              value={dates}
              onChange={onChangeDates}
            />
             <Button type="primary" onClick={exportToCSV}>
              Exportar
            </Button>
          </div>
        }
      />

      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <Table dataSource={pausesHistory} columns={columns} />
      )}
    </div>
  );
};

export default DriversPauseHistoryUI;
