import {createSlice} from '@reduxjs/toolkit';
import {getBlogs, newBlog, editBlog, deleteBlog, getCategories} from '../../api/Blogs';

export const blogsSlice = createSlice({
  name: 'blogs',
  initialState: {
    blogs: [],
  },
  reducers: {
    setBlogs: (state, action) => {
      state.blogs = action.payload;
    },
    setBlog: (state, action) => {
      const index = state.blogs.findIndex(el => el.id === action.payload.id);
      if (index > -1) {
        state.blogs[index] = action.payload;
      }
    },
    addBlog: (state, action) => {
      state.blogs.push(action.payload);
    },
    removeBlog: (state, action) => {
      const index = state.blogs.findIndex(el => el.id === action.payload);
      if (index > -1) {
        state.blogs.splice(index, 1);
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {setBlogs, setBlog, addBlog, removeBlog} = blogsSlice.actions;

export const getAllBlogs = () => async dispatch => {
  try {
    const response = await getBlogs();
    if (!response.error && response.status === 200) {
      dispatch(setBlogs(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const getAllCategories = () => async () => {
  try {
    const response = await getCategories();
    if (!response.error && response.status === 200) {
      return {
        status: 'success',
        categories: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};


export const createBlog = data => async dispatch => {
  try {
    const response = await newBlog(data);
    if (!response.error && response.status === 200) {
      dispatch(addBlog(response.data));
      return {
        status: 'success',
        vehicle: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const updateBlog = (id, data) => async dispatch => {
  try {
    const response = await editBlog(id, data);
    if (!response.error && response.status === 200) {
      dispatch(setBlog(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const removeMonitorBlog = id => async dispatch => {
  try {
    const response = await deleteBlog(id);
    if (!response.error && response.status === 200) {
      dispatch(removeBlog(id));
      return {
        status: 'success',
        // departments: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const selectBlogs = state => state.blogs.blogs;

export default blogsSlice.reducer;
