import instance from "./Request";

export const getHistory = async (config) => {
  const request = await instance();
  let data = await request
    .post("/monitor/orders-history", config)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getOrderTimes = async (config) => {
  const request = await instance();
  let data = await request
    .post("/monitor/orders-times", config)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
