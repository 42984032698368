import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
} from "antd";
import styles from "../styles/NotificationsUI.module.css";
import Close from "../../../components/Close";
import { useDispatch } from "react-redux";
import { saveNotificationThunk } from "../../../redux/slices/notifications";
import { useState } from "react";
import moment from "moment";
const NotificationEditor = (props) => {
  const { closeNewNotification, userId } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [time, handleTime] = useState(1);
  const [title,handleTitle] = useState('')
  const [description,handleDescription] = useState('')
  const onFinish = async (values) => {
    try {
      const data = {
        ...values,
        fechaEnvio: values.fechaEnvio ? values.fechaEnvio.format("YYYY/MM/DD HH:mm:ss") : moment().startOf('day').format("YYYY/MM/DD HH:mm:ss"),
        fechaVencimiento: values.fechaVencimiento ? values.fechaVencimiento.format("YYYY/MM/DD HH:mm:ss") :  moment().endOf('day').format("YYYY/MM/DD HH:mm:ss"),
        userId,
      };
      const response = await dispatch(saveNotificationThunk(data));

      if (response.status === "success") {
        form.resetFields();
        Modal.success({
          title: "Notificación creada",
          content: "Notificación creada con éxito!",
        });
      } else {
        Modal.error({ title: "Hubo un error", content: "Inténtalo de nuevo" });
      }
    } catch (error) {
      Modal.error({ title: "Hubo un error", content: "Inténtalo de nuevo" });
    }
  };
  return (
    <div>
      <div className={styles.header}>
        <span className={styles.title}>NUEVA NOTIFICACIÓN</span>
        <Close action={closeNewNotification} />
      </div>
      <Row>
        <Col span={12}>
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            initialValues={{ time: 1 }}
          >
            <Form.Item
              name="titulo"
              label={<span className={styles.labelFormItem}>TITULO</span>}
              rules={[{ required: true }]}
            >
              <Input onChange={(e)=> handleTitle(e.target.value)} />
            </Form.Item>
            <Form.Item
              name="descripcion"
              label={<span className={styles.labelFormItem}>DESCRIPCIÓN</span>}
              rules={[{ required: true }]}
            >
              <Input.TextArea onChange={(e) => handleDescription(e.target.value)}/>
            </Form.Item>
            <Form.Item name="time" label={<span className={styles.labelFormItem}>HORARIO</span>}>
              <Radio.Group
                onChange={(e) => handleTime(e.target.value)}
                options={[
                  { value: 1, label: "Ahora" },
                  { value: 2, label: "Programado" },
                ]}
              />
            </Form.Item>
            {time === 2 && (
              <>
                <Form.Item
                  name="fechaEnvio"
                  label={
                    <span className={styles.labelFormItem}>FECHA ENVIÓ</span>
                  }
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    showTime
                    format="DD/MM/YYYY HH:mm"
                    className={styles.datePicker}
                  />
                </Form.Item>

                <Form.Item
                  name="fechaVencimiento"
                  label={
                    <span className={styles.labelFormItem}>
                      FECHA VENCIMIENTO
                    </span>
                  }
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    showTime
                    format="DD/MM/YYYY HH:mm"
                    className={styles.datePicker}
                  />
                </Form.Item>
              </>
            )}

            <Button htmlType="submit" type="primary" className={styles.submit}>
              GUARDAR NOTIFICACIÓN
            </Button>
          </Form>
        </Col>
        <Col span={12}>
       
        <div className={styles.colPreview}>
        <span className={styles.labelFormItem}>VISTA PREVIA</span>
         <div className={styles.container}>
            <span className={styles.notchLighter}>
            <span className={styles.notch}></span>
            <span className={styles.lighter}></span>
            </span>
            <span className={styles.notification}>
                <p>{title}</p>
                <p>{description}</p>
            </span>
         </div>
         </div>
        </Col>
      </Row>
    </div>
  );
};

export default NotificationEditor;
