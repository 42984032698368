import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Modal } from "antd";
import { selectUser } from "../../redux/slices/user";
import DriversPauseUI from "./components/DriversPauseUI";
import {
  approvePauseThunk,
  deniedPauseThunk,
  listPausesDriversThunk,
  selectListPauses,
} from "../../redux/slices/vehicles";

const Drivers = () => {
  const [loading, handleLoading] = useState(true);
  const [openModal,setOpenModal] = useState(false)
  const [pause,setPause] = useState({pause_id:null,driver_id:null,comment:null})
  const user = useSelector(selectUser);
  const listPauses = useSelector(selectListPauses);
  const dispatch = useDispatch();

  const initialFetch = useCallback(async () => {
    const response = await dispatch(
      listPausesDriversThunk({ userId: user.id })
    );
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
  }, [dispatch, user]);

  useEffect(() => {
    initialFetch();
  }, [dispatch]);
  
  const handleApprove = (pauseId,driverId) => {
    Modal.confirm({
      title:'Aprobar Pausa',
      content:'¿Deseas aprobar esta pausa?',
      async onOk (){
          approvePause(pauseId,driverId)
      }
    })
  }

  const approvePause = async (pauseId,driverId) => {
     try {
      const response = await dispatch(approvePauseThunk({driver_id:driverId,pause_id:pauseId}))
      if(response.status === 'success'){
        Modal.success({
          title:'Pausa Aceptada',
          content:'Se ha aceptado la pausa con éxito'
        })
      }else{
        Modal.error({
          title:'Hubo un error',
          content:'Inténtalo de nuevo'
        })
      }
     } catch (error) {
      Modal.error({
        title:'Hubo un error',
        content:'Inténtalo de nuevo'
      })
     }
  }
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const handleOpenModal = (data) => {
    setPause(data)
    setOpenModal(true)
  }


  const onFinishDeniedPause = async (values) => {
     try {
        const response = await dispatch(deniedPauseThunk({...pause,comment:values.comment}))

        if(response.status === 'success'){
         handleCloseModal()
         Modal.success({
          title:'Pausa denegada',
          content:'Se ha denegado la pausa con éxito'
         })
        }else{
          Modal.error({
            title:'Hubo un error',
            content:'Inténtalo de nuevo',
          })
        }
     } catch (error) {
      Modal.error({
        title:'Hubo un error',
        content:'Inténtalo de nuevo'
      })
     }
  }
  return (
    <DriversPauseUI
      loading={loading}
      handleLoading={handleLoading}
      user={user}
      pauses={listPauses}
      handleApprove={handleApprove}
      onFinishDeniedPause={onFinishDeniedPause}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
      openModal={openModal}
    />
  );
};

export default Drivers;
