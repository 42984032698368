import { Button, Table, Switch, Spin, Modal } from "antd";
import { getColumnSearchProps } from "../../../utils/tables";
import Header from "../../../components/HeaderList";
import { selectUser } from "../../../redux/slices/user";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";
import Editor from "./Editor";
import styles from "../styles/BranchesUI.module.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const BranchesUI = (props) => {
  const {
    loading,
    branches,
    branch,
    openEditor,
    editorVisible,
    closeEditor,
    initialFetch,
    handleTakeOut,
    handleDelivery,
    newBranch,
    handleBranch,
    confirmDelete,
  } = props;

  const user = useSelector(selectUser);
  const navigate = useNavigate()
  const hasPermission = (type) => {
    return user.rols.includes(type);
  };

  const columns = [
    {
      title: "Zona",
      dataIndex: "zone",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Dirección",
      dataIndex: "address",
    },
    {
      title: "Teléfono",
      dataIndex: "phone",
      align: "center",
    },
    {
      title: "Take Out",
      align: "center",
      dataIndex: "take_out",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.take_out === value,
      render: (val, record) => (
        <Switch checked={val} onChange={() => handleTakeOut(record.id, val)} />
      ),
    },
    {
      title: "Delivery",
      align: "center",
      dataIndex: "delivery",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.delivery === value,
      render: (val, record) => (
        <Switch checked={val} onChange={() => handleDelivery(record.id, val)} />
      ),
    },
    {
      title: "Editar",
      align: "center",
      dataIndex: "id",
      render: (id, record) => (
        <div className={styles.actions}>
          <div className={styles.edit} onClick={() => openEditor(record)}>
            <Edit />
          </div>
          {
            hasPermission("settings") && (
              <>
              <div className={styles.divider} />
              <div className={styles.delete} onClick={() => confirmDelete(id)}>
                <Trash />
              </div>
              </>
            )
          }
        </div>
      ),
    },
  ];

  return (
    <div className={styles.view}>
      {hasPermission("settings") ? (
                <Header
                title="Sucursales"
                actions={
                  <div className={styles.buttonBranch}>
                  <Button
                  onClick={newBranch}
                  type="primary"
                  size="large"
                  className="main-button"
                >
                  NUEVA SUCURSAL
                </Button>
                <Button
                  onClick={()=>navigate('sucursal-emergencia')}
                  type="primary"
                  size="large"
                  className="main-button"
                >
                  SUCURSAL EMERGENCIA
                </Button>
                </div>
                }
              />
        )  :  (
          <Header
                title="Información de la Sucursal" />
        )}
      
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table columns={columns} dataSource={branches} rowKey="id" />
        </div>
      )}
      <Modal
        destroyOnClose
        closable={false}
        visible={editorVisible}
        footer={false}
        onCancel={closeEditor}
        width={950}
      >
        <Editor
          close={closeEditor}
          branch={branch}
          initialFetch={initialFetch}
          handleBranch={handleBranch}
        />
      </Modal>
    </div>
  );
};

export default BranchesUI;
