import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message} from 'antd';
import {getAllClients, selectClients, setPage, selectPage, selectLoaded, setLoaded} from '../../redux/slices/clients';
import ClientsUI from './components/ClientsUI';
import exportClients from '../../utils/exportClients';

const Clients = () => {
  const loaded = useSelector(selectLoaded);
  const [loading, handleLoading] = useState(!loaded);
  const clients = useSelector(selectClients);
  const page = useSelector(selectPage);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      if (!loaded) {
        const [response] = await Promise.all([
          dispatch(getAllClients()),
        ]);
        if (response.status !== 'success') {
          message.error('¡Hubo un problema!');
        }
        handleLoading(false);
        dispatch(setLoaded(true));
      }
    };
    initialFetch();
  }, [dispatch, loaded]);

  const exportData = () => {
    exportClients(clients);
  };

  const handlePage = newPage => {
    dispatch(setPage(newPage));
  };

  return (
    <ClientsUI
      loading={loading}
      clients={clients}
      exportData={exportData}
      page={page}
      handlePage={handlePage}
    />
  );
}

export default Clients;
