import React from "react";
import styles from "../styles/ClientsUI.module.css";
import Close from "../../../components/Close";
import { Button, Col, Form, Input, Row } from "antd";
const PasswordEditor = ({client,closeEditor}) => {
  return (
    <div className={styles.editor}>
      <div className={styles.header}>
        <span className={styles.title}>Editar Contraseña</span>
        <Close action={closeEditor}/>
      </div>
      <Form>
        <Row>
            <Col span={24}>
            <Form.Item name="old_password" className={styles.itemColumn} rules={[{required:true,message:"Ingresar contraseña actual"}]} label={<span className={styles.label}>CONTRASEÑA ACTUAL</span>}>
                <Input.Password  className={styles.input} size="large" />
            </Form.Item>
            <Form.Item name="new_password" className={styles.itemColumn} rules={[{required:true,message:"Ingresar contraseña nueva"}]} label={<span className={styles.label}>CONTRASEÑA NUEVA</span>}>
                <Input.Password  className={styles.input} size="large" />
            </Form.Item>
            </Col>
            <Col span={8}>
            <Form.Item className={styles.itemSubmit}>
                <Button htmlType="submit" type="primary" className={styles.submit} size="large">GUARDAR NUEVA CONTRASEÑA</Button>
            </Form.Item>
            </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PasswordEditor;
