import { createSlice } from "@reduxjs/toolkit";
import {
  getPromos,
  getTypes,
  editPromo,
  newPromo,
  deletePromo,
  changePromoActive,
} from "../../api/Promos";

const sortPromos = (x, y) => (x.active === y.active ? 0 : x.active ? -1 : 1);

export const promosSlice = createSlice({
  name: "promos",
  initialState: {
    promos: [],
  },
  reducers: {
    setPromos: (state, action) => {
      state.promos = action.payload;
    },
    setActive: (state, action) => {
      const { promo, val } = action.payload;
      const index = state.promos.findIndex((el) => el.id === promo);
      state.promos[index].active = val;
    },
    setPromo: (state, action) => {
      const index = state.promos.findIndex((el) => el.id === action.payload.id);
      if (index > -1) {
        state.promos[index] = action.payload;
      }
    },
    addPromo: (state, action) => {
      state.promos.push(action.payload);
    },
    removePromo: (state, action) => {
      const index = state.categories.findIndex(
        (el) => el.id === action.payload
      );
      if (index > -1) {
        state.promos.splice(index, 1);
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPromos, setActive, addPromo, setPromo, removePromo } =
  promosSlice.actions;

export const getAllPromos = () => async (dispatch) => {
  try {
    const response = await getPromos();
    if (!response.error && response.status === 200) {
      const promos = response.data;
      promos.sort(sortPromos);
      dispatch(setPromos(promos));
      return {
        status: "success",
        first: promos[0],
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const getTypesList = () => async () => {
  try {
    const response = await getTypes();
    if (!response.error && response.status === 200) {
      return {
        status: "success",
        types: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const activePromoFrontpage = (id, val) => async (dispatch) => {
  try {
    dispatch(setActive({ promo: id, val: val }));
    const response = await changePromoActive(id);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
      };
    }
    dispatch(setActive({ promo: id, val: !val }));
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    dispatch(setActive({ promo: id, val: !val }));
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const updatePromo = (id, data) => async (dispatch) => {
  try {
    const response = await editPromo(id, data);
    if (!response.error && response.status === 200) {
      if (response.data?.status === 300) {
        return {
          status: "error",
          type: "incomplete",
        };
      }
      dispatch(setPromo(response.data));
      return {
        status: "success",
        promo: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    console.log(e);
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const createPromo = (data) => async (dispatch) => {
  try {
    const response = await newPromo(data);
    if (!response.error && response.status === 200) {
      if (response.data?.status === 300) {
        return {
          status: "error",
          type: "incomplete",
        };
      }
      dispatch(addPromo(response.data));
      return {
        status: "success",
        promo: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const deletePromoMonitor = (id) => async (dispatch) => {
  try {
    const response = await deletePromo(id);
    if (!response.error && response.status === 200) {
      dispatch(removePromo(id));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const selectPromos = (state) => state.promos.promos;

export default promosSlice.reducer;
