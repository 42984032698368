import instance from "./Request";

export const getLastClientScaned = async(client) => {
    const request = await instance();
    let data = await request.post("/api/registro/ultimos-verificados", client)
    .catch((error) => {
      return {
        error,
      };
    });
    return data;
}

export const getClientScannedList = async(client)=>{
  const request = await instance()
  let data = await request.post('/api/registro/verificados-tienda', client)
  .catch((error) => {
    return {
      error,
    };
  });
  return data;
}