import getInstance from "./Request"


export const getNotifications = async (userId) => {
    const request = await getInstance();
    const data = await request.post('notificaciones-push/listado',{
        userId: userId
    }
    ).catch((error)=> {
        return {
            error
        }
    })

    return data
}


export const saveNotification = async (notification) => {
    const request = await getInstance()
    const data = await request.post('notificaciones-push/nuevo',notification).catch((error)=>{
        return {
            error
        }
    })
    return data
}