import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import moment from "moment";
import "moment/locale/es";
import "moment-timezone";
import {
  getPlatformRatings,
  selectRatings,
  selectAverage,
} from "../../redux/slices/ratings";
import RatingsUI from "./components/RatingsUI";
import { selectUser } from "../../redux/slices/user";
import {
  getAllBranches,
  selectBranches
} from "../../redux/slices/branches";
const Ratings = () => {
  const [loading, handleLoading] = useState(true);
  const ratings = useSelector(selectRatings);
  const average = useSelector(selectAverage);
  const [beginDate, setBeginDate] = useState(
    moment.tz("America/El_Salvador").subtract(7, "days")
  );
  const [endDate, setEndDate] = useState(moment.tz("America/El_Salvador"));
  const [platform, setPlatform] = useState(2);
  const [editorVisible,handleVisibleEditor] = useState(false)
  const [question,handleQuestion] = useState({})
  const [resultVisible,handleVisibleResult] = useState(false)
  const [selectedBranchId,handleSelectedBranchId] = useState(null)
  const user = useSelector(selectUser);
  const branches = useSelector(selectBranches)
  const dispatch = useDispatch();

  const initialFetch = useCallback(async () => {
    handleLoading(true);
    const config = {
      start_date: beginDate.format("YYYY-MM-DD 00:00:00"),
      end_date: endDate.format("YYYY-MM-DD 23:59:59"),
      id: platform,
    };
    const response = await Promise.all([dispatch(getPlatformRatings(config)),dispatch(getAllBranches(user.id))])
    if (response[0].status !== "success") {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
  }, [beginDate, dispatch, endDate, platform]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  const handleDates = (newDates) => {
    dispatch(setBeginDate(newDates[0]));
    dispatch(setEndDate(newDates[1]));
  };
  
  const openEditor = (record) => {
    handleQuestion(record)
    handleVisibleEditor(true)
  }

  const closeEditor = () => {
    handleVisibleEditor(false)
  }

  const openResult = (record) => {
    handleQuestion(record)
    handleVisibleResult(true)
  }

  const closeResult = () => {
    handleVisibleResult(false)
  }
  return (
    <RatingsUI
      loading={loading}
      ratings={ratings}
      average={average}
      platform={platform}
      setPlatform={setPlatform}
      beginDate={beginDate}
      endDate={endDate}
      handleDates={handleDates}
      editorVisible={editorVisible}
      openEditor={openEditor}
      closeEditor={closeEditor}
      question={question}
      handleQuestion={handleQuestion}
      openResult={openResult}
      closeResult={closeResult}
      resultVisible={resultVisible}
      branches={branches}
      handleSelectedBranchId={handleSelectedBranchId}
      selectedBranchId={selectedBranchId}
    />
  );
};

export default Ratings;
