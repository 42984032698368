import React, { useState } from 'react';
import ScannerUI from './components/ScannerUI';
import { useDispatch } from 'react-redux';
import { getOrderDetails } from '../../redux/slices/monitor';
import { message } from 'antd';
import { setUserScanned } from '../../redux/slices/scanner';

const Scanner = () => {
    const [loadingOrder, handleLoadingOrder] = useState(false);
    const [orderDetail, handleOrderDetail] = useState({});
    const [modalOrder, handleModalOrder] = useState(false);
    const dispatch = useDispatch()

    const openDetails = async (order) => {
        handleLoadingOrder(order);
        const response = await dispatch(getOrderDetails(order));
        handleLoadingOrder(false);
        if (response.status !== "success") {
          message.error("Hubo un problema");
        } else {
          handleOrderDetail(response.order);
          handleModalOrder(true);
        }
      };

      const closeDetails = () => {
        handleModalOrder(false);
        handleOrderDetail({});
        dispatch(setUserScanned(null))
      };
    

    return (
       <ScannerUI
        loadingOrder={loadingOrder}
        openDetails={openDetails}
        modalOrder={modalOrder}
        orderDetail={orderDetail}
        closeDetails={closeDetails}
       />
    );
};

export default Scanner;