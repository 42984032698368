import React, { useCallback, useEffect, useState } from "react";
import NotificationsUI from "./components/NotificationsUI";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/user";
import {
  getNotificationsThunk,
  selectNotifications,
} from "../../redux/slices/notifications";
import { message } from "antd";

const Main = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const notifications = useSelector(selectNotifications);
  const [loading, handleLoading] = useState(true);
  const [showNewNotification, handleShowNewNotification] = useState(false);
  const initialFetch = useCallback(async () => {
    handleLoading(true);
    const response = await dispatch(getNotificationsThunk(user.id));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
  }, [dispatch, user.id]);
  const closeNewNotification = () => {
    handleShowNewNotification(false);
  };
  const openNewNotification = () => {
    handleShowNewNotification(true);
  };
  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  return (
    <NotificationsUI
      notifications={notifications}
      loading={loading}
      openNewNotification={openNewNotification}
      closeNewNotification={closeNewNotification}
      showNewNotification={showNewNotification}
      userId={user.id}
    />
  );
};

export default Main;
