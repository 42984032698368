import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, message } from "antd";
import { signIn } from "../../redux/slices/user";
import LoginUI from "./components/LoginUI";

const Main = () => {
  const [sending, handleSending] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const login = async () => {
    handleSending(true);
    try {
      const { user, password } = await form.validateFields();
      const response = await dispatch(signIn(user, password));
      if (response.status === "success") {
      } else if (response.type === "wrong-user") {
        message.error("Credenciales incorrectas");
      } else {
        message.error("¡Hubo un problema! Inténtalo de nuevo");
      }
    } catch (errorInfo) {
      if (errorInfo.errorFields) {
        message.error("Ingresa los campos solicitados");
      } else {
        message.error("¡Hubo un problema! Inténtalo de nuevo");
      }
    }
    handleSending(false);
  };

  return <LoginUI login={login} form={form} sending={sending} />;
};

export default Main;
