import instance from "./Request";

export const cancellationReasonList = async () => {
  const request = await instance();

  const data = request.post("api/lista-motivos-cancelacion").catch((error) => {
    return {
      error,
    };
  });
  return data;
};
