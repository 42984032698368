import React, { useState } from "react";
import PromoUI from "./components/PromoUI";
import { getOrderDetails } from "../../redux/slices/monitor";
import { message } from "antd";
import { useDispatch } from "react-redux";

const Promo = () => {
  const [loadingOrder, handleLoadingOrder] = useState(false);
  const [orderDetail, handleOrderDetail] = useState({});
  const [modalOrder, handleModalOrder] = useState(false);
  const dispatch = useDispatch();

  const openDetails = async (order) => {
    handleLoadingOrder(order);
    const response = await dispatch(getOrderDetails(order));
    handleLoadingOrder(false);
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      handleOrderDetail(response.order);
      handleModalOrder(true);
    }
  };

  const closeDetails = () => {
    handleModalOrder(false);
    handleOrderDetail({});
  };

  return (
    <PromoUI
      loadingOrder={loadingOrder}
      openDetails={openDetails}
      modalOrder={modalOrder}
      orderDetail={orderDetail}
      closeDetails={closeDetails}
    />
  );
};

export default Promo;
