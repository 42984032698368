import { Col, Row, Spin, Table, Image, Button } from "antd";
import moment from "moment-timezone";
import Header from "../../../components/HeaderList";
import styles from "../styles/DriversPauseUI.module.css";
import { useEffect, useRef } from "react";
import { getColumnSearchProps } from "../../../utils/tables";
import DeniedPause from "./DeniedPause";
import { ReactComponent as History } from "../../../assets/icons/history.svg";
import { Link } from "react-router-dom";
const DriversPauseUI = (props) => {
  const {
    loading,
    handleApprove,
    pauses,
    user,
    handleOpenModal,
    handleCloseModal,
    openModal,
    pause,
    onFinishDeniedPause,
  } = props;
  const viewRef = useRef();
  
  const hasPermission = (type) => {
    return user.rols.includes(type);
  };

  useEffect(() => {
    const style = document.createElement("style");
    const currentRef = viewRef.current;
    style.innerHTML = `
          .ant-image-preview-img-wrapper {
            display: flex;
            justify-content: center;
            align-items:center;
          }
        `;
    if (currentRef) {
      currentRef.appendChild(style);
    }

    return () => {
      if (currentRef) {
        currentRef.removeChild(style);
      }
    };
  }, []);

  const columns = [
    {
      title: "Fecha y Hora",
      dataIndex: "requestedAt",
      align: "center",
      render: (dateTime) => moment(dateTime).format("DD/MM/YYYY HH:mm A"),
      sorter: (a, b) =>
        moment(a.requestedAt).unix() - moment(b.requestedAt).unix(),
    },

    {
      title: "Nombre del Driver",
      dataIndex: "driverName",
      ...getColumnSearchProps("driverName"),
    },
    {
      title: "Motivo",
      align: "center",
      dataIndex: "reasonName",
      filters: [
        {
          text: "Desperfecto Mecánico",
          value: "1",
        },
        {
          text: "Otros",
          value: "2",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.reason === value,
    },
    {
      title: "Foto",
      align: "center",
      dataIndex: "photo",
      render: (photo) => (
        <Image
          src={`data:image/jpeg;base64,${photo}`}
          width="100px"
          height="100px"
        />
      ),
    },
    {
      title: "Opciones",
      align: "center",
      dataIndex: "id",
      render: (_, record) => (
        <div className={styles.actions}>
          {hasPermission("settings") && (
            <div className={styles.actions}>
                <Button
                  onClick={() =>
                    handleOpenModal({
                      pause_id: record.id,
                      driver_id: record.id_motorista.id,
                      comment: record.comment,
                    })
                  }
                  type="ghost"
                  size="small"
                >
                  Denegar
                </Button>
                <div className={styles.divider} />
                <Button
                  type="primary"
                  size="small"
                  onClick={() =>
                    handleApprove(record.id, record.id_motorista.id)
                  }
                >
                  Aprobar
                </Button>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div id="view" ref={viewRef} className={styles.view}>
      <Header
        back="/"
        title="SOLICITUDES DE PAUSA"
        actions={
          <Link to="historial" className={styles.history}>
            <History />
            <span>VER HISTORIAL DE SOLICITUDES</span>
          </Link>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Row gutter={6}>
            <Col span={24}>
              <Table columns={columns} dataSource={pauses} rowKey="id" />
              <DeniedPause
                handleCloseModal={handleCloseModal}
                openModal={openModal}
                pause={pause}
                onFinishDeniedPause={onFinishDeniedPause}
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default DriversPauseUI;
