import React, { useState,useEffect,useCallback } from 'react'
import ReportUI from './components/ReportUI'
import moment from 'moment'
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../../redux/slices/user";
import {message} from 'antd'
import {getLogsThunk,selectLogs} from '../../redux/slices/logs'
const Main = () => {
  const [dates,handleDates] = useState([moment(),moment().subtract(7,'days')])
  const [loading,handleLoading] = useState(true)
  const logs = useSelector(selectLogs)
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  const initialFetch = useCallback(async () => {
    handleLoading(true);
    const config = {
      start_date: dates[0].format("YYYY-MM-DD 00:00:00"),
      end_date: dates[1].format("YYYY-MM-DD 23:59:59"),
      id: user.id,
    };
    const response = await dispatch(getLogsThunk(config))
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
  }, [dates, dispatch]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  
  const onChangeDates = (datesInputs) => {
        handleDates(datesInputs)
  }

  return (
        <ReportUI onChangeDates={onChangeDates} loading={loading} logs={logs} dates={dates} />
  )
}

export default Main