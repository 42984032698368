import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom'
import { Spin, Row, Col, Button, Switch, Form, Input, Upload, Select, message } from "antd";
import * as tj from "@mapbox/togeojson";
import rewind from "@mapbox/geojson-rewind";
import {useDispatch} from 'react-redux';
import { setCobertura } from '../../../redux/slices/zones';
import Header from "../../../components/HeaderList";
import { normFile, dummyRequest } from "../../../utils/images";
import {validateFloatKeyPress} from '../../../utils/format';
import ZoneMap from "../../../components/customMap";
import styles from "../styles/ZoneUI.module.css";



const {Option} = Select;

const ZoneUI = (props) => {
    const {
      loading,
      zone,
      zoneId,
      branches,
      levels,
      handleZone
    } = props;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [sending, handleSending] = useState(false);
    const [zoneActive, setActive] = useState(true);
    const [fileup, handleFileUp] = useState(null);
    const [zoom, setZoom] = useState(8);
    const [branchesMarkers, setBranchesMarkers] = useState([]);
    const [coords, setCoordinates] = useState([]);
    const [latlng_center, setLatLngCenter] = useState({lat:13.83333333, lng:-88.91666666 });


    useEffect(() => {
      const initialFetch = async () => {
        if (zone) {
          const newValues = {
            name: zone.coverage?zone.coverage.name:"",
            location: zone.id,
            level: zone.coverage?(zone.coverage.time==20?1:2):1,
            delivery_fee: zone.coverage?(zone.coverage.fee):"",
          };
          setLatLngCenter({ lat: Number(zone.latitude), lng: Number(zone.longitude) });
          setZoom(14);
          form.setFieldsValue(newValues);
          if(zone.coverage.zones) {
            setCoordinates(zone.coverage.zones);
            getBranchesMarker();
          }
        }
      };
      initialFetch();
    }, [zone]);

    const getBranchesMarker = () => {
      let markers = [];
      if(branches.length > 0) {
          for(let branch of branches.filter(x => x.id==zone.id)) {
              var newMarker = {
                  id: branch.id,
                  title: branch.zone,
                  address: branch.address,
                  position: {lat: parseFloat(branch.latitude), lng: parseFloat(branch.longitude)},
                  
                  icon: {
                      url:  (require("../../../assets/icons/location-branch.svg")).default
                  },
                  
                  infowindowOpen: false,
                  markeroptions: {}
              };
              markers.push(newMarker);
          }
          setBranchesMarkers(markers);
      }
    };

    const changeCenter = (value, option) => {
      const lat = option['data-latitude'];
      const lng = option['data-longitude'];
      setLatLngCenter({ lat: Number(lat), lng: Number(lng) });
      setZoom(14);
    }

    const handleActive = () => {
        if(zoneActive){
          setActive(false);
        } else {
          setActive(true);
        }
    }

    const setCoords = (objArr) => {
      setCoordinates(objArr);
      //form.setFields([{name: "ZoneCoords", value: obj}])
    }

    const beforeUploadKml = (file) => {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("La imagen debe ser menor a 2MB!");
      }
      const reader = new FileReader();
      reader.onload = e => {
        var text = e.target.result;
        parseKMLtoGeoJSON(text);
      };
      reader.readAsText(file);
      
      return isLt2M;
    };

    const save = async () => {
        try {
          handleSending(true);
          const values = await form.validateFields();
          
          const info = {
            name: values.name,
            locationId: values.location,
            cobertura: parseInt(values.level),
            delivery_fee: parseFloat(values.delivery_fee),
            active: zoneActive,
            zoneCoords: coords
          };
          if (zone) {
            info.id = zone.id;
          }
          if (zone) {
            const response = await dispatch(setCobertura(info));
            if (response.status === 'success') {
              message.success('Cambios realizados con éxito');
              navigate('/ajustes/zonas');
            } else {
              message.error('¡Hubo un problema! Inténtalo de nuevo');
            }
          } else {
            const response = await dispatch(setCobertura(info));
            if (response.status === 'success') {
              message.success('Cambios realizados con éxito');
              handleZone(response.zone);
              navigate('/ajustes/zonas');
            } else {
              message.error('¡Hubo un problema! Inténtalo de nuevo');
            }
          }
          handleSending(false);
          
        } catch (e) {
        }
    };
    
    const parseKMLtoGeoJSON = (text) => {
      const dom = new DOMParser().parseFromString(text, "text/xml"); // create xml dom object
      const converted = tj.kml(dom); // convert xml dom to geojson
      rewind(converted, false); // correct right hand rule
      console.log(converted);
      let newcoord= [];
      let newobj = {id_polygon: (new Date()).getTime(), coord: newcoord};
      // procedemos a convertirlo al formato 
      if(converted.type == "FeatureCollection"){
        for(let feat of converted.features) {
          if(feat.type == "Feature") {
            if(feat.geometry.type == "Polygon") {
              for(let coord of feat.geometry.coordinates) {
                for(let lat_lng of coord){
                  newcoord.push({lat: lat_lng[1], lng: lat_lng[0]});
                }
              }
            }
          }
        }
      }

      console.log(newcoord);
      console.log(newobj);
      setCoordinates([...coords, newobj]);
    };

    return (
        <div className={styles.view}>
          <Header
            back="/ajustes/zonas"
            title={loading ? "Zona" : (zone==null? " Nueva": `${zone.name}`) }
            actions={
              <div className={styles.actions}>
                <div className={styles.switchWrap}>
                  <span>Activo</span>
                  <Switch checked={zoneActive} onChange={handleActive} />
                </div>
              </div>
            }
          />
          {loading ? (
            <div className={styles.loader}>
              <Spin size="large" />
            </div>
          ) : (
            <div>
                <Form
                    name="zone-editor"
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    colon={false}
                    requiredMark={false}
                    onFinish={save}
                >
                    <Row gutter={40}>
                    <Col span={8}>

                    <Row gutter={20}>
                    <Col span={24}>
                        <Form.Item
                        label={
                            <span className={styles.label}>
                            DOMICILIO
                            </span>
                        }
                        name="location"
                        rules={[{required: true, message: 'Ingresar domicilio'}]}
                        >
                        <Select size="large" className={styles.input} onChange={changeCenter}>
                            {branches.map(branch => (
                              <Option key={branch.id} value={branch.id} data-latitude={branch.latitude} data-longitude={branch.longitude}>{branch.zone}</Option>
                            ))}
                        </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={ <span className={styles.label}>TARIFA </span> }
                            name="delivery_fee"
                            normalize={validateFloatKeyPress}
                            className={styles.itemColumn}
                            rules={[{required: true, message: 'Ingresar tarifa'}]}
                        >
                          <Input size="large" className={styles.input} prefix="$" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                        label={
                            <span className={styles.label}>
                            TIPO DE COBERTURA
                            </span>
                        }
                        name="level"
                        className={styles.itemColumn}
                        rules={[{required: true, message: 'Ingresar tipo'}]}
                        >
                        <Select size="large" className={styles.input}>
                            {levels.map(level => (
                            <Option key={level.id} value={level.id}>{level.name}</Option>
                            ))}
                        </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                        label={
                            <span className={styles.label}>
                            NOMBRE
                            </span>
                        }
                        name="name"
                        className={styles.itemColumn}
                        rules={[{required: true, message: 'Ingresar nombre'}]}
                        >
                        <Input size="large" className={styles.input} />
                        </Form.Item>
                    </Col>
                    
                    <Col span={24}>
                        <Form.Item className={styles.itemSubmit}>
                        <Button
                            loading={sending}
                            className={styles.submit}
                            size="large"
                            type="primary"
                            htmlType="submit">
                            GUARDAR COBERTURA
                        </Button>
                        </Form.Item>
                    </Col>
                    </Row>
                    </Col>
                    <Col span={16}>
                    <Form.Item
                        name="kmlfile"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        label={ <span className={styles.label}>ARCHIVO DE MAPA</span> }
                        className={styles.itemColumn}
                        extra={ <span className={styles.center}>(el formato del archivo debe ser .kml)</span> }
                      >
                        <Upload
                          maxCount={1}
                          showUploadList={false}
                          customRequest={(e) => dummyRequest(e, handleFileUp)}
                          beforeUpload={beforeUploadKml}
                          listType="file"
                        >
                            <Button size="large" className={styles.uploadImage}>
                              cargar mapa
                            </Button>
                        </Upload>
                      </Form.Item>
                    <Form.Item name="ZoneCoords" className={styles.itemSubmit}>
                        <ZoneMap
                            polygons={coords}
                            branchesMarkers={branchesMarkers}
                            defaultCenter={latlng_center}
                            centerZoom={zoom}
                            setPolygons={setCoords}
                            loadDraw={true}
                        />
                        </Form.Item>
                    </Col>
                </Row>
                </Form>
            </div>  
          )}
        </div>
    );
};

export default ZoneUI;

