import {useRef} from 'react';
import { Link } from 'react-router-dom';
import {Button, Table, Spin, Modal, Switch} from 'antd';
import Header from '../../../components/HeaderList';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import {getColumnSearchProps} from '../../../utils/tables';
import Editor from './Editor';
import styles from '../styles/ZonesUI.module.css';


const ZonesUI = props => {
  const searchInput = useRef(null);

  const {
    loading,
    zones,
    zone,
    branches,
    openEditor,
    editorVisible,
    closeEditor,
    departments,
    levels,
    newZone,
    confirmDelete,
    handleZone,
    handleActive,
  } = props;

  const getLocalityName = locality => {
    let name = '-';
    departments.map(department => {
      const index = department.items.findIndex(el => el.id === locality);
      if (index > -1) {
        name = department.items[index].name;
      }
    });
    return name;
  };

  const getLevels = level => {
    const index = levels.findIndex(el => el.id === level);
    if (index > -1) {
      return levels[index].name;
    }
    return '-';
  };

  const getLocalities = () => {
    const localities = [];
    departments.map(d => {
      d.items.map(m => {
        localities.push({
          text: m.name,
          value: m.id,
        });
      })
    });
    return localities;
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: 'Municipio',
      dataIndex: 'localityId',
      align: 'center',
      filters: getLocalities(),
      onFilter: (val, record) => record.localityId === val,
      render: val => getLocalityName(val, searchInput),
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Cobertura',
      dataIndex: 'level',
      filters: levels.map(level => ({
        text: level.name,
        value: level.id,
      })),
      onFilter: (val, record) => record.level === val,
      render: val => getLevels(val),
    },
    {
      title: 'Tarifa',
      dataIndex: 'fee',
      align: 'center',
      render: (val, row) => `${row.feeCurrency}${parseFloat(val).toFixed(2)}`, 
    },
    {
      title: 'Activo',
      align: 'center',
      dataIndex: 'active',
      render: (val, record) => (
        <Switch checked={val} onChange={() => handleActive(record.id, val)} />
      ),
    },
    {
      title: 'Editar',
      align: 'center',
      dataIndex: 'id',
      render: (id, record) => (
        <div className={styles.actions}>
          <div className={styles.edit} onClick={() => openEditor(record)}>
            <Edit />
          </div>
          <div className={styles.divider} />
          <div className={styles.delete} onClick={() => confirmDelete(id)}>
            <Trash />
          </div>
        </div>
      ),
    },
  ];

  const BranchColumns = [
    {
      title: "Sucursal",
      dataIndex: "zone",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Dirección",
      dataIndex: "address",
    },
    {
      title: "punto GPS",
      dataIndex: "id",
      align: "center",
      render: (id, record) => (
        <div className={styles.actions}>[{record.latitude}, {record.longitude}]</div>
      )
    },
    
    
    {
      title: "ver Cobertura",
      align: "center",
      dataIndex: "id",
      render: (id, record) => (
        <div className={styles.actions}>
          <Link className={styles.edit} to={`/ajustes/zonas/edit/${id}`}>
            <Edit />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.view}>
      <Header
        title="Coberturas"
        actions={
          <>
          <Link className={styles.edit} to={`/ajustes/zonas/listado`}>
            <Button
          type="primary"
          size="large"
          className="main-button">
           IR A ZONAS
        </Button> 
        </Link>
          </>
        }
      />
        
         
     {/*    <Button
          onClick={newZone}
          type="primary"
          size="large"
          className="main-button">
          NUEVA COBERTURA
        </Button> 
         */}
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table
            columns={BranchColumns}
            dataSource={branches}
            rowKey="id"
          />
        </div>
      )}
      <Modal
        destroyOnClose
        closable={false}
        visible={editorVisible}
        footer={false}
        onCancel={closeEditor}
        width={600}
      >
        <Editor
          close={closeEditor}
          zone={zone}
          departments={departments}
          levels={levels}
          branches={branches}
          handleZone={handleZone}
        />
      </Modal>
    </div>
  );
};

export default ZonesUI;
