import instance from "./Request";

export const getRatings = async (config) => {
  const request = await instance();
  let data = await request.post("/ratings", config).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const newQuestion = async (user) => {
  const request = await instance();
  let data = await request
    .post("/ratings/create-question", user)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const editQuestion = async (user) => {
  const request = await instance();
  let data = await request
    .post("/ratings/update-question", user)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
