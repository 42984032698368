import { Button, Form, Input, Radio } from "antd";
import styles from "../styles/Params.module.css";
import cx from "classnames";

const ParamsUi = () => {
  // carta de parametros en ajustes
  // mandarlo a otra vista y mostra de una inico un forma para editar parametros de la api

  return (
    <div className={styles.view}>
      <Form layout="vertical">
        <Form.Item
          name="habilitacion"
          label={
            <span className={cx(styles.label, styles.labelHour)}>
              HABILITACIÓN DE INVITADO
            </span>
          }
          rules={[{ required: true }]}
        >
          <Radio.Group options={[{label:'Si',value:true},{label:'No',value:false}]} />
        </Form.Item>
        <Form.Item
          name="codigo"
          label={
            <span className={cx(styles.label, styles.labelHour)}>
              INVITADO PUEDE USAR CÓDIGO
            </span>
          }
          rules={[{ required: true }]}
        >
          <Radio.Group options={[{label:'Si',value:true},{label:'No',value:false}]} />
        </Form.Item>
        <Form.Item>
          <Button
            className={styles.submit}
            size="large"
            type="primary"
            htmlType="submit"
          >
            GUARDAR PARÁMETROS
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ParamsUi;
