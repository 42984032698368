import React, { useRef } from 'react'
import Header from '../../../components/HeaderList'
import { Button, Modal, Spin, Switch, Table } from 'antd';
import { getColumnSearchProps } from '../../../utils/tables';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import styles from '../styles/ZoneListUI.module.css';
import Editor from '../../zones/components/Editor';

const ZoneUi = ({
    loading,
    newZone,
    zones,
    zone,
    editorVisible,
    departments,
    levels,
    openEditor,
    confirmDelete,
    handleActive,
    closeEditor,
    handleZone,
    branches
}) => {
    const searchInput = useRef(null);
    const getLocalityName = locality => {
        let name = '-';
        departments.map(department => {
          const index = department.items.findIndex(el => el.id === locality);
          if (index > -1) {
            name = department.items[index].name;
          }
        });
        return name;
      };
    
      const getLevels = level => {
        const index = levels.findIndex(el => el.id === level);
        if (index > -1) {
          return levels[index].name;
        }
        return '-';
      };
    
      const getLocalities = () => {
        const localities = [];
        departments.map(d => {
          d.items.map(m => {
            localities.push({
              text: m.name,
              value: m.id,
            });
          })
        });
        return localities;
      };
    const columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          align: 'center',
        },
        {
          title: 'Municipio',
          dataIndex: 'localityId',
          align: 'center',
          filters: getLocalities(),
          onFilter: (val, record) => record.localityId === val,
          render: val => getLocalityName(val, searchInput),
        },
        {
          title: 'Nombre',
          dataIndex: 'name',
          ...getColumnSearchProps('name'),
        },
        {
          title: 'Cobertura',
          dataIndex: 'level',
          filters: levels.map(level => ({
            text: level.name,
            value: level.id,
          })),
          onFilter: (val, record) => record.level === val,
          render: val => getLevels(val),
        },
        {
          title: 'Tarifa',
          dataIndex: 'fee',
          align: 'center',
          render: (val, row) => `${row.feeCurrency}${parseFloat(val).toFixed(2)}`, 
        },
        {
          title: 'Activo',
          align: 'center',
          dataIndex: 'active',
          render: (val, record) => (
            <Switch checked={val} onChange={() => handleActive(record.id, val)} />
          ),
        },
        {
          title: 'Editar',
          align: 'center',
          dataIndex: 'id',
          render: (id, record) => (
            <div className={styles.actions}>
              <div className={styles.edit} onClick={() => openEditor(record)}>
                <Edit />
              </div>
              <div className={styles.divider} />
              <div className={styles.delete} onClick={() => confirmDelete(id)}>
                <Trash />
              </div>
            </div>
          ),
        },
      ];
  return (
    <div className={styles.view}>
         <Header
         back={'/ajustes/zonas'}
        title="Zonas"
        actions={
          <>
            <Button
          onClick={newZone}
          type="primary"
          size="large"
          className="main-button">
          NUEVA COBERTURA
        </Button> 
          </>
        }
      />
  {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table
            columns={columns}
            dataSource={zones}
            rowKey="id"
          />
        </div>
      )}
       <Modal
        destroyOnClose
        closable={false}
        visible={editorVisible}
        footer={false}
        onCancel={closeEditor}
        width={600}
      >
        <Editor
          close={closeEditor}
          zone={zone}
          departments={departments}
          levels={levels}
          branches={branches}
          handleZone={handleZone}
          
        />
      </Modal>
    </div>
  )
}

export default ZoneUi