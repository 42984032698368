import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import { getTimeOrders, selectHistory } from "../../redux/slices/history";
import { getOrderDetails } from "../../redux/slices/monitor";
import moment from "moment";
import "moment/locale/es";
import "moment-timezone";
import { selectUser } from "../../redux/slices/user";
import ReportUI from "./components/ReportUI";
import exportOrders from "../../utils/exportOrders";

const Reports = () => {
  const today = moment.tz("America/El_Salvador").subtract(1, "days");
  const [loading, handleLoading] = useState(true);
  const [loadingOrder, handleLoadingOrder] = useState(false);
  const [order, handleOrder] = useState(null);
  const history = useSelector(selectHistory);
  const user = useSelector(selectUser);
  const [endDate, handleEndDate] = useState(today);
  const [beginDate, handleBeginDate] = useState(
    moment.tz("America/El_Salvador").subtract(7, "days")
  );
  // const isAdmin = user?.role.id === 2;
  // const isAccountant = user.role.id === 6;
  const isAccountant = true;
  const dispatch = useDispatch();

  const searchOrders = useCallback(
    async (newdBegin, newEnd, searchVal) => {
      handleLoading(true);
      const begin = newdBegin || beginDate;
      const end = newEnd || endDate;
      const config = {
        status: [1, 7, 12, 15],
        startDate: begin.format("YYYY-MM-DD 00:00:00"),
        endDate: end.format("YYYY-MM-DD 23:59:59"),
        role: 2,
        idBranch: user.branch,
        admin: user.admin
      };
      if (searchVal && searchVal.length) {
        config.ticket = searchVal;
      }
      const response = await dispatch(getTimeOrders(config));
      if (response.status !== "success") {
        message.error("¡Hubo un problema!");
      }
      handleLoading(false);
    },
    [beginDate, dispatch, endDate]
  );

  const handleDates = (newDates) => {
    handleBeginDate(newDates[0]);
    handleEndDate(newDates[1]);
    searchOrders(newDates[0], newDates[1]);
  };

  /* const initialFetch = useCallback(async () => {
    const response = await dispatch(getOrders(isAdmin ? 0 : user.employee_info.locationId));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    }
    handleLoading(false);
  }, [dispatch]); */
  const orderSearch = (val) => {
    searchOrders(false, false, val);
  };

  useEffect(() => {
    searchOrders();
  }, [searchOrders]);

  /* const openOrder = (newOrder) => {
    handleOrder(newOrder);
  }; */

  const closeOrder = () => {
    handleOrder(null);
  };

  const exportData = () => {
    exportOrders(history);
  };

  const openDetails = async (order) => {
    handleLoadingOrder(order);
    const response = await dispatch(getOrderDetails(order));
    handleLoadingOrder(false);
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      handleOrder(response.order);
      // handleModalOrder(true);
    }
  };

  return (
    <ReportUI
      loading={loading}
      history={history}
      order={order}
      openOrder={openDetails}
      closeOrder={closeOrder}
      isAccountant={isAccountant}
      exportData={exportData}
      handleDates={handleDates}
      beginDate={beginDate}
      orderSearch={orderSearch}
      endDate={endDate}
      loadingOrder={loadingOrder}
    />
  );
};

export default Reports;
