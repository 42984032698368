import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  DatePicker,
  Switch,
  Radio,
  Checkbox,
} from "antd";
import { useDispatch } from "react-redux";
import { createQuestion, updateQuestion } from "../../../redux/slices/ratings";
import Close from "../../../components/Close";
import styles from "../styles/Editor.module.css";
import moment from "moment";

const Editor = ({ close, question, handleQuestion }) => {
  const [sending, handleSending] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  console.log(question);
  useEffect(() => {
    const initialFetch = async () => {
      if (question) {
        const newValues = {
          name: question.question,
          active: question.active,
          dateBegin: moment(question.dateBegin),
          dateEnd: moment(question.dateEnd),
        };
        form.setFieldsValue(newValues);
      }
    };
    initialFetch();
  }, [question]);

  const save = async () => {
    try {
      handleSending(true);
      const values = await form.validateFields();

      const info = {
        name: values.name,
        active: values.active,
        dateBegin: values.dateBegin,
        dateEnd: values.dateEnd,
      };
      if (question) {
        info.id = question.id;
      }
      if (question) {
        const response = await dispatch(updateQuestion(info));
        if (response.status === "success") {
          message.success("Cambios realizados con éxito");
        } else {
          message.error("¡Hubo un problema! Inténtalo de nuevo");
        }
      } else {
        const response = await dispatch(createQuestion(info));
        if (response.status === "success") {
          message.success("Cambios realizados con éxito");
          handleQuestion(response.question);
        } else {
          message.error("¡Hubo un problema! Inténtalo de nuevo");
        }
      }
      handleSending(false);
    } catch (e) {}
  };

  return (
    <div className={styles.editor}>
      <div className={styles.header}>
        <span className={styles.title}>
          {question ? "Editar Evaluación" : "Nueva Evaluación"}
        </span>
        <Close action={close} />
      </div>
      <Form
        name="question-editor"
        autoComplete="off"
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={save}
      >
        <Row>
          <Col span={12} offset={14}>
            <Form.Item
              name={"active"}
              valuePropName="checked"
              label="EVALUACIÓN ACTIVA"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <span className={styles.label}>TÍTULO DE LA EVALUACIÓN</span>
              }
              name="name"
              className={styles.itemColumn}
              rules={[{ required: true, message: "Ingresar pregunta" }]}
            >
              <Input.TextArea rows={3} size="large" className={styles.input} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={<span className={styles.label}>FECHA INICIO</span>}
              name="dateBegin"
              className={styles.itemColumn}
              rules={[{ required: true, message: "Ingresar tipo" }]}
            >
              <DatePicker size="large" className={styles.date} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<span className={styles.label}>FECHA FINALIZACIÓN</span>}
              name="dateEnd"
              className={styles.itemColumn}
              rules={[{ required: true, message: "Ingresar tipo" }]}
            >
              <DatePicker size="large" className={styles.date} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} offset={16}>
            <Form.Item
              name={"time_undefined"}
              valuePropName="checked"
              label="Tiempo indefinido"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item className={styles.itemSubmit}>
              <Button
                size="large"
                type="ghost"
                htmlType="button"
                className={styles.submit}
              >
                BORRAR EVALUACIÓN
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className={styles.itemSubmit}>
              <Button
                loading={sending}
                className={styles.submit}
                size="large"
                type="primary"
                htmlType="submit"
              >
                GUARDAR INDICADOR
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Editor;
