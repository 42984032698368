import { createSlice } from "@reduxjs/toolkit";
import {
  getBranches,
  getDepartments,
  editBranch,
  newBranch,
  deleteBranch,
  changeTakeOut,
  changeDelivery,
  uploadImage,
  getSchedule,
  updateSchedule,
  getBranch,
  createBranchBackup,
  getBranchesBackup,
  deleteBranchBackup,
  editBranchBackup,
} from "../../api/Branches";

export const branchesSlice = createSlice({
  name: "branches",
  initialState: {
    branch:{},
    branches: [],
    branchesBackup:[]
  },
  reducers: {
    setBranches: (state, action) => {
      state.branches = action.payload;
    },
    setTakeOut: (state, action) => {
      const { branch, val } = action.payload;
      const index = state.branches.findIndex((el) => el.id === branch);
      state.branches[index].take_out = val;
    },
    setDelivery: (state, action) => {
      const { branch, val } = action.payload;
      const index = state.branches.findIndex((el) => el.id === branch);
      state.branches[index].delivery = val;
    },
    setBranch: (state, action) => {
      const index = state.branches.findIndex(
        (el) => el.id === action.payload.id
      );
      if (index > -1) {
        state.branches[index] = action.payload;
      }
    },
    setSingleBranch: (state, action) => {
      state.branch = action.payload;
    },
    addBranch: (state, action) => {
      state.branches.push(action.payload);
    },
    removeBranch: (state, action) => {
      const index = state.branches.findIndex((el) => el.id === action.payload);
      if (index > -1) {
        state.branches.splice(index, 1);
      }
    },
    setBranchesBackup: (state,action) => {
      state.branchesBackup = action.payload
    },
    addBranchBackup:(state,action) => {
      state.branchesBackup = action.payload
    },
    removeBranchBackup:(state,action) => {
      const index = state.branchesBackup.findIndex((item)=> item.id === action.payload)
      if(index > -1){
        state.branchesBackup.splice(index,1)
      }
    }
  },
});

// Action creators are generated for each case reducer function
export const { setBranches, setTakeOut, setDelivery, setBranch, setSingleBranch, addBranch, removeBranch,addBranchBackup,setBranchesBackup,removeBranchBackup } =
  branchesSlice.actions;

export const getAllBranches = (userid) => async (dispatch) => {
  try {
    const response = await getBranches(userid);
    if (!response.error && response.status === 200) {
      dispatch(setBranches(response.data.branches));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};


export const getAllDepartments = () => async () => {
  try {
    const response = await getDepartments();
    if (!response.error && response.status === 200) {
      return {
        status: "success",
        departments: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};


export const getDeliverySchedule = () => async () => {
  try {
    const response = await getSchedule();
    if (!response.error && response.status === 200) {
      return {
        status: "success",
        schedule: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const updateDeliverySchedule = (open, close) => async () => {
  try {
    const response = await updateSchedule(open, close);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const deliveryLocation = (id, val) => async (dispatch) => {
  try {
    dispatch(setDelivery({ branch: id, val: !val }));
    const response = await changeDelivery(id);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
      };
    }
    dispatch(setDelivery({ branch: id, val }));
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    dispatch(setDelivery({ branch: id, val }));
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const takeOutBranch = (id, val, user) => async (dispatch) => {
  try {
    dispatch(setTakeOut({ branch: id, val: !val }));
    const response = await changeTakeOut(id, !val, user);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
      };
    }
    dispatch(setTakeOut({ branch: id, val }));
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    dispatch(setTakeOut({ branch: id, val }));
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const DeliveryBranch = (id, val, user) => async (dispatch) => {
  try {
    dispatch(setDelivery({ branch: id, val: !val }));
    const response = await changeDelivery(id, !val, user);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
      };
    }
    dispatch(setDelivery({ branch: id, val }));
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    dispatch(setDelivery({ branch: id, val }));
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const getBranche = (data) => async (dispatch) => {
  try {
    const response = await getBranch(data);
    if (!response.error && response.status === 200) {
      dispatch(setSingleBranch(response.data.branch));
      return {
        status: "success",
        branch: response.data.branch
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const editLocation = (data) => async (dispatch) => {
  try {
    const response = await editBranch(data);
    if (!response.error && response.status === 200) {
      dispatch(setBranch(response.data.branch[0]));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const newLocation = (data) => async (dispatch) => {
  try {
    const response = await newBranch(data);
    if (!response.error && response.status === 200) {
      dispatch(addBranch(response.data.branch));
      return {
        status: "success",
        branch: response.data.branch,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const createBranchBackupThunk = (data) => async () => {
  try {
    const response = await createBranchBackup(data)
    if(!response.error && response.status === 200){
      return {
        status:'success',
        branches:response.data.branches
      }
    } 
    return {
      status: 'error',
      type:'unknown'
    }
  } catch (e) {
    return {
      status: 'error',
      type:'unknown'
    }
  }
}

export const editBranchBackupThunk = (data) => async () => {
   try {
    const response = await editBranchBackup(data)
    if(!response.error && response.status === 200){
      return {
        status:'success',
        branches:response.data.branches
      }
    }
     return {
      status: 'error',
      type: 'unknown'
     }
   } catch (e) {
     return {
      status: 'error',
      type: 'unknown'
     }
   }
}

export const getBranchesBackupThunk = (userid) => async (dispatch) => {
  try {
    const response = await getBranchesBackup(userid)
    if(!response.error && response.status === 200){
      dispatch(setBranchesBackup(response.data.branches))
      return {
        status:'success'
      }
    }
    return {
      status: 'error',
      type:'unknown'
    }
  } catch (e) {
    return {
      status: 'error',
      type:'unknown'
    }
  }
}
export const deleteBranchBackupThunk = (userId,branchId) => async (dispatch) => {
  try {
    const response = await deleteBranchBackup(userId,branchId)
    if(!response.error &&  response.status === 200){
      dispatch(removeBranchBackup(branchId))
      return {
        status:'success'
      }
    }
    return {
      status:'error',
      type:'unknown',
    }
  } catch (e) {
    return {
      status:'error',
      type:'unknown',
    }
  }
}
export const deleteLocation = (id) => async (dispatch) => {
  try {
    const response = await deleteBranch(id);
    if (!response.error && response.status === 200) {
      dispatch(removeBranch(id));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};


export const newImage = (info) => async (dispatch) => {
  try {
    const response = await uploadImage(info);
    if (!response.error && response.status === 200) {
      dispatch(setBranch(response.data.branch[0]));
      return {
        status: "success",
        branch: response.data.branch[0],
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const selectBranches = (state) => state.branches.branches;
export const selectBranch = (state) => state.branches.branch;
export const selectBranchesBackup = (state) => state.branches.branchesBackup
export default branchesSlice.reducer;
