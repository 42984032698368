import { useState, useEffect } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Upload,
  Button,
  message,
  Select,
  Radio,
} from "antd";
import { useDispatch } from "react-redux";
import {
  createSlide,
  updateSlide,
  newImage,
} from "../../../redux/slices/frontpage";
import { normFile, beforeUpload, dummyRequest } from "../../../utils/images";
import Close from "../../../components/Close";
import styles from "../styles/Editor.module.css";

const { Option } = Select;

const Editor = ({ banner, closeEditor, handleBanner, isBannerOrPopUp,products,categories }) => {
  const [sending, handleSending] = useState(false);
  const [baseImg, handleBaseImg] = useState("");
  const [baseImg2, handleBaseImg2] = useState("");
  const [target, setTarget] = useState("take_out");
  const [action,handleAction] = useState('NONE')

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    const initialFetch = async () => {
      if (banner) {
        const newValues = {
          url: banner.url || "",
          sort: banner.sort || 0,
          name: banner.name,
          target: banner.mode == "APP" ? "app" : "take_out",
          action:banner.action_type
        };
        handleAction(banner.action_type)
        handleBaseImg(banner.image || "");
        handleBaseImg2(banner.image_movil || "");
        setTarget(banner.mode == "APP" ? "app" : "take_out");
        form.setFieldsValue(newValues);
      } else {
        form.setFieldsValue({ target });
      }
    };
    initialFetch();
  }, [banner]);


  const uploadImage = async (valueImage, id, type) => {
    const isWeb = type === "web";
    const imagePreview = isWeb ? baseImg : baseImg2;
    const imageBanner = isWeb ? banner?.image : banner?.image_movil;
    if (imagePreview !== imageBanner || !banner) {
      const infoImage = new FormData();
      infoImage.append("id", id);
      infoImage.append("type", isWeb ? "image" : "image_movil");
      infoImage.append(
        "image",
        valueImage?.length ? valueImage[0]?.originFileObj : imagePreview
      );
      const response = await dispatch(newImage(infoImage));
      if (response.status === "success") {
        message.success("Cambios realizados con éxito");
        return response;
      } else {
        message.error("¡Hubo un problema! Inténtalo de nuevo");
        return false;
      }
    } else if (target === "app" || type === "mobile") {
      message.success("Cambios realizados con éxito");
      return false;
    }
  };

  const save = async () => {
    try {
      handleSending(true);
      const values = await form.validateFields();
      
      if (
        baseImg &&
        baseImg.length &&
        (target === "app" || (baseImg2 && baseImg2.length))
      ) {
        const info = {
          name: values.name,
          target: values.target == "app" ? "take_out" : values.target,
          mode: values.target == "app" ? "APP" : "WEB",
          sort: values.sort,
        };
        if (banner) {
          info.id = banner.id;
          info.status = banner.status;
        } else {
          info.status = 1;
        }
        if (target === "take_out") {
          info.url = values.url;
        }

        if (banner) {
          const response = await dispatch(updateSlide(info));
          if (response.status === "success") {
            await uploadImage(values.image, banner.id, "web");
            if (target === "take_out") {
              await uploadImage(values.image2, banner.id, "mobile");
            }
          } else {
            message.error(
              "¡Hubo un problema al subir el banner! Inténtalo de nuevo"
            );
          }
        } else {
          const response = await dispatch(createSlide(info));
          if (response.status === "success") {
            let response_image = await uploadImage(
              values.image,
              response.banner.id,
              "web"
            );
            if (target === "take_out") {
              response_image = await uploadImage(
                values.image2,
                response.banner.id,
                "mobile"
              );
            }
            handleBanner(response_image.banner);
          } else {
            message.error("¡Hubo un problema! Inténtalo de nuevo");
          }
        }
      } else {
        message.error("Seleciona una imagen válida");
      }

      handleSending(false);
    } catch (e) {
      console.log(e);
    }
  };

  // select de productos mostrar en name de cada producto y enviar sku ,hacer por busqueda
  // para el select de products y categorias obtenerlos de la api /partners/menu
  // para la tabla de los popups en el switch de activa ,so lo uno puede estar activo
  // para ocupar la misma vista puede utilizar esta misma solo que cambiar de tabla con tags
  console.log(action)
  return (
    <div>
      <Row>
        <Col span={24}>
          <div className={styles.header}>
            <span>{isBannerOrPopUp === 'banner' && banner?.id ? "Editar banner" : isBannerOrPopUp === 'banner' && !banner ? "Agregar banner" : isBannerOrPopUp === 'popup' && banner?.id ? 'Editar popup' : 'Agregar popup'}</span>
            <Close action={closeEditor} />
          </div>
        </Col>
      </Row>
      <Form
        form={form}
        name="product-editor"
        layout="vertical"
        autoComplete="off"
        colon={false}
        requiredMark={false}
        className={styles.form}
        onFinish={save}
        initialValues={{ promo: false, url: "" }}
      >
        <Row>
          <Col span={24}>
            <Row gutter={6}>
              <Col span={10}>
                <Form.Item
                  label={<span className={styles.label}>PLATAFORMA</span>}
                  name="target"
                  className={styles.itemColumn}
                >
                  <Select
                    onChange={setTarget}
                    size="large"
                    className={styles.input}
                  >
                    <Option value="take_out">Sitio Web</Option>
                    <Option value="app">App móvil</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label={<span className={styles.label}>NOMBRE</span>}
                  name="name"
                  className={styles.itemColumn}
                  rules={[{ required: true, message: "Ingresar nombre" }]}
                >
                  <Input size="large" className={styles.input} />
                </Form.Item>
                <Form.Item
                  label={<span className={styles.label}>POSICIÓN</span>}
                  name="sort"
                  className={styles.itemColumn}
                  rules={[{ required: true, message: "Ingresar posición" }]}
                >
                  <Input size="large" className={styles.input} type="number" />
                </Form.Item>
              </Col>
              <Col span={14}>
                <Row gutter={20}>
                  <Col span={12}>
                    <div
                      className={styles.preview}
                      style={{ backgroundImage: `url(${baseImg})` }}
                    />
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="image"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      label={
                        <span className={styles.label}>IMAGEN DE {`${isBannerOrPopUp === 'banner' ? 'BANNER' : 'POPUP'}`} </span>
                      }
                      extra={
                        <span className={styles.center}>
                          (Formatos jpg/png de 1600x700px)
                        </span>
                      }
                    >
                      <Upload
                        showUploadList={false}
                        customRequest={(e) => dummyRequest(e, handleBaseImg)}
                        beforeUpload={beforeUpload}
                        className={styles.upload}
                      >
                        <Button
                          size="large"
                          className={styles.uploadImage}
                          block
                        >
                          Seleccionar imagen
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                {target === "take_out" && (
                  <Row gutter={20}>
                    <Col span={12}>
                      <div
                        className={styles.preview}
                        style={{ backgroundImage: `url(${baseImg2})` }}
                      />
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="image2"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        label={
                          <span className={styles.label}>
                            IMAGEN DE {`${isBannerOrPopUp === 'banner' ? 'BANNER' : 'POPUP'}`}  (version Móvil)
                          </span>
                        }
                        extra={
                          <span className={styles.center}>
                            (Formatos jpg /png de 960x1500px)
                          </span>
                        }
                      >
                        <Upload
                          showUploadList={false}
                          customRequest={(e) => dummyRequest(e, handleBaseImg2)}
                          beforeUpload={beforeUpload}
                          className={styles.upload}
                        >
                          <Button
                            size="large"
                            className={styles.uploadImage}
                            block
                          >
                            Seleccionar imagen
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Row gutter={6}>
              <Col span={10}>
                <Form.Item
                  name="action"
                  label={<span className={styles.label}>ACCIÓN</span>}
                >
                  <Radio.Group onChange={(e)=>handleAction(e.target.value)}>
                    <div className={styles.actions}>
                      <Radio value="NONE" >Ninguna</Radio>
                      <Radio value="PRODUCT" >Producto</Radio>
                      <Radio value="CATEGORY" >Categoría</Radio>
                      {isBannerOrPopUp === 'banner' && <Radio value="MODAL" >Modal</Radio>}
                      <Radio value="EXTERNAL" >URL</Radio>
                    </div>
                  </Radio.Group>
                </Form.Item>
                {
                action === 'PRODUCT' && 
                  <Form.Item name='product' label={<span className={styles.label}>PRODUCTO</span>} >
                  <Select options={products.map((item)=> {return {value:item.sku,label:item.name}})} filterOption optionFilterProp="label" showSearch />
                </Form.Item>
                }
                {
                  action === 'CATEGORY' && 
                   <Form.Item name='category' label={<span className={styles.label}>CATEGORÍA</span>}>
                   <Select options={categories.map((item)=> {return {value:item.value,label:item.text}})} />
                 </Form.Item>
                }
              {
                   action === 'EXTERNAL' &&  target === "take_out"  &&
                   <Form.Item
                   label={<span className={styles.label}>ENLACE</span>}
                   name="url"
                   className={styles.itemColumn}
                 >
                   <Input
                     size="large"
                     className={styles.input}
                     placeholder="Ejemplo: https://gobuff.com/enlace"
                   />
                 </Form.Item>
              }
               
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.submitWrap}>
              <Form.Item className={styles.itemSubmit}>
                <Button
                  loading={sending}
                  className={styles.submit}
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  GUARDAR {`${isBannerOrPopUp === 'banner' ? 'BANNER' : 'POPUP'}`}
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Editor;
